

  <h2 mat-dialog-title class="primaryColor section-header-operative" translate>
    <span
    [translate]="'GluePlan.GlueSetNo'"
    [translateParams]="{no: bmgs.beamMaterialGlueSetID}">
  </span>

  </h2>
<mat-dialog-content class="popupContent">
  <div class="gluesetContainer">
      <app-beam-material-glue-set
      [bmgs]="bmgs"
      [plan] = "plan"
      ></app-beam-material-glue-set>
      <h4 *ngIf="!bmgs">No records found</h4>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="row">
  <button mat-raised-button type="button"
  (click)="close()"
  class="float-right">
 {{"App.Close" | translate}}
</button>
  </mat-dialog-actions>


