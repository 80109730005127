import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProdOrderDTO } from '../../../models/prod-order';

@Injectable({
  providedIn: 'root'
})
export class ProdOrderEventService {
  constructor(private http: HttpClient,
    public dialog: MatDialog) {
  }

  getProdOrderSchedules(from: Date, selectedPeriodId:number, mashines:number[], to: Date) : Observable<IProdOrderDTO[]> {
    let params = new HttpParams()
      .set('fromDate', from.toISOString())
      .set('selectedPeriodId', selectedPeriodId);

    if (to) {
      params = params.set('toDate', to.toISOString());
    }

    for (const id of mashines) {
      params = params.append('machines', id);
    }
    return this.http.get<IProdOrderDTO[]>(`${environment.apiUrl}ProdOrderEvent/getProdOrderScheduler`, { params });
  }

  getProdOrderEvents(from: Date, mashines:number[], to: Date) : Observable<IEventDTO[]> {
    let params = new HttpParams()
      .set('fromDate', from.toISOString())
      .set('toDate', to.toISOString());

    for (const id of mashines) {
      params = params.append('machineGroups', id);
    }

    return this.http.get<IEventDTO[]>(`${environment.apiUrl}ProdOrderEvent/events`, { params });
  }

  getDeviations(from: Date, to: Date, mashines:number[]) : Observable<IEventDTO[]> {
    let params = new HttpParams()
      .set('fromDate', from.toISOString())
      .set('toDate', to.toISOString());

    for (const id of mashines) {
      params = params.append('machineGroups', id);
    }

    return this.http.get<IEventDTO[]>(`${environment.apiUrl}ProdOrderEvent/deviations`, { params });
  }

  getSchedule(from: Date, mashines:number[], to: Date) : Observable<IEventColorDTO[]> {
    let params = new HttpParams()
      .set('fromDate', from.toDateString())
      .set('toDate', to.toDateString())
      .set('timeZoneOffset', (new Date()).getTimezoneOffset());

    for (const id of mashines) {
      params = params.append('machineGroups', id);
    }

    return this.http.get<IEventColorDTO[]>(`${environment.apiUrl}ProdOrderEvent/schedule`, { params });
  }
}

export interface IEventDTO{
  id: number;
  start: Date;
  end: Date;
  title: string;
  resource: number;
  resources: number[];
  stateText: string;
  stateId: number;
  voiletesPOChain: boolean;
  sequenceIds: string[];
}

export interface IEventColorDTO{
  start: Date;
  end: Date;
  resources: number[];
}
