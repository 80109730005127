import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OptinexUserDTO, UserService } from '@app/core/services/http-services/common/user.service';
import { Parameter } from '@app/core/models/parameter.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  public static appVersion = '1.0';
  public baseApiUrl = environment.apiUrl;

  public;
  public get baseUrl(): string {
    return this.baseApiUrl.indexOf('/api') > 0
      ? this.baseApiUrl.replace('/api', '')
      : this.baseApiUrl;
  }

  public BEAM_MAX_WIDTH_FOR_TWO_LAYERS: number;

  public PRESS_HIGHT_MAX: number = 1222;
  public PRESS_HIGHT_MIN: number = 880;

  public PRESS_LENGTH_MIN: number = 6000;
  public PRESS_LENGTH_MAX: number = 20200;

  public BEAM_NUMBER_OF_LAYERS: number = 1;
  public BEAM_DEFAULT_OFF_CUT_BMGS: number = 30;

  public BEAM_TEST_PSC_LENGTH: number = 80;

  // public BEAM_CPLEX_MODEL_FILE: string;

  public WATSON_MODEL_FILE: string;
  public WATSON_MODEL_FILE_ID: string = '949465bc-2e39-44d9-a766-370ff9c72a7c';

  public BEAM_MAX_BEAM_MATERIAL_SURGESTIONS: number = 0.5;
  public BEAM_MAX_GLUESETS_SURGESTIONS: number = 8;
  public BEAM_MAX_NUMBER_OF_GLUSET_BATCHES: number = 2;

  public BEAM_MAX_ORDER_PER_GLUSET_BATCHE: number = 5;
  public BEAM_MAX_GLUSET_BATCH_PER_ORDER: number = 5;
  public BEAM_WEIGHT_FACTOR_VALUE: number = 0;
  public BEAM_WEIGHT_FACTOR_AREA: number = 0.5;
  public BEAM_WEIGHT_FACTOR_PT: number = 0.5;
  public BEAM_WEIGHT_FACTOR_HIGHT: number = 0.5;
  public BEAM_WEIGHT_FACTOR_ORDERS_BATCH: number = 0.5;

  // for statical

  public Max_Beam_In_BeamMaterial: number = 5;
  public Max_Glueplans: number = 5;
  public Max_Gluesets: number = 5;
  public Max_Layers: number = 1;

  public BEAM_MAX_SOLVE_TIME: number = 20;

  private _parameters: Parameter[] = null;
  private _user: OptinexUserDTO = null;

  constructor(public http: HttpClient, public userService:UserService) { }

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService
        .getUser()
        .then(user => {
          this._parameters = user.parameters;
          this._user = user;

          this.PRESS_LENGTH_MIN = this.getParameterIntValue('BEAM_PRESS_LENGTH_MIN');
          this.PRESS_LENGTH_MAX = this.getParameterIntValue('BEAM_PRESS_LENGTH_MAX'); ;
          this.PRESS_HIGHT_MIN = this.getParameterIntValue('BEAM_PRESS_HIGHT_MIN');
          this.PRESS_HIGHT_MAX = this.getParameterIntValue('BEAM_PRESS_HIGHT_MAX');
          this.BEAM_NUMBER_OF_LAYERS = this.getParameterIntValue('BEAM_NUMBER_OF_LAYERS');
          this.BEAM_DEFAULT_OFF_CUT_BMGS = this.getParameterIntValue('BEAM_DEFAULT_OFF_CUT_BMGS', this.BEAM_DEFAULT_OFF_CUT_BMGS);

          // this.BEAM_CPLEX_MODEL_FILE = this.getParameterStringValue("BEAM_CPLEX_MODEL_FILE");
          this.WATSON_MODEL_FILE = this.getParameterStringValue('WATSON_MODEL_FILE');
          // this.WATSON_MODEL_FILE_ID = this.getParameterStringValue('WATSON_MODEL_FILE_ID');

          this.BEAM_TEST_PSC_LENGTH = this.getParameterIntValue('BEAM_TEST_PSC_LENGTH', 80);

          this.BEAM_MAX_BEAM_MATERIAL_SURGESTIONS = this.getParameterIntValue('BEAM_MAX_BEAM_MATERIAL_SURGESTIONS');
          this.BEAM_MAX_GLUESETS_SURGESTIONS = this.getParameterIntValue('BEAM_MAX_GLUESETS_SURGESTIONS');

          this.BEAM_MAX_NUMBER_OF_GLUSET_BATCHES = this.getParameterIntValue('BEAM_MAX_NUMBER_OF_GLUSET_BATCHES');
          this.BEAM_MAX_GLUSET_BATCH_PER_ORDER = this.getParameterIntValue('BEAM_MAX_GLUSET_BATCH_PER_ORDER');
          this.BEAM_MAX_ORDER_PER_GLUSET_BATCHE = this.getParameterIntValue('BEAM_MAX_ORDER_PER_GLUSET_BATCHE');
          this.BEAM_WEIGHT_FACTOR_VALUE = this.getParameterFloatValue('BEAM_WEIGHT_FACTOR_VALUE');
          this.BEAM_WEIGHT_FACTOR_AREA = this.getParameterFloatValue('BEAM_WEIGHT_FACTOR_AREA');
          this.BEAM_WEIGHT_FACTOR_PT = this.getParameterFloatValue('BEAM_WEIGHT_FACTOR_PT');
          this.BEAM_WEIGHT_FACTOR_HIGHT = this.getParameterFloatValue('BEAM_WEIGHT_FACTOR_HIGHT');
          this.BEAM_WEIGHT_FACTOR_ORDERS_BATCH = this.getParameterFloatValue('BEAM_WEIGHT_FACTOR_ORDERS_BATCH');

          this.BEAM_MAX_SOLVE_TIME = this.getParameterIntValue('BEAM_MAX_SOLVE_TIME', 20);

          this.BEAM_MAX_WIDTH_FOR_TWO_LAYERS = 174;
          // Rememner to add all new Props to make shure that all configurations are loaded before app loads
          if (
            !this.BEAM_NUMBER_OF_LAYERS ||
            !this.PRESS_HIGHT_MAX ||
            !this.PRESS_HIGHT_MIN ||
            !this.PRESS_LENGTH_MIN ||
            !this.PRESS_LENGTH_MAX ||
            !this.BEAM_DEFAULT_OFF_CUT_BMGS ||
            !this.BEAM_MAX_WIDTH_FOR_TWO_LAYERS
          ) {
            throw new Error('Not all configuratons set');
          } else {
            resolve(true);
          }
        });
      resolve(true);
    });
  }

  public saveUserParameterInt(code: string, int: number) {

  }

  public saveUserParameterFloat(code: string, float: number) {

  }

  public saveUserParameterString(code: string, float: number) {

  }

  private postUpdate(parameter: Parameter): Observable<any> {
    return this.http.post(`${this.baseApiUrl}Parameter`, parameter);
  }

  private getParameterIntValue(code: string, def: number = 100): number {
    const o = this._parameters.find(
      p => p.code === code
    );

    if (o) {
      return o.valueInt;
    } else {
      console.warn(`Using Default Value for prop: ${code} val: ${def}`);
      return def;
    }
  }

  private getParameterFloatValue(code: string, def: number = 0): number {
    const o = this._parameters.find(
      p => p.code === code
    );
    if (o) {
      return o.valueDouble;
    } else {
      console.warn(`Using Default Value for prop: ${code} val: ${def}`);
      return def;
    }
  }

  private getParameterStringValue(code: string, def: string = ''): string {
    const o = this._parameters.find(
      p => p.code === code
    );

    if (o) {
      return o.valueChar35;
    } else {
      console.warn(`Using Default Value for prop: ${code} val: ${def}`);
      return def;
    }
  }
}
