<h1 translate>Beams.beams</h1>
<kendo-grid
    [data]="view | async"
    [pageSize]="state.take"
    [skip]="state.skip"
    [sort]="state.sort"
    [filterable]="true"
    [sortable]="true"
    [pageable]="true"
    (dataStateChange)="dataStateChange($event)"
    [filter]="state.filter"
    [style]="{'height': 'calc(100vh - 10em)'}"
    [size]="'small'">
    <kendo-grid-column
        field="orderNo"
        title="{{ 'Beams.orderNo' | translate }}"
        [width]="200">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="orderRowNo"
        title="{{ 'OrderViewTranslation.OrderRow' | translate }}"
        [width]="200">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="transportPackageId"
        title="{{ 'OrderViewTranslation.PackageId' | translate }}"
        [width]="130">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="customerDesc"
        title="{{ 'OrderViewTranslation.CustomerDesc' | translate }}"
        [width]="200">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="mainProduct"
        title="{{ 'OrderViewTranslation.BEAM_MAIN_PRODUCT' | translate }}"
        [width]="130">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="quality"
        title="{{ 'OrderViewTranslation.BEAM_QUALITY' | translate }}"
        [width]="200">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="constructionCertification"
        title="{{ 'OrderViewTranslation.ConstructionCertification' | translate }}"
        [width]="130">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="width"
        title="{{ 'OrderViewTranslation.Width' | translate }}"
        [width]="130">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="height"
        title="{{ 'OrderViewTranslation.Height' | translate }}"
        [width]="130">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="length"
        title="{{ 'OrderViewTranslation.Length' | translate }}"
        [width]="130">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="beamMaterialLineID"
        title="{{ 'Beams.beamMaterialLineID' | translate }}"
        [width]="130">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
    <kendo-grid-column
        field="gluePlanId"
        title="{{ 'Beams.gluePlanId' | translate }}"
        [width]="130">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="glueSetId"
        title="{{ 'Beams.glueSetId' | translate }}"
        [width]="130">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="stateId"
        title="{{ 'App.State' | translate }}"
        [width]="200">
        <ng-template
            kendoGridFilterCellTemplate
            let-filter>
            <app-kendo-grid-drop-down-filter
                [data]="states"
                [filter]="filter"
                textField="name"
                valueField="beamMaterialLineStateID"
                [filterCode]="'stateId'">
            </app-kendo-grid-drop-down-filter>
        </ng-template>
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            <span [style.color]="getStateColor(dataItem.productionStateName)">{{ dataItem.productionStateName }}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column
        title="{{ 'App.Action' | translate }}"
        [width]="100">
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            <button
                mat-icon-button
                color="warn"
                [disabled]="dataItem.productionStateName!='DONE'"
                (click)="onReject(dataItem)"
                class="mr-2"
                title="{{ 'Beams.rejectBeams' | translate }}">
                <mat-icon>cancel</mat-icon>
            </button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
