<mat-toolbar
  color="primary"
  class="no-print">
  <mat-icon
    aria-label="Menu"
    class="pointer"
    *ngIf="!isMenuExpanded &&  loggedIn"
    (click)="toggleSidenav()">
    menu
  </mat-icon>
  <a class="toolbar-logo">
    <img [attr.src]="logoImg" alt="">
  </a>
  <div style="flex-grow: 1;"></div>
  <span>{{configuration.environment}}</span>
  <div style="flex-grow: 1;"></div>
  <button
    mat-button
    *ngIf="loggedIn"
    (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
  </button>
</mat-toolbar>
<mat-sidenav-container
  MatSidenavModule
  id="sidenav-container"
  [hasBackdrop]="!isMenuExpanded">
  <mat-sidenav
    #sidenav
    class="no-print"
    [mode]="isMenuExpanded ? 'side' : 'over'"
    [opened]="isMenuExpanded">
    <app-user-settings
      [(isMenuExpanded)]="isMenuExpanded"
      *ngIf="loggedIn"
      (toggleMenu)="toggleSidenav()"></app-user-settings>
     <mat-divider></mat-divider>
    <app-menu></app-menu>
  </mat-sidenav>

  <mat-sidenav-content class="main-content-wrapper" [ngClass] = "{'mat-sidenav-overflow' : isMenuExpanded}">
    <div  class="main-content mat-typography" [ngClass]="{'main-content-margin' : !noMargin}">
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
    <div kendoDialogContainer></div>
  </mat-sidenav-content>
</mat-sidenav-container>
