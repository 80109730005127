import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy,
  HostListener,
  OnInit,
  SimpleChange
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { process, State, DataResult, FilterDescriptor } from '@progress/kendo-data-query';
import {
  SelectAllCheckboxState,
  PageSizeItem,
  SelectionEvent,
  GridComponent,
  ColumnComponent,
  DataStateChangeEvent
} from '@progress/kendo-angular-grid';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {
  EntityPropertyService,
  IEntityProperty,
  IEntityPropertyValueValid
} from '@app/core/services/http-services/model/entity-property.service';
import { BeamLamminaDimension } from '../../../../core/models/beam-lammina-dimension.model';
import { GluingPlan } from '../../../../core/models/gluing-plans.model';

import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { EntityFilter, EntityService } from '@app/core/services/http-services/model/entity.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { EntityGroupService } from '@app/core/services/custom-services/entity-group.service';
import { Demandline } from '@app/core/models/demand-line.model';
import { DeamndService, UpdateSumUIQtyDTO, IDemandPartialDTO, IAltModelResponceDTO, IDemandDTO } from '@app-core-httpservice/gluelam/demand.service';
import { IOrderViewSetting } from '@app/core/models/order-view.model';
import { UserSettingkeyEnum } from '@app/core/models/user-setting-key.enum';
import { UserSettingService } from '@app/core/services/http-services/common/user-setting.service';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { DemandSpecificationService } from '@app/core/services/http-services/aot/demand-specification.service';
import { IPersistedColState, IPersistedGridState } from '@app/core/models/persist-grid-state';

marker('OrderViewTranslation.BEAM_MAIN_PRODUCT');
marker('OrderViewTranslation.BEAM_QUALITY');
marker('OrderViewTranslation.BEAM_PROFILE');
marker('OrderViewTranslation.BEAM_PLANED_PROD_DATE');
marker('OrderViewTranslation.Show_all');
marker('Operative.DemandRecordDeleted');
marker('Operative.DemandDeletedSuccessfully');
marker('Operative.PackageDeletedSuccessfully');

const createFormGroup = (dataItem: Demandline) =>
  new UntypedFormGroup({
    DeamndId: new UntypedFormControl(dataItem.BeamOrderRowID),
    LamminaPlaneThickness: new UntypedFormControl(dataItem.DeafultLamminaPlaneThicknes, Validators.required),
    LamminaPlaneWidth: new UntypedFormControl(dataItem.LamminaPlaneWidth, Validators.required),
    beamLamminaDimensionId: new UntypedFormControl(
      { value: dataItem.BeamLamminaDimensionID, disabled: true },
      Validators.required
    ),
    materialIndex: new UntypedFormControl(dataItem.materialIndex, Validators.required),
    materialDescription: new UntypedFormControl(dataItem.materialDescription, Validators.required),
    qtyReproduce: new UntypedFormControl(dataItem.transportPackageAllocation?.unitsExtra),
    transportPackageId: new UntypedFormControl(dataItem.transportPackageAllocation?.transportPackageID)
  });

const PLANE_LAMINA_THICKNESS = 'PLANE_LAMINA_THICKNESS';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styles: [`.grid-wrapper {
    position: relative;
  }
      .k-button {
        padding: 1px 4px;
      }
    `
  ]
})
export class OrderView implements OnChanges, OnDestroy, OnInit {
  @Input() public uiQty: UpdateSumUIQtyDTO[] = [];
  @Input() selectedLamminaDimension: BeamLamminaDimension;
  @Input() isLeftMajor: boolean;
  @Input() filterOnSelectGluePlan: boolean = true;
  @Input() selectedPlan: GluingPlan;
  @Input() siteId: string;
  @Input() isRefreshGrid: Observable<boolean>;

  @Output() optimise = new EventEmitter();
  @Output() updateSize = new EventEmitter<boolean>();

  initialLoad = true;
  selectRowCount = 0;
  pageSize = 200;
  saveInProgress = false;
  take = 200;
  showPlanned = false;
  showOnlyWithNoGluPlan = true;
  removeDemandInProgress = false;
  removeDemandByRowInProgress = false;
  removeDemandByPackageInProgress = false;
  formGroup: UntypedFormGroup;
  beamOrderLinesGridData: DataResult;
  planeLaminaThicknessOptions: Array<number>;
  beamLamminaDimensions: BeamLamminaDimension[];
  dimensions: BeamLamminaDimension[];
  altModels: IAltModelResponceDTO[] = [];
  selectedIDs = [];
  lastUpdated: Date;
  loading = false;
  reorderInProgress = false;
  beamLamminaDimensionColumnName = 'BeamLamminaDimensionId';
  selectedBeamLaminaDimensionId: number;
  disableOptimizerBtn: boolean;
  state: State = {
    skip: 0,
    take: this.take,
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: []
    },
    sort: [],
    group: []
  };

  pageSizes: PageSizeItem[] = [
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: '150', value: 150 },
    { text: '200', value: 200 },
    { text: 'All', value: 0 }
  ];

  @ViewChild('autocomplete', { static: false })
  private autocomplete: AutoCompleteComponent;

  @ViewChild('grid')
  private grid: GridComponent;

  private editedRowIndex: number;
  private planeLaminaThickness = <IEntityProperty[]>[];
  private currentSelectedRows: Demandline[];
  private beamOrderLines: Demandline[];
  private currentQtyReproduce: number;
  private updateLaminaThicknessEntityPropertyValueValid: IEntityPropertyValueValid;
  private selectAllState: SelectAllCheckboxState = 'unchecked';
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private beamOrderRowService: DeamndService,
    public translate: TranslateService,
    private entityPropertyService: EntityPropertyService,
    public dimensionService: DimensionService,
    public gluingPlanService: GluePlanService,
    public notificationService: AppNotificationService,
    public entityService: EntityService,
    public entityGroupService: EntityGroupService,
    private userSettingService: UserSettingService,
    private demandSpecificationService: DemandSpecificationService) { }

  ngOnInit(): void {
    const thisPointer = this;
    this.loading = true;

    this.getUserSettings()
      .pipe(
        switchMap(() => this.getUserGridSettings())
      )
      .subscribe(() => {
        this.loading = false;
        if (this.filterOnSelectGluePlan) {
          this.loading = true;
          this.gluingPlanService.selectedPlan.pipe(takeUntil(this.destroy$)).subscribe((plan: GluingPlan) => {
            this.loading = false;
            this.state.filter.filters = this.state.filter.filters.filter((x: any) => x.field !== this.beamLamminaDimensionColumnName);
            this.selectedBeamLaminaDimensionId = plan.beamLaminaDimentionId;

            if (this.selectedPlan?.gluingPlanID !== plan.gluingPlanID) {
              this.selectedIDs = [];
            }
          });
        }
        this.getDemands(this.selectedBeamLaminaDimensionId);
        this.initialLoad = false;
      });

    this.selectedBeamLaminaDimensionId = this.selectedLamminaDimension?.beamLamminaDimensionID;

    this.entityPropertyService.get().subscribe((data) => {
      this.planeLaminaThickness = data.filter((x) => x.code === PLANE_LAMINA_THICKNESS);
      this.planeLaminaThicknessOptions = this.planeLaminaThickness.map((x) => {
        return x.validValues.map((y) => {
          return y.valueDouble;
        });
      })[0];
    });

    this.beamOrderRowService.unselectedOrderRow.subscribe((x) => {
      thisPointer.selectedIDs = thisPointer.selectedIDs.filter((id) => id !== x.rowId);
      const rows = this.beamOrderLines.filter((bol) => thisPointer.selectedIDs.find((id) => bol.rowId === id) > 0);
      this.selectRowCount = rows.length;
      this.beamOrderRowService.selecOrderRows(rows);
    });

    this.dimensionService.getBeamLamminaDimentions(true).subscribe((res) => {
      this.beamLamminaDimensions = res;
      this.dimensions = res;
    });

    // This event will call after selected glue plan transport package demand api load data
    this.beamOrderRowService.getBeamOrderRows.pipe(takeUntil(this.destroy$)).subscribe((d) => this.onRowsUppdated(d));

    this.isRefreshGrid.pipe(takeUntil(this.destroy$)).subscribe(value => {
      /* to avoild multiple calls at page load */
      if (value && !this.initialLoad) {
        this.query();
      }
    });
  }

  private getDemands(beamLamminaDimensionID: number) {
    if (beamLamminaDimensionID > 0) {
      this.state.filter.filters = this.state.filter.filters.filter(x => x['field'] !== this.beamLamminaDimensionColumnName);
      this.state.filter.filters.push(<FilterDescriptor>{ field: this.beamLamminaDimensionColumnName, value: beamLamminaDimensionID.toString(), operator: 'eq' });
    }
    this.query();
  }

  private query(): Subscription {
    if (this.state.filter.filters.find((x) => x['field'] === 'isActive') === undefined) {
      this.state.filter.filters.push(<FilterDescriptor>{ field: 'isActive', value: true, operator: 'eq' });
    }
    return this.beamOrderRowService.query(this.state, this.showPlanned, this.showOnlyWithNoGluPlan, this.siteId);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    event.stopPropagation();
    event.preventDefault();
  }

  ngOnChanges(changes: SimpleChanges): void {
    /* if siteId changes load the complete grid */
    if (changes.siteId) {
      if (!changes.siteId.firstChange && !this.initialLoad) {
        this.query();
      }
    } else if (changes.selectedPlan && !changes.selectedPlan.firstChange) {
      /* this.selectedBeamLaminaDimensionId to identify if call is from statical or continioud */
      // TODO - adding new input parm to identify if statical or continious
      let isIdenticalPlan = changes.selectedPlan.currentValue.gluingPlanID === changes.selectedPlan.previousValue?.gluingPlanID;
      let isSameDimensionPlan = !isIdenticalPlan && changes.selectedPlan.currentValue.beamLaminaDimentionId === changes.selectedPlan.previousValue?.beamLaminaDimentionId;

      if ((isIdenticalPlan && changes.selectedPlan.currentValue.glueSetStateId !== changes.selectedPlan.previousValue?.glueSetStateId)
        || (this.selectedBeamLaminaDimensionId !== undefined && isSameDimensionPlan)) {
        this.getDemands(this.selectedBeamLaminaDimensionId);
      }
    } else if (changes.uiQty) {
      this.loading = true;
      this.loadData(this.beamOrderLines);
      this.loadDataWithNewUiQty(this.beamOrderLines, changes.uiQty);
      this.loading = false;
    }
  }

  loadData(demands: Demandline[]) {
    if (demands) {
      demands.forEach(demand => demand.QtySumUI = 0);

      /* Find given beam order based for selected plan in uiQty(all utilized beams in gps) and update black qty */
      this.uiQty.forEach((d) => {
        const selectedPlanDemand = demands.find((k) => k.BeamOrderRowID === d.demandId && k.transportPackageId === d.transportPackageId && this.selectedPlan?.gluingPlanID === d.gluePlanId);
        if (selectedPlanDemand) {
          selectedPlanDemand.QtySumUI = d.newQty;
        }
      });
    }

    this.beamOrderLines = demands;
    this.beamOrderLinesGridData = process(demands, { group: this.state.group });
    this.beamOrderLinesGridData.total = this.beamOrderRowService.totalCount; // setting total values
  }

  loadDataWithNewUiQty(demands: Demandline[], change: SimpleChange) {
    if (change !== null) {
      const maxChangeCount = this.getMaxCount(change);
      maxChangeCount.forEach((m) => {
        const d = demands.find((k) => k.BeamOrderRowID === m.demandId && k.transportPackageId === m.transportPackageId);
        if (d && m.gluePlanId === this.selectedPlan.gluingPlanID) {
          const current = change.currentValue.find(
            (k) => k.demandId === m.demandId && k.transportPackageId === m.transportPackageId && k.gluePlanId === this.selectedPlan.gluingPlanID
          );
          const previous = change.previousValue.find(
            (k) => k.demandId === m.demandId && k.transportPackageId === m.transportPackageId && k.gluePlanId === this.selectedPlan.gluingPlanID
          );
          if (current !== undefined) {
            if (previous !== undefined) {
              if (previous.newQty !== current.newQty) {
                const totalConsumedPreviousQty = change.previousValue
                  .filter(k => k.demandId === m.demandId && k.transportPackageId === m.transportPackageId) // Filter objects based on conditions
                  .reduce((sum, obj) => sum + obj.newQty, 0);

                const totalConsumedCurrentQty = change.currentValue
                  .filter(k => k.demandId === m.demandId && k.transportPackageId === m.transportPackageId) // Filter objects based on conditions
                  .reduce((sum, obj) => sum + obj.newQty, 0);

                d.consumedBML = (d.consumedBML - totalConsumedPreviousQty) + totalConsumedCurrentQty;
              }
            } else {
              d.consumedBML += current.newQty;
            }
          } else if (previous !== undefined) {
            d.consumedBML = d.consumedBML - previous.newQty;
          }
        }
      });
    }
    this.beamOrderLines = demands;
    this.beamOrderLinesGridData = process(demands, { group: this.state.group });
    this.beamOrderLinesGridData.total = this.beamOrderRowService.totalCount; // setting total values
  }

  public getMaxCount(change: SimpleChange = null): any {
    return change.currentValue.length >= change.previousValue.length ? change.currentValue : change.previousValue;
  }

  public onRowsUppdated(demands: Demandline[]) {
    this.loading = false;
    this.loadData(demands);
    this.lastUpdated = new Date();
    const rows = this.beamOrderLines.filter((bol) => this.selectedIDs.find((id) => bol.rowId === id));
    this.selectRowCount = rows.length;
    this.forParentSelection(rows, []);
  }

  public updateSelectAllCheckboxState() {
    const len = this.selectedIDs.length;
    if (len === 0) {
      this.selectAllState = 'unchecked';
    } else if (len > 0 && len < this.beamOrderLines.length) {
      this.selectAllState = 'indeterminate';
    } else {
      this.selectAllState = 'checked';
    }
  }

  public onSelectAllChange(checkedState: SelectAllCheckboxState) {
    const check: any = document.getElementsByClassName('group-check');
    if (checkedState === 'checked') {
      this.selectedIDs = this.beamOrderLines.map((y) => y.rowId);

      // eslint-disable-next-line no-var
      for (var c1 of check) {
        c1.checked = true;
      }
      this.selectAllState = 'checked';
    } else {
      this.selectedIDs = [];
      this.selectAllState = 'unchecked';
      // eslint-disable-next-line no-var
      for (var checkbox of check) {
        checkbox.checked = false;
      }
    }
  }

  public dataStateChange(event: DataStateChangeEvent): void {
    this.state = event;
    if (this.state.take === 0) {
      this.state.take = this.beamOrderLinesGridData.total;
    }
    if (!this.initialLoad) {
      this.loading = true;
      this.saveUserGridSettings();
      this.query();
    }
  }

  public selectGroup(event: Event, group: any): boolean {
    const groupedItems = this.getGroupedItemsRecursively(group.items);
    const groupedItemIds = groupedItems.map((i) => i.rowId);
    const selected = event.target['checked'];
    if (selected) {
      this.selectedIDs = this.selectedIDs.concat(groupedItemIds);
    } else {
      this.selectedIDs = this.selectedIDs.filter(function (value) {
        return groupedItemIds.indexOf(value) === -1;
      });
    }
    this.onSelect(null);
    return selected;
  }

  public onSelect(e: SelectionEvent) {
    const rows = this.beamOrderLines.filter((bol) => this.selectedIDs.find((rowId) => bol.rowId === rowId));

    this.currentSelectedRows = rows;
    this.selectRowCount = rows.length;
    this.beamOrderRowService.selecOrderRows(rows);
    this.updateSelectAllCheckboxState();
    this.disableOptimizerBtn = rows.some(x => x.isSpecialFormBeam) || this.selectedIDs.length < 1;
  }

  private getGroupedItemsRecursively(items: any): any[] {
    let foundItems = [];
    if (items.length > 0 && items[0]['aggregates']) {
      items.forEach((item) => {
        foundItems = foundItems.concat(this.getGroupedItemsRecursively(item.items));
      });
    } else {
      foundItems = items;
    }
    return foundItems;
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.currentQtyReproduce = dataItem.transportPackageAllocation?.unitsExtra;
    this.closeEditor(sender);
    this.formGroup = createFormGroup(dataItem);
    this.queryAlternativeModels(this.formGroup.get('materialDescription').value);

    this.editedRowIndex = rowIndex;

    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public validate(currentQtyReproduce, updatedQtyReproduce) {
    if (updatedQtyReproduce > currentQtyReproduce) {
      this.notificationService.notifyErrorAppChanel('OrderViewTranslation.Qty_Reproduce_High');
      return false;
    } else if (updatedQtyReproduce < 0) {
      this.notificationService.notifyErrorAppChanel('OrderViewTranslation.Qty_Reproduce_Negative');
      return false;
    }
    return true;
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew }): void {
    this.loading = true;
    const dl = formGroup.value;
    this.saveInProgress = true;
    const isValid = this.validate(this.currentQtyReproduce, dl.qtyReproduce);
    if (isValid) {
      this.beamOrderRowService
        .savePartial(<IDemandPartialDTO>{
          demandID: dl.DeamndId,
          lamminaPlaneThickness: +dl.LamminaPlaneThickness,
          lamminaPlaneWidth: dl.LamminaPlaneWidth,
          beamLamminaDimensionId: dl.beamLamminaDimensionId,
          materialIndex: dl.materialIndex,
          qtyReproduce: dl.qtyReproduce,
          transportPackageId: dl.transportPackageId
        })
        .subscribe(
          (d) => {
            this.saveInProgress = false;

            this.beamOrderLinesGridData.data
              .filter((b) => b.BeamOrderRowID === dl.DeamndId)
              .forEach((h) => {
                h.LamminaPlaneThickness = d.lamminaPlaneThickness;
                h.BeamLamminaDimensionID = d.beamLamminaDimensionId;
                h.LamminaPlaneWidth = d.lamminaPlaneWidth;
                h.materialIndex = d.materialIndex;
                h.materialDescription = d.materialDescription;
                h.transportPackageAllocation.unitsExtra = d.qtyReproduce;
              });

            this.beamOrderLines
              .filter((b) => b.BeamOrderRowID === dl.DeamndId)
              .forEach((h) => {
                h.DeafultLamminaPlaneThicknes = d.lamminaPlaneThickness;
                h.BeamLamminaDimensionID = d.beamLamminaDimensionId;
                h.LamminaPlaneWidth = d.lamminaPlaneWidth;
                h.NumberOfLaminas = d.numberOfLamminas;
                h.materialIndex = d.materialIndex;
                h.materialDescription = d.materialDescription;
                h.deafultNumberOfBeamsPerBeamMaterial = d.numberOfBeamsPerBeamMaterial;
                h.transportPackageAllocation.unitsExtra = d.qtyReproduce;
              });

            // this.loadData();
            // this.onSelect(null);
            sender.closeRow(rowIndex);
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
    } else {
      this.loading = false;
      this.saveInProgress = false;
    }
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  public updateLaminaValidValues(event, formGroup) {
    if (!event) {
      return;
    }
    const index = this.planeLaminaThickness[0].validValues.some((x) => x.valueDouble === Number(event));
    if (!index && formGroup.dirty) {
      this.updateLaminaThicknessEntityPropertyValueValid = <IEntityPropertyValueValid>{
        propertyCode: PLANE_LAMINA_THICKNESS,
        valueDouble: Number(event)
      };
      this.planeLaminaThickness[0].validValues.push(this.updateLaminaThicknessEntityPropertyValueValid);
    }
  }

  getPropertyValue(dataItem: any, propertyCode: string) {
    const p = dataItem.propertyCode === propertyCode;

    if (p) {
      return dataItem.displayValue;
    } else {
      return null;
    }
  }

  getBLDDescription(id: number): string {
    return this.beamLamminaDimensions.find((bld) => bld.beamLamminaDimensionID === id).width + '';
  }

  queryAlternativeModels(searchTerm: string) {
    if (searchTerm.length >= 3) {
      const entityState: EntityFilter = {} as EntityFilter;
      entityState.searchTermCode = searchTerm;
      entityState.take = 25;

      this.beamOrderRowService.getAlternateCodes(entityState).subscribe((result) => {
        this.altModels = result;
      });
    }
  }

  onMaterialSelected(material: IAltModelResponceDTO) {
    const entity = this.altModels.find((x) => x.index === material.index);

    this.formGroup.patchValue({
      materialIndex: entity.index,
      materialDescription: entity.materialDescription,
      LamminaPlaneThickness: entity.lamminaPlaneThickness ?? this.formGroup.get('LamminaPlaneThickness').value,
      beamLamminaDimensionId: entity.beamLamminaDimensionId ?? this.formGroup.get('beamLamminaDimensionId').value
    });
  }

  public onSelecttionChange(e) {
    this.forParentSelection(e.selectedRows, e.deselectedRows);
  }

  private forParentSelection(selectedData: any[], deselectedData: any[]) {
    selectedData.forEach(data => {
      const selectedGrp = this.beamOrderLines.filter(x => x.transportPackageAllocation.transportPackageID === data.dataItem.transportPackageAllocation.transportPackageID).map(x => {
        return x.rowId;
      });
      const check: any = document.getElementById('selectGrouptransportPackageCode' + data.dataItem?.transportPackageAllocation?.packageID);
      if (selectedGrp.every(item => this.selectedIDs.includes(item))) {
        check.indeterminate = false;
        check.checked = true;
      } else if (selectedGrp.some((item) => this.selectedIDs.includes(item))) {
        check.checked = false;
        check.indeterminate = true;
      } else {
        check.checked = false;
        check.indeterminate = false;
      }
    });
    deselectedData.forEach(data => {
      const deselectedGrp = this.beamOrderLines.filter(x => x.transportPackageAllocation.transportPackageID === data.dataItem.transportPackageAllocation.transportPackageID).map(x => {
        return x.rowId;
      });
      const check: any = document.getElementById('selectGrouptransportPackageCode' + data.dataItem?.transportPackageAllocation?.packageID);
      if (deselectedGrp.every(item => this.selectedIDs.includes(item))) {
        check.indeterminate = false;
        check.checked = false;
      } else if (deselectedGrp.some((item) => this.selectedIDs.includes(item))) {
        check.indeterminate = true;
        check.checked = false;
      } else {
        check.indeterminate = false;
        check.checked = false;
      }
    });
  }

  public openOptimizationEditor() {
    this.optimise.emit();
  }

  public async resetUserSettings() {
    await this.userSettingService.delete(this.getUserGridSettingsKey()).subscribe();
    window.location.reload();
  }

  public openConfirmationDialog() {
    this.removeDemandInProgress = true;
  }

  public openConfirmationDialogRemoveByRow() {
    this.removeDemandByRowInProgress = true;
  }

  public openConfirmationDialogRemoveByPackage() {
    this.removeDemandByPackageInProgress = true;
  }

  confirmRemoveDemand(confirmChange: boolean) {
    const ret: Array<{ text: string; values: any }> = new Array<{ text: string; values: any }>();
    const selectedDemandIDs = [];
    let rows: Demandline[];
    if (confirmChange) {
      if (this.currentSelectedRows.length > 0) {
        rows = this.currentSelectedRows;
      } else {
        rows = this.beamOrderLines.filter((bol) => this.selectedIDs.find((id) => bol.rowId === id));
      }
      if (this.removeDemandByPackageInProgress) {
        rows.forEach((element) => {
          selectedDemandIDs.push(element.transportPackageId);
        });
        this.beamOrderRowService.removeDemandByPackage(selectedDemandIDs).subscribe((t) => {
          this.selectedIDs = [];
          this.notificationService.notifyInfoAppChanel('Operative.PackageDeletedSuccessfully');
          this.dataStateChange(this.state as DataStateChangeEvent);
        });
      } else {
        rows.forEach((element) => {
          selectedDemandIDs.push(element.BeamOrderRowID);
        });
        this.beamOrderRowService.removeDemand(selectedDemandIDs, this.removeDemandByRowInProgress).subscribe((t) => {
          this.selectedIDs = [];
          ret.push({ text: 'Operative.DemandRecordDeleted', values: { noOfRecords: t } });
          this.deleteInfoNotification(ret);
          this.dataStateChange(this.state as DataStateChangeEvent);
        });
      }
    }
    this.removeDemandInProgress = false;
    this.removeDemandByRowInProgress = false;
    this.removeDemandByPackageInProgress = false;
  }

  private deleteInfoNotification(ret: { text: string; values: any }[]) {
    ret.forEach((info) => {
      this.notificationService.notifyInfoAppChanel(
        info.text,
        'Operative.DemandDeletedSuccessfully',
        info.values
      );
    });
  }

  public resizeGrid(value) {
    this.isLeftMajor = value;
    this.getUserGridSettings().subscribe(() => this.query());
    this.saveUserSettings();
    this.updateSize.emit(value);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  addOrViewSpecification(selectedDemand: Demandline): void {
    this.demandSpecificationService.openDialog(selectedDemand).afterClosed().subscribe((response: IDemandDTO) => {
      if (response !== undefined) {
        this.beamOrderLinesGridData.data
          .filter((lines) =>
            lines.items.filter((filteredlines) => filteredlines.BeamOrderRowID === selectedDemand.BeamOrderRowID)
              .forEach((demand) => {
                this.beamOrderRowService.setDemandFields(demand, response);
              })
          );
      }
    });
  }

  public onShowPlanedChanged(value: boolean) {
    this.loading = true;
    this.showPlanned = value;
    this.saveUserSettings();
    this.query();
  }

  public onShowWithNoGluPlan(value: boolean) {
    this.loading = true;
    this.showOnlyWithNoGluPlan = value;
    this.saveUserSettings();
    this.query();
  }

  saveUserSettings() {
    this.userSettingService.save<IOrderViewSetting>(
      UserSettingkeyEnum.order_view,
      <IOrderViewSetting>{
        isLeftMajor: this.isLeftMajor,
        showPlanned: this.showPlanned,
        showOnlyWithNoGluPlan: this.showOnlyWithNoGluPlan
      }
    ).subscribe();
  }

  getUserSettings(): Observable<any> {
    return this.userSettingService.get<IOrderViewSetting>(UserSettingkeyEnum.order_view).pipe(tap(settings => {
      if (settings) {
        this.applyUserSettings(settings);
      }
    }));
  }

  private applyUserSettings(settings: IOrderViewSetting): void {
    this.showPlanned = settings?.showPlanned ?? true;
    this.showOnlyWithNoGluPlan = settings?.showOnlyWithNoGluPlan ?? true;

    if (settings?.isLeftMajor && settings.isLeftMajor !== this.isLeftMajor) {
      this.isLeftMajor = settings.isLeftMajor;
      this.updateSize.emit(this.isLeftMajor);
    }
  }

  getUserGridSettings(): Observable<any> {
    return this.userSettingService.get<IPersistedGridState>(this.getUserGridSettingsKey()).pipe(tap(settings => {
      this.applyUserGridSettings(settings);
    }));
  }

  private applyUserGridSettings(settings: IPersistedGridState): void {
    if (!settings) {
      return;
    }
    this.grid.columns.filter(c => c.includeInChooser !== false).forEach(c => {
      c.hidden = true;
    });

    this.sortByOrderIndex(settings.columnSettings).forEach(colSetting => {
      let col;
      if (colSetting.field === this.translate.instant('App.Action')) {
        col = this.grid.columns.find(c => c.title === colSetting.field);
      } else {
        col = this.grid.columns.find(c => (c as ColumnComponent).field === colSetting.field);
      }

      if (col) {
        col.width = colSetting.width ?? col.width ?? 200;
        col.hidden = false;
      }
    });

    this.reorderInProgress = true;

    this.sortByOrderIndex(settings.columnSettings).forEach((colSetting, index) => {
      if (colSetting) {
        const col = this.grid.columns.find(c => (c as ColumnComponent).field === colSetting.field);

        if (col) {
          // Index + 1 to make sure column selector is zero
          this.grid.reorderColumn(col, index + 1, { before: true });
        }
      }
    });

    this.reorderInProgress = false;

    if (settings.state) {
      this.state = settings.state;
    }
  }

  private sortByOrderIndex<T extends { orderIndex?: number }>(items: T[]): T[] {
    return items.sort((a, b) => (a.orderIndex ?? 0) - (b.orderIndex ?? 0));
  }

  private getUserGridSettingsKey(): string {
    return this.isLeftMajor ? UserSettingkeyEnum.grid_gluelam_demandList_exp : UserSettingkeyEnum.grid_gluelam_demandList;
  }

  public saveUserGridSettings(): void {
    if (!this.reorderInProgress) {
      setTimeout(() => {
        const settings = <IPersistedGridState>{
          state: this.state,
          columnSettings: this.grid.columns.filter(c => c.hidden == false).map(col => {
            if ((col as ColumnComponent)?.field) {
              return <IPersistedColState>{
                field: (col as ColumnComponent).field,
                width: col.width,
                orderIndex: col.orderIndex
              };
            } else if (col.title === this.translate.instant('App.Action')) {
              return <IPersistedColState>{
                field: col.title,
                width: col.width,
                orderIndex: col.orderIndex
              };
            }
          })
        };
        this.userSettingService.save<IPersistedGridState>(this.getUserGridSettingsKey(), settings).subscribe();
      }, 500);
    }
  }
}
