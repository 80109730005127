<h1 mat-dialog-title>
  {{ "OptimizationOrderTranslation.OrderOptimization" | translate }}
</h1>
<mat-dialog-content>
  <kendo-stepper
      [steps]="optiSteps"
      [stepType]="stepType"
      [(currentStep)]="current">
      <ng-template
          kendoStepperLabelTemplate
          let-step>
          <div style="display: flex; flex-direction: column;">
              <span>{{ step.label }}</span>
              <span *ngIf="step.time">{{ step.time }} sec</span>
              <span>{{ step.state }}</span>
          </div>



      </ng-template>
  </kendo-stepper>
  <form
      [formGroup]="form"
      class="col">
      <div class="row">
        <kendo-formfield>
          <div class="row mb-1">
            <app-machine-selector-dropdown
              formControlName="machineId"
              (selectedMachine)="onMachineSelected($event)"
              [readonly]="true">
            </app-machine-selector-dropdown>
          </div>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label translate>OptimizationOrderTranslation.MaxGluePlanLength</kendo-label>
          <kendo-numerictextbox format="n0"
                formControlName="maxGluePlanLength"></kendo-numerictextbox>
      </kendo-formfield>
    </div>
    <div class="row">
      <kendo-formfield>
        <kendo-label translate>OptimizationOrderTranslation.MaxPressLength</kendo-label>
        <kendo-numerictextbox format="n0"
              formControlName="maxPressLength"></kendo-numerictextbox>
    </kendo-formfield>
        <kendo-formfield>
            <kendo-label translate>OptimizationOrderTranslation.MaxPressWidth</kendo-label>
            <kendo-numerictextbox format="n0"
                formControlName="maxPressWidth"></kendo-numerictextbox>
        </kendo-formfield>
    </div>

      <div class="row">
        <kendo-formfield>
          <kendo-label translate>OptimizationOrderTranslation.MinPressHight</kendo-label>
          <kendo-numerictextbox format="n0"
              formControlName="minPressHight"></kendo-numerictextbox>
      </kendo-formfield>
          <kendo-formfield>
              <kendo-label translate>OptimizationOrderTranslation.MaxPressHight</kendo-label>
              <kendo-numerictextbox format="n0"
                  formControlName="maxPressHight"></kendo-numerictextbox>
          </kendo-formfield>
      </div>

      <div class="row">
        <kendo-formfield style="flex-grow: 2.5;">
          <kendo-label translate>OptimizationOrderTranslation.MaxLayers</kendo-label>
              <kendo-dropdownlist
              [data]="numberOfLayers"
              [valueField]="'value'"
              [textField]="'key'"
              formControlName="maxLayers"
              [valuePrimitive]="true"
    > </kendo-dropdownlist>
      </kendo-formfield>
          <kendo-formfield>
              <kendo-label translate>OptimizationOrderTranslation.MaxBeamInBeamMaterial</kendo-label>
              <kendo-numerictextbox format="n0"
                  formControlName="maxBeamInBeamMaterial"></kendo-numerictextbox>
          </kendo-formfield>
      </div>
      <div class="row">
          <kendo-formfield>
              <kendo-label translate>
                  OptimizationOrderTranslation.MaxGlueplans
              </kendo-label>
              <kendo-numerictextbox format="n0"
                  formControlName="maxGlueplans"></kendo-numerictextbox>
          </kendo-formfield>
          <kendo-formfield>
              <kendo-label translate>
                  OptimizationOrderTranslation.MaxGluesets
              </kendo-label>
              <kendo-numerictextbox format="n0"
                  formControlName="maxGluesets"></kendo-numerictextbox>
          </kendo-formfield>
      </div>



      <div class="row">
          <kendo-formfield>
              <kendo-label
                  translate
                  style="flex-grow: 1;">
                  OptimizationOrderTranslation.Optimization_Model
              </kendo-label>
              <kendo-dropdownlist
                  style="flex-grow: 1;"
                  [data]="watsonDeployedModels"
                  formControlName="deployedModelId"
                  [valuePrimitive]="true"
                  [textField]="'Name'"
                  [valueField]="'Id'">
              </kendo-dropdownlist>
          </kendo-formfield>
          <kendo-formfield>
              <kendo-label translate>
                  OptimizationOrderTranslation.MaxSolveTime
              </kendo-label>
              <kendo-numerictextbox format="n0"
                  formControlName="maxSolveTime"></kendo-numerictextbox>
          </kendo-formfield>
      </div>
      <div>
          <div class="row">
              <mat-label style="width: 200px;">ExecutionStatus:</mat-label>
              {{jobState?.status ?? 'Not Started'}}
          </div>
          <div class="row">
              <mat-label style="width: 200px;">SolveStatus:</mat-label>
              {{jobState?.solveStatus ?? 'Not Started'}}
          </div>
          <div class="row">
              <mat-label style="width: 200px;">JobID:</mat-label>
              {{JobUrl ?? 'Not Started'}}
          </div>
      </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="row">
  <button
      mat-raised-button
      mat-dialog-close
      (click)="close()">
      {{ "App.Close" | translate }}
  </button>
  <div style="flex-grow: 1;"></div>
  <button
      [disabled]="true"
      mat-raised-button
      color="primary">
      {{ "OptimizationOrderTranslation.SetDefault" | translate }}
  </button>
  <button
      [disabled]="true"
      mat-raised-button
      color="primary">
      {{ "App.SaveSetting" | translate }}
  </button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <!-- <a class="btn btn-success" (click)="saveBeamMaterial()">Save</a> -->
  <button
      mat-raised-button
      color="primary"
      (click)="runOptimazation()"
      [disabled]="!form.valid"
      translate>
      {{ "OptimizationOrderTranslation.Optimize" | translate }}
  </button>
</mat-dialog-actions>
