/* eslint-disable indent */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IHandelingUnit, HandelingUnitService } from '@app/core/services/http-services/stock/handeling-unit-report.service';
import { IHandelingUnitProperty, IHandelingUnitProp, QuantityReportService, IQuantityReport } from '@app/core/services/http-services/stock/quantity-report.service';

const createFormGroup = (hu: IHandelingUnit) => new UntypedFormGroup({
  qty: new UntypedFormControl(hu.qty, []),
  qtyUnitCode: new UntypedFormControl(hu.qtyUnitCode, [])
});

@Component({
  selector: 'app-handeling-unit-form',
  templateUrl: './handeling-unit-form.component.html',
  styleUrls: ['./handeling-unit-form.component.css']
})
export class HandelingUnitFormComponent implements OnInit {
  public listdata:IQuantityReport[] = [];
  public griddatadetails: IHandelingUnitProperty[] = [];
  public handelingunitprop:IHandelingUnitProp[] = [];
  public viewquantity: Observable<GridDataResult>;

  state: State = {
    skip: 0,
    take: 10,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public hu:IHandelingUnit;

  public form:UntypedFormGroup = createFormGroup(<IHandelingUnit> <unknown>{ qty: 0, qtyUnitCode: 'ddd' });

public gridLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { id: number },
    public handelingunitreportservice: HandelingUnitService, public quantityreportservice:QuantityReportService
  ) {

  }

  ngOnInit(): void {
    this.gridLoading = true;
    this.viewquantity = this.quantityreportservice;

    this.quantityreportservice.gethandelingunitprop().subscribe(a => {
      this.handelingunitprop = a;
    });

    this.quantityreportservice.queryQuantity(this.state, this.data.id);

    this.handelingunitreportservice.get(this.data.id).subscribe(hu => {
      this.hu = hu;
      this.form = createFormGroup(hu);
    });
    this.gridLoading = false;
  }

  loadHandelingUnitProp() {

  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.gridLoading = true;
    this.state = state;
    // this.quantityreportservice.queryQuantity(state, this.handelingUnitId);
    this.gridLoading = false;
  }

  getPropertyValue(dataItem: IQuantityReport, propcode:string) {
    const p = dataItem.handelingUnitPropValues.find(p => p.propCode === propcode);

    if (p) {
      return this.getStringValue(p);
    } else {
     return 'N/A';
    }
  };

  getStringValue(pv: IHandelingUnitProperty): string {
    if (pv.valueInt !== 0) {
      return pv?.valueInt?.toString();
    } else if (pv.valueDecimal !== 0) {
      return pv?.valueDecimal?.toString();
    } else {
      return pv?.valueString;
    }
  };

  public onSubmit() {

  }
}
