import { Component, ViewChild } from '@angular/core';
import { EntityGroupService, IEntityGroup } from '@app/core/services/custom-services/entity-group.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { KendoGridSetUp } from '@app/core/services/custom-services/kendo-grid-setup';
import { EntityGroupBindingDirective } from './entity-group-binding.directive';

@Component({
  selector: 'app-entity-group-editor',
  templateUrl: './entity-group-editor.component.html',
  styleUrls: ['./../entity-editor.component.css']
})

export class EntityGroupEditorComponent {
  state: State = {
    skip: 0,
    take: KendoGridSetUp.pageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    sort: []
  };

  public entityGroup: IEntityGroup = null;
  public isCollapsed: boolean = true;
  public isNew = false;

  @ViewChild(EntityGroupBindingDirective) entityGroupBinding: EntityGroupBindingDirective;

  constructor(private entityGroupService: EntityGroupService, private notificationService: AppNotificationService) { }

  onNewEntity() {
    this.isNew = true;
    this.entityGroup = null;
    this.entityGroup = <IEntityGroup>{
      index: -1,
      code: null,
      description: null,
      entityTypeCode: null,
      resourceTypeCode: '-',
      defaultBaseUnitCode: 'M3',
      entityGroupProperties: [],
      stockLocationConsumeId: null,
      stockLocationProduceId: null,
      entityGroupSites: []
    };
    this.isCollapsed = false;
    const dialogOpened = this.entityGroupService.openDialog(this.entityGroup, true);
    dialogOpened.afterClosed().subscribe((result) => {
      if (result) {
        this.notificationService.notifySucsessAppChanel('EntityTranslation.EntityAdded');
      }
      this.isCollapsed = true;
    });
  }

  onEditEntity({ dataItem }) {
    this.entityGroup = dataItem;
    const dialogOpened = this.entityGroupService.openDialog(this.entityGroup, false);
    dialogOpened.afterClosed().subscribe((result) => {
      if (result) {
        this.notificationService.notifySucsessAppChanel('EntityTranslation.EntityUpdated');
      }
    });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
  }
}
