import { BeamMaterial, BeamMaterialTypeeEnum } from './beam-material.model';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { BeamLamminaDimension } from './beam-lammina-dimension.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { Guid } from '@app/core/models/Guid';
import { BeamMaterialType } from './beam-material-type.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IEntity } from '@app/core/services/http-services/model/entity.service';
import { IBeamMaterial, IBeamMaterialGlueSet } from '@app/core/services/http-services/gluelam/glue-set.service';
import { BeamOrderLineCreator, Demandline } from '@app/core/models/demand-line.model';

marker('GlueSet.Length');
marker('GlueSet.Height');

export class BeamMaterialGlueSetCreator {
  // pass config and pass further to BMGS
  static FromJson(
    gs: IBeamMaterialGlueSet,
    conf: ConfigurationService,
    saveAll: boolean
  ): BeamMaterialGlueSet {
    const bmgsls = new Array<BeamMaterialGlueSetLayer>();
    const template: BeamMaterialType = gs.Template
      ? new BeamMaterialType(
        gs.Template.beamMaterialTypeID,
        gs.Template.typeGroupCode,
        gs.Template.name,
        gs.Template.description,
        gs.Template.color,
        gs.Template.colorLight,
        gs.Template.fixedHight,
        gs.Template.radius,
        gs.Template.isNormal,
        gs.Template.isSpecialForm,
        gs.Template.isManual
      )
      : null;
    // if (gs.TemplateID) {
    //   template = KonvaHelper.GetTemplate(gs.TemplateID);
    // }
    const data = gs.beamMaterialGlueSetLayers;
    data.forEach((bmgsl, index) => {
      const bms: BeamMaterial[] = [];
      // const bmIndex = 0;
      for (const bm of bmgsl.beamMaterials) {
        // bm.layerIndex = bmIndex++;
        bms.push(this.FromJsonBM(bm));
      }
      // if(index == 0){
      bmgsls.push(new BeamMaterialGlueSetLayer(bms));
      // }
    });

    return new BeamMaterialGlueSet(
      gs.glueSetID,
      gs.name,
      gs.length,
      gs.locked,
      bmgsls,
      gs.efficiency,
      gs.materialNeedM3,
      template,
      saveAll,
      <BeamLamminaDimension>{ beamLamminaDimensionID: gs.beamLamminaDimension?.beamLamminaDimensionID, thickness: gs.beamLamminaDimension?.thickness, width: gs.beamLamminaDimension?.width, speciesCode: gs.beamLamminaDimension?.speciesCode },
      gs.errorMessageText,
      false,
      false,
      conf,
      gs.sequenceIndexNavigation,
      gs.beamMaterialGlueSetState,
      gs.performedTimes,
      gs.gluesetBatchIndex
    );
  }

  static FromJsonBM(bm: IBeamMaterial): BeamMaterial {
    const bmo = new BeamMaterial(
      bm.beamMaterialID,
      bm.layer,
      bm.layerOrder,
      bm.numberOfLamminas,
      bm.locked,
      bm.length,
      bm.productionNote,
      bm.planedThickness,
      new BeamMaterialType(
        bm.beamMaterialType?.beamMaterialTypeID,
        bm.beamMaterialType?.typeGroupCode,
        bm.beamMaterialType?.name,
        bm.beamMaterialType?.description,
        bm.beamMaterialType?.color,
        bm.beamMaterialType?.colorLight,
        bm.beamMaterialType?.fixedHight,
        bm.beamMaterialType?.radius,
        bm.beamMaterialType?.isNormal,
        bm.beamMaterialType?.isSpecialForm,
        bm.beamMaterialType?.isManual
      ),
      bm.materialIndex,
      bm.width,
      bm.height,
      null,
      null,
      bm.takeTestPsc,
      bm.testPscLenght,
      bm.isSplit,
      bm.overlength
    );
    bmo.guid = Guid.newGuid();
    const data = bm.beamMaterialLines;
    for (const bml of data) {
      const id = bml.beamOrderRowID;

      bml.cut = bml.cut ? bml.cut : 1;

      bmo.beamMaterialLines.push(
        new BeamMaterialLine(
          BeamOrderLineCreator.FromJsonObj(bml.transportPackageDemand),
          bml.length,
          id,
          bml.numberOfBeams,
          bml.cut,
          bml.indexInCut,
          null,
          Guid.newGuid(),
          bml.transportPackageId,
          bml.beamMaterialLineID,
          bml.beamMaterialID,
          bml.transportPackageAllocation,
          bml.productionStateCode,
          bml.productionStateGroupCode
        )
      );
    }

    return bmo;
  }

  /* public static getBeamOrderLine(
    id: number,
    beamOrderLines: Demandline[]
  ): Demandline {
    return beamOrderLines.find(function(item) {
      // since id (OrderIdentifier) is coming as string from optimization, we need to convert them.
      return item.BeamOrderRowID === id;
    });
  } */
}

export class BeamMaterialGlueSet {
  public TemplateID: number;
  public _length: number;
  public Index: number;

  constructor(
    public beamMaterialGlueSetID: number,
    public name: string,
    length: number,
    public locked: boolean,
    public layers: BeamMaterialGlueSetLayer[],
    public efficiency: number,
    public materialNeedM3: number,
    public Template: BeamMaterialType,
    public save: boolean = false,
    public beamLamminaDimension: BeamLamminaDimension,
    public errorMessageText: string = null,
    public remove: boolean = false,
    public send: boolean = false,
    public conf: ConfigurationService,
    public beamMaterialGlueSetSequence: BeamMaterialGlueSetSequence,
    public beamMaterialGlueSetState: BeamMaterialGlueSetState,
    public performedTimes: number = 1,
    public glusetBatch: number = -1,
    public guid:string = Guid.newGuid(),
    public isMainProductWE: boolean = false
  ) {
    this.length = length;
    // this.Repeats = this.performedTimes;

    if (Template != null) {
      this.TemplateID = Template.BeamMaterialTypeID;
    }
  }

  public toJSON():any {
    return {
      glueSetID: this.beamMaterialGlueSetID,
      name: this.name,
      length: this.length,
      locked: this.locked,
      beamLamminaDimensionID: this.beamLamminaDimension?.beamLamminaDimensionID,
      beamMaterialGlueSetLayers: this.layers,
      efficiency: this.efficiency,
      TemplateID: this.TemplateID,
      save: this.save,
      remove: this.remove,
      send: this.send,
      beamMaterialGlueSetState: this.beamMaterialGlueSetState,
      beamMaterialGlueSetSequence: this.beamMaterialGlueSetSequence,
      gluesetBatchIndex: this.glusetBatch,
      performedTimes: this.performedTimes,
      guid: this.guid
    };
  }

  public get length(): number {
    return this._length;
  }

  public set length(value: number) {
    const minLenght = this.conf.PRESS_LENGTH_MIN > 0 ? this.conf.PRESS_LENGTH_MIN : 6000;

    const totMinLenght = minLenght;

    this._length = value > totMinLenght ? value : totMinLenght;
  }

  public GetBeamMaterialByIndex(
    beamMaterialIndex: number,
    layerIndex: number
  ): BeamMaterial {
    return this.layers[layerIndex].BeamMaterials[beamMaterialIndex];
  }

  public AddBeamMaterial(beamMaterial: BeamMaterial, layerIndex: number) {
    this.layers[layerIndex].BeamMaterials.push(beamMaterial);
  }

  public RemoveBeamMaterial(beamMaterial: BeamMaterial, layerIndex: number) {
    this.layers[layerIndex].BeamMaterials.splice(beamMaterial.layerOrder, 1);
  }

  /**/
  public ForEachBMInBothLayers(
    funcOnEach: (bm: BeamMaterial, bmIndex: number) => void,
    funcBetween: () => void = null
  ) {
    this.layers.forEach((bml) => {
      bml.BeamMaterials.forEach((bm, index) => funcOnEach(bm, index));
      if (funcBetween !== null) {
        funcBetween();
      }
    });
  }

  public calcMaxLength(): number {
    const k = this.layers.map(x => Math.max(...x.BeamMaterials.map(y => y.length)));

    return Math.max(0, ...k);
  }

  get beammaterials(): BeamMaterial[] {
    let beamMaterials: BeamMaterial[] = [];

    this.layers.forEach((bmgsl) => {
      beamMaterials = beamMaterials.concat(bmgsl.BeamMaterials);
    });

    return beamMaterials.sort((a, b) => {
      return a.layerOrder > b.layerOrder ? 1 : -1;
    });
  }

  public GetTotalLammnias(): number {
    let totLammnias = 0;
    this.layers.forEach((bmgsl) => {
      bmgsl.BeamMaterials.forEach((bm) => {
        totLammnias += bm.numberOfLamminas;
      });
    });

    return totLammnias;
  }

  public CalcMaterialNeedM3(dim: BeamLamminaDimension): number {
    const total: number =
      (this.GetTotalLammnias() *
        dim.thickness *
        dim.width *
        this.length) /
      1000 ** 3;

    return Math.round(total * 1000) / 1000;
  }

  public AdjustBeamMaterialLines() {
    let hasTemplate = false;

    let activeLayer = this.layers[0].BeamMaterials;

    // TODO: IMPLEMNET TAEMPLATETE
    this.ForEachBMInBothLayers(
      (bm, index) => {
        bm.beamMaterialLines.forEach((bml) => {
          if (bml.transportPackageDemand.BeamMaterialType !== null) {
            hasTemplate = true;
          }
        });

        if (
          bm.beamMaterialLines.length < 1 &&
          bm.BeamMaterialType.TypeGroupCode === BeamMaterialTypeeEnum.Normal
        ) {
          activeLayer.splice(index, 1);
        }
      },
      () => {
        activeLayer = this.layers[0].BeamMaterials;
      }
    );

    if (!hasTemplate) {
      this.Template = null;
      this.TemplateID = null;
      this.layers.forEach((bmgsl) => {
        bmgsl.BeamMaterials.filter((bm) => bm.IsTemplate === false);
      });
    }

    const maxLength = this.calcMaxLength();

    this.length = maxLength;

    this.layers.forEach((bml) => {
      bml.BeamMaterials.forEach((bm, index) => {
        bm.layerOrder = index;
        bm.length = bm.getLength();
      });
    });
  }

  public AddTemplate(bml: Demandline, poduct:IEntity) {
    this.Template = bml.BeamMaterialType;

    this.AddBeamMaterial(
      new BeamMaterial(
        -1,
        -1,
        -1,
        -1,
        false,
        bml.BeamMaterialType.getHight(this.length),
        'Mall - ' + bml.BeamMaterialType.Name,
        0,
        this.Template,
        bml.materialIndex,
        100,
        100,
        bml.BeamMaterialType
      ),
      0
    );

    /*

    TODO: FIX */

    // if (bml.Width < ConfigurationService.BEAM_MAX_WIDTH_FOR_TWO_LAYERS) {
    if (bml.width < this.conf.BEAM_MAX_WIDTH_FOR_TWO_LAYERS) {
      // if (bml.Width < 174) {
      this.AddBeamMaterial(
        new BeamMaterial(
          -1,
          -1,
          -1,
          -1,
          false,
          bml.BeamMaterialType.getHight(this.length),
          'Mall - ' + bml.BeamMaterialType.Name,
          0,
          bml.BeamMaterialType,
          bml.materialIndex,
          100,
          100,
          bml.BeamMaterialType
        ),
        0
      );
    }
  }

  public RemoveTemplate(template: BeamMaterialType) {
    if (template !== null && this.Template !== null) {
      this.Template = null;
      this.TemplateID = null;
      const bmToDelete = this.layers[0].BeamMaterials.filter(
        (x) =>
          x.BeamMaterialType.TypeGroupCode === BeamMaterialTypeeEnum.Template
      )[0];
      this.RemoveBeamMaterial(bmToDelete, 0);
    }
  }
}

export class BeamMaterialGlueSetState {
  constructor(
    public glueSetStateId: number,
    public name: string,
    public description: string,
    public isGlobal: boolean,
    public beamMaterialGlueSet: BeamMaterialGlueSet[]
  ) { }
}

export interface IBeamMaterialGlueSets {
  glueSets: BeamMaterialGlueSet[];
  existingGlueSetsId?: number[];
}

export const ProposalState: BeamMaterialGlueSetState = new BeamMaterialGlueSetState(
  0,
  'Proposal',
  'Only shown on UI',
  false,
  []
);

export const GeneratedState: BeamMaterialGlueSetState = new BeamMaterialGlueSetState(
  1,
  'Generated',
  '',
  false,
  []
);
export const PlannedState: BeamMaterialGlueSetState = new BeamMaterialGlueSetState(
  2,
  'Planned',
  '',
  true,
  []
);
export const StartedState: BeamMaterialGlueSetState = new BeamMaterialGlueSetState(
  3,
  'Planned',
  '',
  true,
  []
);

export const PausedState: BeamMaterialGlueSetState = new BeamMaterialGlueSetState(
  4,
  'Paused',
  '',
  true,
  []
);

export const CompletedState: BeamMaterialGlueSetState = new BeamMaterialGlueSetState(
  5,
  'Completed',
  '',
  true,
  []
);

export const CanceledState: BeamMaterialGlueSetState = new BeamMaterialGlueSetState(
  6,
  'Canceled',
  '',
  true,
  []
);

export const statesList: { Index: number; state: BeamMaterialGlueSetState }[] = [
  { Index: 0, state: ProposalState },
  { Index: 1, state: GeneratedState },
  { Index: 2, state: PlannedState }
];

export class BeamMaterialGlueSetSequence {
  constructor(
    public SequenceIndex: number,
    public EntityIndex: number,
    public BeamMaterialGlueSetId: number,
    public BeamMaterialGlueSet: BeamMaterialGlueSet[]
  ) { }
}

export class BeamMaterialGlueSetLayer {
  constructor(public BeamMaterials: BeamMaterial[]) { }

  public get totalHeight(): number {
    return this.BeamMaterials.reduce<number>((a, b) => a + b.getHight(), 0);
  }
}
