import { HttpClient } from '@angular/common/http';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Injectable } from '@angular/core';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { ICalendarLinesCriteriaInputDTO } from '@app/core/models/CalendarLinesCriteriaInputDTO';
import { IScheduleDTO } from '@app/core/models/scheduleDTO.model';
import { ScheduleEditorComponent } from '@app/modules/operative/controls/schedule-editor/schedule-editor.component';
import { ShiftCalendarComponent } from '@app/modules/operative/shift-calendar/shift-calendar.component';

marker('Operative.ScheduleCodeCanNotBeBlank');
marker('Operative.ScheduleCodeAlreadyExists');

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  constructor(private http: HttpClient
    , private kendoGridService: KendoGridService
    , private dialog: MatDialog) {
  }

  query() : Observable<GridDataResult> {
    return this.http.get<GridDataResult>(`${environment.apiUrl}Schedule`);
  }

  openDialog(scheduleId: number) : MatDialogRef<ScheduleEditorComponent, any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '1500px';
    dialogConfig.height = '800px';
    dialogConfig.data = { scheduleId };

    return this.dialog.open(ScheduleEditorComponent, dialogConfig);
  }

  getSchedule(scheduleId: number) : Observable<IScheduleDTO> {
    return this.http.get<IScheduleDTO>(`${environment.apiUrl}Schedule/${scheduleId}`);
  }

  saveSchedule(schedule: IScheduleDTO) : Observable<number> {
    return this.http.post<number>(`${environment.apiUrl}Schedule`, schedule);
  }

  removeSchedule(scheduleId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}Schedule/${scheduleId}`);
  }

  getScheduleLinesbyMachinegroupId(machineGroupId:number):Observable<IScheduleDTO> {
    return this.http.get<IScheduleDTO>(`${environment.apiUrl}Schedule/machineGroup/${machineGroupId}`);
  }

  showCalender(criteria:ICalendarLinesCriteriaInputDTO, title:string = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '1500px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(ShiftCalendarComponent, dialogConfig);
    const instance = dialogRef.componentInstance;
    instance.criteria = criteria;
    instance.label = title;
  }
}
