import { Component, OnInit } from '@angular/core';
import { ScheduleService } from '@app/core/services/http-services/operative/schedule.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
  refreshScheduleGrid: boolean = false;
  constructor(private scheduleService: ScheduleService) {
  }

  ngOnInit(): void {
  }

  addSchedule() {
    this.scheduleService.openDialog(undefined)
      .afterClosed()
      .subscribe((d) => {
        if (d) {
          this.refreshScheduleGrid = { ...d };
        }
      });
  }
}
