import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { BeamMaterialGlueSet } from '../../../core/models/beam-material-glue-set.model';
import { BeamLamminaDimension } from '../../../core/models/beam-lammina-dimension.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-gluesets-view-model',
  templateUrl: './gluesets-view-model.component.html',
  styleUrls: ['./gluesets-view-model.component.css']
})
export class GluesetsViewModelComponent implements OnInit {
  bmgs: BeamMaterialGlueSet;
  loading = true;
  dim:BeamLamminaDimension;
  plan: GluingPlan;

  constructor(
    @Inject(MAT_DIALOG_DATA)
  public data: { input: number },
    public gsService: GlueSetService,
    public glueplanService : GluePlanService,
    private dialogRef: MatDialogRef<GluesetsViewModelComponent>
  ) {

  }

  ngOnInit(): void {
    if (this.data.input != null) {
      forkJoin({
        gluset: this.gsService.getGluesetbyId(this.data.input),
        glueplan: this.glueplanService.getGluePlansByGluesetId(this.data.input)
      })
        .subscribe(result => {
          this.bmgs = result.gluset;
          this.plan = result.glueplan;
        });
    }
  }

  close() {
    this.dialogRef.close(false);
  }
}
