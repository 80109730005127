import { NgModule } from '@angular/core';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DiagramComponent } from './diagram/diagram.component';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';

import { DashbordCardDirective } from './dashboard-layout/dashbord-card.directive';

import { CardItemService } from './services/dashbord-card-item.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserModule } from '@angular/platform-browser';
import { DashboardLayoutCardComponent } from './dashboard-layout/dashboard-card-component';
import { SimpleMetricComponent } from './simple-metric/simple-metric.component';
import { PieDiagramComponent } from './pie-diagram/pie-diagram.component';
import localeSE from '@angular/common/locales/se-SE';
// import localeAT from '@angular/common/locales/de-AT';
import { registerLocaleData } from '@angular/common';
import { NotificationDisplayComponent, NotificationComponent } from './notification/notification.component';
import { EntityQtyComponent } from './entity-qty/entity-qty.component';
import { MaterialImportModule } from '@app/core/material-import.module';
import { KendoImportModule } from '@app/core/kendo-import.module';

registerLocaleData(localeSE);
// registerLocaleData(localeAT);

@NgModule({
  declarations: [DiagramComponent, DashboardLayoutComponent, DashbordCardDirective, DashboardLayoutCardComponent, SimpleMetricComponent, PieDiagramComponent, NotificationDisplayComponent, NotificationComponent, EntityQtyComponent],
  imports: [
    DashboardRoutingModule,
    MaterialImportModule,
    KendoImportModule,
    TranslateModule,
    BrowserModule,
    ChartsModule, MatGridListModule, MatCardModule, MatMenuModule, MatIconModule, MatButtonModule, LayoutModule, NgxSpinnerModule
  ],
  providers: [
    CardItemService
  ]
})
export class DashboardModule { }
