<h1 mat-dialog-title>Lägglapps Utskrift</h1>

<div mat-dialog-content>
  <div id="printThis">
    <div class="print-container">
      <!-- Content here -->
      <div class="row">

        <h1>
            {{ "GluePlan.GlueSet" | translate }}:
            {{ bmgs.beamMaterialGlueSetID }}
          </h1>

      </div>
      <div class="row">

        <strong> {{ "GluePlan.Length" | translate }}: </strong>
        {{ bmgs.length }}
        <strong> {{ "GluePlan.Material" | translate }}: </strong>
        {{ bmgs.materialNeedM3 | number }} M3
      </div>
      <strong>
            {{ "GluePlan.Total_Lamminas" | translate }}:</strong>
      {{ bmgs.GetTotalLammnias() }} st
    </div>
  </div>
  <div class="row">

    <app-beam-material-glue-set
      [bmgs]="bmgs"
      [lamminaDim]="lamminaDim"></app-beam-material-glue-set>
  </div>
  <div
    class="row"
    *ngFor="let bmgsl of bmgs.layers; let i = index">
    <h3>{{ "GluePlan.Layers" | translate }} {{i+1}}:</h3>
    <div class="col-md-12">

      <app-bm-print
        *ngFor="let bm of bmgsl.BeamMaterials; let i = index"
        [bm]="bm"
        [index]="i">
      </app-bm-print>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <a
    mat-raised-button
    color="primary"
    (click)="print()">
    {{ "GluePlan.Print" | translate }}
  </a>
</div>
