import { Component, Inject, OnInit } from '@angular/core';
import { BeamMaterialGlueSet } from '@app/core/models/beam-material-glue-set.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';

@Component({
  selector: 'app-beam-material-gluset-statical-dialog',
  templateUrl: './beam-material-gluset-statical-dialog.component.html',
  styleUrls: ['./beam-material-gluset-statical-dialog.component.css']
})
export class BeamMaterialGlusetStaticalDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { bmgs: BeamMaterialGlueSet, plan: GluingPlan, machine: IMachineDTO },
    public gluelamStaticalMediatorService: GluelamStaticalMediatorService
  ) {}

  ngOnInit() {
    if (this.data) {
      this.gluelamStaticalMediatorService.notifyGlueplanRamConfigurationChange(this.data.machine.pressbedRamConfigurations);
    }
  }
}
