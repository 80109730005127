import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CordHelper } from '../beam-material-gluset/CordHelper';
import { BeamMaterial } from '../../../../core/models/beam-material.model';
import { BeamLamminaDimension } from '../../../../core/models/beam-lammina-dimension.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { Demandline } from '@app/core/models/demand-line.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-beam-split-view-modal',
  templateUrl: './beam-split-view-modal.component.html',
  styleUrls: ['./beam-split-view-modal.component.css'],
  host:{
    class :"d-flex-col-component"
  }
})
export class BeamSplitViewModalComponent implements OnInit, AfterViewInit, OnDestroy {
  public gslayers = [];
  public ch: CordHelper;
  public containerHight = 300;
  public layerHightSide = 25;
  public bmgsWidth = 0;
  public isSplit = true;
  public beamOrderLines: Demandline[];
  public index: number = 1;

  public cutsToShow: number[] = [1];

  lamminaDim: BeamLamminaDimension;
  @ViewChild('bmgsContainer') bmgsContainer: ElementRef;
  constructor(public dialogRef: MatDialogRef<BeamSplitViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { beamMaterials: BeamMaterial[], bmgsLength: number },
    public conf: ConfigurationService) {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.bmgsWidth = this.bmgsContainer.nativeElement.offsetWidth + (this.bmgsContainer.nativeElement.offsetWidth * 0.01);
      this.setupCordSystem();
    }, 50);
  }

  setupCordSystem() {
    this.ch = new CordHelper(
      this.containerHight, environment.maxHight,
      this.bmgsWidth, this.data.bmgsLength
    );
  }

  ngOnInit(): void {
    this.isSplit = true;
    // this.beamMaterials = Array.from(this.data.beamMaterials);
    this.data.beamMaterials.forEach(x => {
      x.beamMaterialLines.forEach(bml => {
        if (!this.cutsToShow.includes(bml.Cut)) {
          this.cutsToShow.push(bml.Cut);
        }
      });
    });
    this.setupCordSystem();
  }

  calcY(i: number): number {
    return i * (this.containerHight + 10);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getBMHeight(bm: BeamMaterial) {
    return this.ch.getHight(bm.getHight());
  }

  calcX(index: number, bm) {
    let x = this.conf.BEAM_DEFAULT_OFF_CUT_BMGS;

    for (let i = 0; i < index; i++) {
      x += bm.beamMaterialLines[i].length;
    }
    return this.ch.getWidth(x);
  }

  ngOnDestroy() {
    this.data = null;
  }
}
