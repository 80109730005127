import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public static tennatKey:string = 'optinex-tenant-override';
  public static selectedMachines: string = 'selectedMachines';
  public static resourceKey: string = 'groupEntityRecuorceIndex';
  public static activityKey: string = 'groupEntityActivityIndex';
  public static sheduleDurationKey: string = 'sheduleDuration';
  public static selectedMachineGroups: string = 'selectedMachineGroups';
  public static eventsheduleSelectedType:string = 'eventsheduleSelectedType';
  public static userGroups:string = 'userGroups';
  public static newPODetails = 'newPODetails';
  public static newPODetailLine = 'newPODetailLine';
  public static selectedLocale = 'selectedLocale';
  public static selectedAutoScheduler = 'selectedAutoScheduler';
  public static userSites = 'userSites';
  public static selectedSiteId = 'selectedSiteId';
  public static userRoles = 'userRoles';

  public localeChangedSub = new Subject();

  constructor() { }

  setItem(key:string, value:string) : boolean {
    localStorage.setItem(key, value);
    return true;
  }

  getItem(key:string) : any {
    return localStorage.getItem(key);
  }

  removeItem(key:string) : boolean {
    localStorage.removeItem(key);
    return true;
  }

  localeChanged() {
    this.localeChangedSub.next(null);
  }
}
