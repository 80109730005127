import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { GluingPlan } from '../../../../core/models/gluing-plans.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GluingPlanModalNgComponent, IGluPlanModalInput } from '../gluing-plan-modal-ng/gluing-plan-modal-ng.component';
import { BeamLamminaDimension } from '../../../../core/models/beam-lammina-dimension.model';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DownloadFileHelper } from '@app/shared/helpers/download-file-helper';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { ProductionFileService } from '@app/core/services/http-services/gluelam/production-file.service';
import { GlusetStateHelper } from '@app/modules/gluelam/glue-plans-history/gluplan-state-helper';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('GluePlan.OverProductionDetected');
marker('GluePlan.alert_gluing_plan_save');
marker('GluePlan.GluePlanConfirmDelete');
marker('GluePlan.GluePlanDeleted');

@Component({
  selector: 'app-gluing-plans',
  templateUrl: './gluing-plans.component.html',
  styleUrls: ['./gluing-plans.component.css', './../../scss/glulam-theme.scss']
})
export class GluingPlansComponent implements OnInit {
  @Input() isLeftMajor: boolean;

  public _updatedTotalBeamCount: number;
  @Input() set updatedTotalBeamCount(updatedTotalBeamCount: number) {
    this._updatedTotalBeamCount = updatedTotalBeamCount;
    if (this.gluingPlans.find(x => x.gluingPlanID === this.selectedPlan.gluingPlanID) != null) {
      this.gluingPlans.find(x => x.gluingPlanID === this.selectedPlan.gluingPlanID).totalBeams = this._updatedTotalBeamCount;
    }
  };

  @Input() isNewGluesetsCreatedByOptimization = false;
  @Input() isOperator: boolean;
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onConfirmUnsavedGlueSet: EventEmitter<boolean> = new EventEmitter<boolean>();

  gluingPlans = <GluingPlan[]>[];
  selectedPlan = <GluingPlan>{};
  gluesetState = GlueSetState;
  blob: Blob;
  public dimensions = <BeamLamminaDimension[]>[];
  public gluePlanCount = 0;
  public removeGluePlanInProgress = false;
  itemToRemove: GluingPlan;
  rowIndex: number;
  selectedGluingPlanAfterGluesetChange: GluingPlan = null;

  constructor(private gluingPlanService: GluePlanService, public dialog: MatDialog, public dimensioService: DimensionService,
    public productionFileService: ProductionFileService, public translate: TranslateService,
    public bmgsService: GlueSetService,
    public spinner: NgxSpinnerService,
    private notificationService: AppNotificationService) {

  }

  ngOnInit() {
    this.getGluingPlans();
  }

  getGluingPlans() {
    this.spinner.show();

    this.gluingPlans = this.gluingPlans.length === 0 ? [] : this.gluingPlans;
    this.dimensioService.getBeamLamminaDimentions().subscribe(dim => {
      this.dimensions.push(...dim);

      const findIDs = this.isOperator ? [2, 7, 8, 9, 3, 4, 10] : [1, 2, 7, 8, 9, 10];
      if (this.gluingPlans.length === 0) {
        this.gluingPlanService.getGluingPlans(findIDs, true, false).subscribe(result => {
          this.loading.emit(false);
          if (result && result.data.length > 0) {
            this.gluingPlans.push(...result.data);
            this.gluingPlans.sort((a, b) => b.gluingPlanID - a.gluingPlanID);
            this.gluingPlans.forEach(x => {
              x.dimension = x.beamLaminaDimentionId != null ? this.dimensions.filter(y => y.beamLamminaDimensionID === x.beamLaminaDimentionId)[0] : null;
            });
            this.gluePlanCount = result.total;
          } else {
            // this.dimensioService.setSelectedLammina(90);
            this.gluePlanCount = 0;
          }

          this.spinner.hide();
        });
      }
      this.spinner.hide();
    });
  }

  public openConfirmationDialog(gluingPlan: GluingPlan, index: number) {
    this.removeGluePlanInProgress = true;
    this.itemToRemove = gluingPlan;
    this.rowIndex = index;
  }

  confirmRemove(isRemove: boolean) {
    if (isRemove) {
      this.delete(this.itemToRemove, this.rowIndex);
    }
    this.itemToRemove = null;
    this.rowIndex = 0;
  }

  delete(gluingPlan: GluingPlan, index: number) {
    this.loading.emit(true);
    this.gluingPlanService.deleteGluingPlan(gluingPlan.gluingPlanID).subscribe(() => {
      this.gluingPlans.splice(index, 1);
      this.selectedPlan = this.gluingPlans[0];
      this.gluingPlanService.setSelectedPlan(this.gluingPlans[0]);
      this.loading.emit(false);
      this.notificationService.notifySucsessAppChanel('GluePlan.GluePlanDeleted');
    });
  }

  onSelectionChange(gluingPlan: GluingPlan) {
    if (gluingPlan.gluingPlanID !== this.gluingPlanService._selectedPlan?.gluingPlanID) {
      if (this.bmgsService.isDirty || this.isNewGluesetsCreatedByOptimization) {
        this.selectedGluingPlanAfterGluesetChange = gluingPlan;
      } else {
        if (this.selectedPlan.gluingPlanID !== gluingPlan.gluingPlanID) {
          this.updateSelection(gluingPlan);
        }
      }
    }
  }

  confirmUnsavedGlueSet(result: boolean) {
    if (result) {
      this.bmgsService.isDirty = false;
      this.isNewGluesetsCreatedByOptimization = false;
      this.updateSelection(this.selectedGluingPlanAfterGluesetChange);
    }
    this.onConfirmUnsavedGlueSet.emit(result);
    this.selectedGluingPlanAfterGluesetChange = null;
  }

  updateSelection(gluingPlan: GluingPlan) {
    this.selectedPlan = gluingPlan;
    this.gluingPlanService.setSelectedPlan(gluingPlan);
  }

  editGluingPlan(plan: GluingPlan = null) {
    const isNew = !plan;
    if (isNew) {
      plan = <GluingPlan>{
        name: '',
        gluingPlanID: 0,
        glueSetStateId: 1,
        plannedExecutionStartDate: new Date(),
        plannedExecutionEndDate: new Date()
      };
    } else {
      if (this.selectedPlan.gluingPlanID !== plan.gluingPlanID) {
        this.updateSelection(plan);
      }
    }

    const ref = this.dialog.open(GluingPlanModalNgComponent, {
      width: '600px',
      data: <IGluPlanModalInput> {
        isNew,
        isStaticalPressGroup: null,
        isContinuousPressGroup: true,
        isStaticalScheduler: false,
        gluingPlan: { ...plan }
      }
    });

    ref.afterClosed().subscribe(changedPlan => {
      if (changedPlan) {
        if (isNew) {
          this.gluingPlans.unshift(changedPlan);
        } else {
          this.updateGluPlanList(changedPlan);
        }
        this.updateSelection(changedPlan);
      }
      this.loading.emit(false);
    });
  }

  updateGluPlanList(changedPlan: GluingPlan) {
    if (changedPlan.glueSetStateId === 3 || changedPlan.glueSetStateId === 4 || changedPlan.glueSetStateId === 5) {
      this.gluingPlans = this.gluingPlans.filter(gp => gp.gluingPlanID !== changedPlan.gluingPlanID);
    } else {
      // Replace where id is the same
      this.gluingPlans = this.gluingPlans.map(gp => gp.gluingPlanID === changedPlan.gluingPlanID ? changedPlan : gp);
    }
  }

  async download(gluingPlan: GluingPlan) {
    this.loading.emit(true);
    this.productionFileService.productionFileForGlueplan(gluingPlan.gluingPlanID).then(b => {
      DownloadFileHelper.downloadFile(b, `GluePlan-${gluingPlan.gluingPlanID}-${new Date(Date.now()).toISOString().split('T')[0]}`);
      this.loading.emit(false);
    });
  }

  public getStateColor(code: number) {
    switch (code) {
      case 1: return '#6c757d';
      case 2: return '#007bff';
      case 5: return '#28a745';
      case 6: return '#dc3545';
      case 7: return '#007bff';
      case 8: return '#007bff';
      case 9: return '#dc3545';
      case 10: return '#007bff';
    }
  }

  getStateText(code: number): string {
    return GlusetStateHelper.getStateText(code);
  }
}
