<h1
    mat-dialog-title
    translate>
    Operative.ProdOrderReportTitle
</h1>
<form
    [formGroup]="prodOrderReportForm"
    style="height: 98%;">
    <mat-dialog-content style="height: calc(100% - 80px);">
        <section class="search-section">
            <kendo-formfield>
                <kendo-label translate>Operative.EventDate</kendo-label>
                <kendo-datepicker
                    formControlName="eventDate" [value]="prodOrderReportForm.get('eventDate').value | siteDateFormat">
                  </kendo-datepicker>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label translate>App.Partial</kendo-label>
                <kendo-switch
                    formControlName="isPartial"
                    color="primary">
                </kendo-switch>
            </kendo-formfield>
            <button
                kendoButton
                themeColor="primary"
                (click)="calculateReportedQty()">
                {{"Operative.CalculateReported" | translate }}
            </button>
            <div class="pt-20">
              <kendo-formhint>
                <mat-icon class="icon" matTooltip="{{ 'Operative.CalculateReportedHint' | translate }}" matTooltipPosition="right">
                  info
                </mat-icon>
              </kendo-formhint>
            </div>
        </section>
        <section class="pt-20">
            <table>
                <thead>
                    <tr>
                        <th translate>Operative.Line</th>
                        <th translate>Operative.Main</th>
                        <th translate>Operative.PlannedQty</th>
                        <th translate>Operative.ReportedQty</th>
                        <th
                            style="width:10%"
                            translate>
                            Operative.NewQty
                        </th>
                        <th translate>Operative.Package</th>
                        <th
                            style="width:16%"
                            translate>
                            Operative.Material
                        </th>
                        <th translate>App.StockLocation</th>
                        <th translate>App.Message</th>
                        <th translate>App.Action</th>
                    </tr>
                </thead>
                <tbody formArrayName="prodOrderReports">
                    <tr
                        *ngFor="let fg of getProdOrderReportsForm().controls let i = index"
                        [formGroupName]="i">
                        <td class="dnone">
                            <kendo-label formControlName="prodOrderLineId">{{ fg.get('prodOrderLineId').value }}</kendo-label>
                        </td>
                        <td>{{ fg.get('lineNo').value }}</td>
                        <td>{{ fg.get('isMain').value ? ('App.Yes' | translate) : ''}}</td>
                        <td style="text-align: right;">
                            {{ fg.get('planedQty').value | number : '1.3'}}
                        </td>
                        <td style="text-align: right;">
                            <kendo-label formControlName="newQty">{{fg.get('newQty').value| number }}</kendo-label>
                        </td>
                        <td>
                            <kendo-textbox formControlName="reportedQty" [ngModel]="fg.get('reportedQty').value | ReplaceDotToCommaPipe">
                              <ng-template kendoTextBoxSuffixTemplate>
                                  <kendo-textbox-separator></kendo-textbox-separator>
                                  <kendo-label formControlName="reportedQtyUOM">{{fg.get('reportedQtyUOM').value }}</kendo-label>
                              </ng-template>
                          </kendo-textbox>
                        </td>


                        <td>
                            <app-select-package
                                formControlName="handelingUnitId"
                                [materialIndex]="fg.value.materialIndex"
                                [stockLocationId]="fg.value.stockLocationId"
                                [isProdOrderReport]="true"
                                (handelingUnit)="packagechange(fg,$event)"></app-select-package>
                        </td>


                        <td>
                            <app-select-entity
                                formControlName="materialIndex"
                                [entityTypeCode]="entityTypeCode"
                                (selectedEntity)="setSelectedMaterial($event)">
                            </app-select-entity>
                        </td>
                        <td>
                            <app-stock-location-selector
                                formControlName="stockLocationId"
                                [isEdge]="true"
                                (selectedStockLocationText)="setSelectedStockLocationText($event)">
                            </app-stock-location-selector>
                        </td>


                        <td>
                            <kendo-textarea
                                formControlName="message"
                                [cols]="30"
                                [rows]="1"></kendo-textarea>
                        </td>
                        <td class="btnwidth">
                            <div style="flex-grow:1"></div>
                            <button
                                kendoButton
                                themeColor="primary"
                                style="gap: 10px;"
                                (click)="createcopy(fg,i)">
                                {{"App.Add" | translate }}
                            </button>
                            <button
                                kendoButton
                                themeColor="warning"
                                (click)="removerow(i)">
                                {{"App.Remove" | translate }}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div style="flex-grow:1"></div>
        <div class="btn-holder">
            <button
                kendoButton
                type="button"
                class="closebtn"
                (click)="close()">
                {{"App.Close" | translate }}
            </button>
            <button
                kendoButton
                themeColor="primary"
                type="submit"
                (click)="save()"
                class="float-right"
                [disabled]="!prodOrderReportForm.valid">
                {{"App.Submit" | translate}}
            </button>
        </div>
    </mat-dialog-actions>



</form>
