<h3 mat-dialog-title class="primaryColor section-header-operative">
  {{selectedMachine.name}} - {{ 'Machine.RamConfigurationDialogHeader' | translate}}
</h3>
<mat-dialog-content class="popupContent">
<div style="margin-top: 10px;margin-bottom: 10px;">
  <button id="add-new-ram-conf" mat-raised-button type="button" color="primary" (click)="add()">
    {{ 'App.AddNew' | translate }}
  </button>
</div>
<div style="margin-bottom: 10px;">
  <!-- Ram Configuration Grid-->
  <div class="grid-wrapper">
    <kendo-grid
        #ramConfigurationEditorGrid
        [columnMenu]="false"
        [data]="ramConfigurationsGridData"
        [groupable]="false"
        [resizable]="true"
        [sortable]="true"
        (edit)="editHandler($event)"
        (cancel)="cancelHandler($event)"
        (save)="saveHandler($event)"
        (remove)="removeHandler($event)"
        [size]="'small'">
      <kendo-grid-column
        title="{{ 'EntityTranslation.Index' | translate }}"
        field="index"
        [editable]="false"
        class="align-right">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.index }}
        </ng-template>
      </kendo-grid-column>
		  <kendo-grid-column
        title="{{ 'App.Position' | translate }}"
        field="position"
        class="align-right">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.position }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <kendo-numerictextbox
            id="position"
            format="n"
            [decimals]="0"
            [formControl]="formGroup.get('position')"
            [min]="0">
          </kendo-numerictextbox>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="{{ 'Machine.Ram' | translate }}"
        field="ram"
        class="align-right">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.width }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <kendo-numerictextbox
            id="width"
            format="n"
            [decimals]="0"
            [formControl]="formGroup.get('width')"
            [min]="1">
          </kendo-numerictextbox>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
          <button kendoGridEditCommand id="edit-ram" [primary]="true">
            {{ 'App.Edit' | translate }}
          </button>
          <button kendoGridSaveCommand id="add-ram" [primary]="true">
            {{ isNew ? ('App.Add' | translate) : ('App.Update' | translate) }}
          </button>
          <button kendoGridRemoveCommand id="remove-ram" themeColor="warning" translate>
             {{ 'App.Remove' }}
          </button>
          <button kendoGridCancelCommand id="cancel-ram">
            {{ isNew ? ('App.Discard' | translate) : ('App.Cancel' | translate) }}
          </button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
    <div class="k-i-loading" *ngIf="loading"></div>
  </div>
</div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button id="close-ram" type="button" (click)="close()">
    {{ 'App.Close' | translate }}
  </button>
  <div style="flex-grow:1"></div>
  <button mat-raised-button id="save-ram" type="button" color="primary" (click)="save()" [disabled]="disableSaveBtn">
    {{ 'App.Save' | translate }}
  </button>
</mat-dialog-actions>
