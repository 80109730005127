import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { State } from '@progress/kendo-data-query';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IMachineGroupDTO, MachineGroupService } from '@app/core/services/http-services/operative/machine-group.service';
import { MachineService } from '@app/core/services/http-services/operative/machine.service';
import { IMachineDTO, IMachineSearchCriteriaDTO } from '@app/core/models/machineDTO';
import { RamConfigurationService } from '@app/core/services/http-services/operative/ram-configuration.service';

marker('Machine.MachineDeleteMsg');
marker('Machine.MachineNameAlreadyExistsMsg');
marker('Machine.SaveMachineMsg');
marker('Machine.UpdateMachineMsg');

const createMachineForm = (p:IMachineDTO) => new UntypedFormGroup({
  machineId: new UntypedFormControl(p.machineId, [Validators.required]),
  machineGroupId: new UntypedFormControl(p.machineGroupId, [Validators.required]),
  name: new UntypedFormControl(p.name, [Validators.required]),
  colorCode: new UntypedFormControl(p.colorCode, [Validators.required]),
  isPlannedWidthEditable: new UntypedFormControl(p.isPlannedWidthEditable),
  isPlannedThicknessEditable: new UntypedFormControl(p.isPlannedThicknessEditable),
  isPlannedLengthEditable: new UntypedFormControl(p.isPlannedLengthEditable),
  isPlannedQualityEditable: new UntypedFormControl(p.isPlannedQualityEditable),
  isSpeciesEditable: new UntypedFormControl(p.isSpeciesEditable),
  minPressLength: new UntypedFormControl(p.minPressLength),
  maxPressLength: new UntypedFormControl(p.maxPressLength),
  minDesiredPressLength: new UntypedFormControl(p.minDesiredPressLength),
  maxDesiredPressLength: new UntypedFormControl(p.maxDesiredPressLength),
  defaultCutOffLength: new UntypedFormControl(p.defaultCutOffLength),
  cutWidth: new UntypedFormControl(p.cutWidth),
  testPieceLength: new UntypedFormControl(p.testPieceLength),
  minPressHight: new UntypedFormControl(p.minPressHight),
  maxPressHight: new UntypedFormControl(p.maxPressHight),
  minDesiredPressHight: new UntypedFormControl(p.minDesiredPressHight),
  maxDesiredPressHight: new UntypedFormControl(p.maxDesiredPressHight),
  minPressWidth: new UntypedFormControl(p.minPressWidth),
  maxPressWidth: new UntypedFormControl(p.maxPressWidth),
  desiredNumberOfLayers: new UntypedFormControl(p.desiredNumberOfLayers)
});

@Component({
  selector: 'app-machine',
  templateUrl: './machine.component.html',
  styleUrls: ['./machine.component.css']
})
export class MachineComponent implements OnInit {
  state: State = {
    skip: 0,
    take: 30,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public machines: IMachineDTO[] = [];
  public machinesGroups: IMachineGroupDTO[] = [];
  public isNew = false;

  machineSearchCriteriaDTO: IMachineSearchCriteriaDTO = { machines: [] };
  gridMachineData : GridDataResult;
  public formGroup: UntypedFormGroup;
  private editedRowIndex: number;
  isEdit = false;

  @ViewChild('machineEditorGrid')
  private machineEditorGrid: GridComponent;

  constructor(public machineService: MachineService,
    public machineGroupService: MachineGroupService,
    private notificationService: AppNotificationService,
    private ramConfigurationService: RamConfigurationService) { }

  public ngOnInit(): void {
    this.loadData();
  }

  get() {
    this.machineService.get(this.state, this.machineSearchCriteriaDTO)
      .subscribe(d => {
        this.gridMachineData = d;
      });
  }

  loadData() {
    this.machineGroupService.query().subscribe(d => {
      this.machinesGroups = d.data;
    });

    this.machineService.getmachines().subscribe(d => {
      this.machines = d.data;
    });
  }

  public getMgName(ids:string):string {
    const id = Number.parseInt(ids);

    if (this.machinesGroups.some(d => d.machineGroupId === id)) {
      return this.machinesGroups.find(d => d.machineGroupId === id).name;
    } else {
      return 'n/a';
    }
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.formGroup = createMachineForm(dataItem);
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }): void {
    if (this.validate(dataItem, formGroup)) {
      const machineToPerst:IMachineDTO = formGroup.value;
      if (isNew) {
        this.machineService.insert(machineToPerst).subscribe(m => {
          this.machines.push(m);
          this.machines = [...this.machines];
          sender.closeRow(rowIndex);
          this.notificationService.notifySucsessAppChanel('Machine.SaveMachineMsg');
        });
      } else {
        this.machineService.update(machineToPerst).subscribe(() => {
          dataItem.machineId = machineToPerst.machineId;
          dataItem.name = machineToPerst.name;
          dataItem.colorCode = machineToPerst.colorCode;
          dataItem.machineGroupId = machineToPerst.machineGroupId;
          dataItem.isPlannedWidthEditable = machineToPerst.isPlannedWidthEditable;
          dataItem.isPlannedThicknessEditable = machineToPerst.isPlannedThicknessEditable;
          dataItem.isPlannedLengthEditable = machineToPerst.isPlannedLengthEditable;
          dataItem.isPlannedQualityEditable = machineToPerst.isPlannedQualityEditable;
          dataItem.isSpeciesEditable = machineToPerst.isSpeciesEditable;
          dataItem.minPressLength = machineToPerst.minPressLength;
          dataItem.maxPressLength = machineToPerst.maxPressLength;
          dataItem.minDesiredPressLength = machineToPerst.minDesiredPressLength;
          dataItem.maxDesiredPressLength = machineToPerst.maxDesiredPressLength;
          dataItem.defaultCutOffLength = machineToPerst.defaultCutOffLength;
          dataItem.cutWidth = machineToPerst.cutWidth;
          dataItem.testPieceLength = machineToPerst.testPieceLength;
          dataItem.minPressHight = machineToPerst.minPressHight;
          dataItem.maxPressHight = machineToPerst.maxPressHight;
          dataItem.minDesiredPressHight = machineToPerst.minDesiredPressHight;
          dataItem.maxDesiredPressHight = machineToPerst.maxDesiredPressHight;
          dataItem.minPressWidth = machineToPerst.minPressWidth;
          dataItem.maxPressWidth = machineToPerst.maxPressWidth;
          dataItem.desiredNumberOfLayers = machineToPerst.desiredNumberOfLayers;
          sender.closeRow(rowIndex);
          this.notificationService.notifySucsessAppChanel('Machine.UpdateMachineMsg');
        });
      }
    }
  }

  public addRamConfig(selectedMachine: IMachineDTO): void {
    this.ramConfigurationService.openDialog(selectedMachine).afterClosed().subscribe((isReload) => {
      if (isReload) {
        this.loadData();
      }
    });
  }

  private validate(dataItem, formGroup): boolean {
    if (this.machines.some(item => item.machineId !== dataItem.machineId && item.name === formGroup.get('name').value)) {
      this.notificationService.notifyErrorAppChanel('Machine.MachineNameAlreadyExistsMsg');
      return false;
    }
    return true;
  }

  public removeHandler({ dataItem, isNew, rowIndex }) {
    this.machineService.remove(dataItem).subscribe(() => {
      const temp = this.machines;
      temp.splice(rowIndex, 1);
      this.machines = [...temp];// can use .map
      this.notificationService.notifySucsessAppChanel('Machine.MachineDeleteMsg');
    });
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  addMachineRow() {
    this.isEdit = false;
    this.isNew = true;
    this.closeEditor(this.machineEditorGrid);
    this.formGroup = createMachineForm(<IMachineDTO> {
      machineId: 0,
      name: '',
      isPlannedWidthEditable: false,
      isPlannedThicknessEditable: false,
      isPlannedLengthEditable: false,
      isPlannedQualityEditable: false,
      isSpeciesEditable: false
    });
    this.machineEditorGrid.addRow(this.formGroup);
  }

  public togglePlannedWidthCheckbox() {
    this.formGroup.patchValue({ isPlannedWidthEditable: !this.formGroup.get('isPlannedWidthEditable').value });
  }

  public togglePlannedThicknessCheckbox() {
    this.formGroup.patchValue({ isPlannedThicknessEditable: !this.formGroup.get('isPlannedThicknessEditable').value });
  }

  public togglePlannedLengthCheckbox() {
    this.formGroup.patchValue({ isPlannedLengthEditable: !this.formGroup.get('isPlannedLengthEditable').value });
  }

  public togglePlannedQualityCheckbox() {
    this.formGroup.patchValue({ isPlannedQualityEditable: !this.formGroup.get('isPlannedQualityEditable').value });
  }

  public toggleSpeciesCheckbox() {
    this.formGroup.patchValue({ isSpeciesEditable: !this.formGroup.get('isSpeciesEditable').value });
  }
}
