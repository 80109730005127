<div class="col statical-plan-view">
  <mat-card class="p-0 glue-plan-mat-card" (click)="updateSelection()" (contextmenu)="updateSelection()">
    <ngx-spinner
        bdColor="rgb(255 255 255 / 59%)"
        size="medium" color="rgb(254 112 33)"
        type="ball-scale-multiple"
        [fullScreen]="false"
        [name]="gluelamSpinner">
      <p> {{ "GluePlan.Loading" | translate}}</p>
    </ngx-spinner>
    <ngx-spinner
      bdColor="rgb(255 255 255 / 59%)"
      size="medium" color="rgb(254 112 33)"
      type="ball-clip-rotate-multiple"
      [fullScreen]="false"
      [name]="glueSetSpinner">
      <p>{{ "GluePlan.Loading" | translate}} </p>
    </ngx-spinner>
    <mat-card-content>
      <div class="row glue-plan-mat-card-headder">
        <div class="col" style="flex-grow: 1;">
          <div class="glueplan-details">
            <h3 class="mb-5 fw-bold">{{ plan.name }} - {{plan.gluingPlanID}}</h3>
            <p class="mb-5">{{ plan.plannedExecutionStartDate | date}} - {{ plan.plannedExecutionEndDate | date}}</p>
          </div>
          <div class="glue-plan-card-ations">
            <button class="m-5" mat-mini-fab color="primary" (click)="edit()">
              <mat-icon>edit</mat-icon>
            </button>
            <button class="m-5" mat-mini-fab color="primary"
              [disabled]="!plan || plan?.glueSetStateId >= glueSetStateEnum.PLANNED || disableSaveGluesetsButton()"
              (click)="saveGlueSetsToGluingPlan()">
              <mat-icon>save</mat-icon>
            </button>
            <button class="m-5" mat-mini-fab color="warn" (click)="delete()">
              <mat-icon>delete_outline</mat-icon>
            </button>
            <button class="m-5" mat-mini-fab color="primary"
              [routerLink]="['/glulam/glueplan-print-third', plan.gluingPlanID]">
              <mat-icon>print icon</mat-icon>
            </button>
          </div>
        </div>
        <app-gluing-plan-layered-view class="app-gluing-plan-layered-view" [glueSets]="glueSets"
          [machine]="machine"></app-gluing-plan-layered-view>
      </div>
      <app-beam-material-gluset-statical class="glueset" [id]="bmgs.beamMaterialGlueSetID"
        *ngFor="let bmgs of glueSets; let i = index" [plan]="plan" [bmgs]="bmgs" [gsWidth]="gsWidth"
        [lamminaDim]="dimension" [isOperator]="false" [machine]="machine" (calculateUnits)="calculateUnits()"
        (deleteBeamMaterialGlueSet)="deleteGluesetsFromGluingPlan($event, i)" (updateGluePlan)="updateGluePlan()"
        (addEmptyBmgs)="AddEmptyBmgs()">
      </app-beam-material-gluset-statical>
    </mat-card-content>
  </mat-card>
</div>
<app-confirmation-dialog *ngIf="isRemoveGluePlan" [msg]="'GluePlan.GluePlanConfirmDelete'"
  (conformationDialogCloseEvent)="confirmRemove($event)">
</app-confirmation-dialog>
