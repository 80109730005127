<ngx-spinner
    name="scheduleLoader">
</ngx-spinner>
<h1
    mat-dialog-title
    translate>
    Operative.ScheduleTitle
</h1>
<mat-dialog-content
      class="popupContent">
    <form
        [formGroup]="scheduleForm">
        <section>
          <kendo-formfield>
              <kendo-floatinglabel
                  text="{{ 'App.Code'  | translate }}">
                  <kendo-textbox
                      formControlName="code"
                      [style.width.px]="350">
                  </kendo-textbox>
              </kendo-floatinglabel>
          </kendo-formfield>
          <kendo-formfield>
              <kendo-floatinglabel
                  text="{{ 'Operative.scheduleName'  | translate }}">
                  <kendo-textbox
                      formControlName="name">
                  </kendo-textbox>
              </kendo-floatinglabel>
          </kendo-formfield>
          <div style="flex-grow: 1;">
          </div>
        </section>
    </form>
    <section>
        <button
            mat-raised-button
            color="primary"
            (click)="addScheduleLineRow()">
            {{ 'App.AddNew' | translate }}
        </button>
    </section>
    <div>
        <kendo-grid
            #scheduleEditorGrid
            [kendoGridBinding]="scheduleLinesGrid"
            [sortable]="true"
            [filterable]="false"
            (edit)="editHandler($event)"
            (cancel)="cancelHandler($event)"
            (save)="saveHandler($event)"
            (remove)="removeHandler($event)"
            [resizable]="false"
            [height]="400"
            [scrollable]="scrollMode"
            [size]="'small'">

            <kendo-grid-column
                title="{{ 'Operative.Line' | translate }}"
                [width]="15"
                [editable]="false">
                <ng-template
                    kendoGridCellTemplate
                    let-rowIndex="rowIndex"
                    let-isNew="isNew">
                    {{ isNew ? (this.scheduleLinesGrid?.length + 1) : (rowIndex + 1) }}
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
                field="dayOfWeekText"
                title="{{ 'Operative.scheduleDay' | translate }}"
                [width]="30">
                <ng-template
                    kendoGridEditTemplate
                    let-formGroup="formGroup">
                    <kendo-dropdownlist
                        [data]="days"
                        textField="key"
                        valueField="value"
                        [valuePrimitive]="true"
                        [formControl]="formGroup.get('dayOfWeek')"
                        (selectionChange)="onDayChange($event)">
                    </kendo-dropdownlist>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
                field="start"
                title="{{ 'Operative.scheduleStart' | translate }}"
                [width]="30">
                <ng-template
                    kendoGridCellTemplate
                    let-dataItem>
                    {{ dataItem.start }}
                </ng-template>
                <ng-template
                    kendoGridEditTemplate
                    let-formGroup="formGroup">
                    <kendo-timepicker
                        format="HH:mm"
                        [formControl]="formGroup.get('start')"
                        (valueChange)="onTimeChange()">
                    </kendo-timepicker>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
                field="end"
                title="{{ 'Operative.scheduleEnd' | translate }}"
                [width]="30">
                <ng-template
                    kendoGridCellTemplate
                    let-dataItem>
                    {{ dataItem.end }}
                </ng-template>
                <ng-template
                    kendoGridEditTemplate
                    let-formGroup="formGroup">
                    <kendo-timepicker
                        format="HH:mm"
                        [formControl]="formGroup.get('end')"
                        (valueChange)="onTimeChange()">
                    </kendo-timepicker>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
                field="duration"
                title="{{ 'Operative.scheduleDuration' | translate }}"
                [width]="30">
                <ng-template
                    kendoGridEditTemplate
                    let-formGroup="formGroup">
                    <kendo-label class="textCenter">{{ formGroup.get('duration').value }}</kendo-label>
                </ng-template>
            </kendo-grid-column>

            <kendo-grid-command-column
                title="{{ 'Operative.Command' | translate }}"
                [width]="50">
                <ng-template
                    kendoGridCellTemplate
                    let-dataItem
                    let-isNew="isNew">
                    <button
                        mat-raised-button
                        kendoGridEditCommand
                        [primary]="true">
                        {{ 'App.Edit' | translate }}
                    </button>
                    <button kendoGridRemoveCommand>{{ 'App.Remove' | translate }}</button>
                    <button
                        mat-raised-button
                        kendoGridSaveCommand
                        [primary]="true"
                        [disabled]="scheduleLineForm?.invalid">
                        {{ isNew ?  ('App.Add' | translate) : ('App.Update' | translate) }}
                    </button>
                    <button
                        mat-raised-button
                        kendoGridCancelCommand>
                        {{ isNew ? ('App.Discard' | translate) : ('App.Cancel' | translate) }}
                    </button>
                </ng-template>
            </kendo-grid-command-column>

          </kendo-grid>

          <app-confirmation-dialog
              *ngIf="itemToRemoveLineNo"
              (conformationDialogCloseEvent)="confirmRemove($event)">
          </app-confirmation-dialog>

      </div>
  </mat-dialog-content>

<mat-dialog-actions>
    <button
        mat-raised-button
        type="button"
        (click)="onClose()">
        {{"App.Close" | translate }}
    </button>
    <div style="flex-grow: 1;"></div>
    <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="!scheduleForm.valid"
        (click)="onSubmit()">
        {{ data.scheduleId > 0 ? ("App.Update" | translate) : ("App.Create" | translate)}}
    </button>
</mat-dialog-actions>
