/* eslint-disable no-unused-vars */
import { CardItem } from '../models/CardItem.model';
import { Injectable } from '@angular/core';
import { DiagramComponent } from '../diagram/diagram.component';
import { Observable, of } from 'rxjs';

import { SimpleMetricComponent } from '../simple-metric/simple-metric.component';
import { PieDiagramComponent } from '../pie-diagram/pie-diagram.component';
import { NotificationDisplayComponent } from '../notification/notification.component';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';

export enum DashbordDefs {
  Generic = 0,
  Operational = 1,
  Tacktical = 2,
  UserDefined = 3
}

export class DashbordDef {
  public type: DashbordDefs;
  public titel: string;
  public cards: CardItem[];
}

@Injectable()
export class CardItemService {
  getCardItems(type: DashbordDefs): Observable<DashbordDef> {
    switch (type) {
      case DashbordDefs.Generic:
        return of(this.GetGeneralDashbord());
      case DashbordDefs.Operational:
        return of(this.GetOperationalDashbord());
      case DashbordDefs.Tacktical:
        return of(this.GetTackticalDashbord());
      default:
        throw new Error('No definition found!');
    }
  }

  GetGeneralDashbord(): DashbordDef {
    const c = new CardItem(SimpleMetricComponent, 'Metric', 1, 1, {});

    c.backgroundColor = 'lightblue';
    c.showTitle = false;
    const def: DashbordDef = {

      titel: 'Main Dashbord',
      type: DashbordDefs.Generic,
      cards: [
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Produced Today', value: 'n/a', uom: 'M3' }, '#43a047'),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Total Cost Contribution', value: 'n/a', uom: 'SEK' }, '#3f51b5'),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Production Pace', value: 'n/a', uom: 'M3/DAY' }, '#2196f3'),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Avg Stop time', value: 'n/a', uom: 'H/DAY' }, '#ffc107'),
        new CardItem(DiagramComponent, 'Diagram', 4, 3, {}),
        new CardItem(PieDiagramComponent, 'Diagram', 2, 2, [
          { category: 'Eaten', value: 0.42 },
          { category: 'Not eaten', value: 0.58 }
        ]),
        new CardItem(PieDiagramComponent, 'Red or Blue Pill', 2, 2, [
          { category: 'Red pill ', value: 0.30 },
          { category: 'Blue Pill', value: 0.40 },
          { category: 'Orange Pill', value: 0.30 }
        ])
      ]
    };

    return def;
  }

  GetOperationalDashbord(): DashbordDef {
    const def: DashbordDef = {
      titel: 'Operational Dashbord',
      type: DashbordDefs.Generic,
      cards: [
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Total Cost Contribution', value: 5000000, uom: 'SEK' }, '#3f51b5'),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Production Pace', value: 500.55, uom: 'M3/DAY' }, '#2196f3'),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Avg Stop time', value: 20, uom: 'H/DAY' }, '#ffc107'),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Produced Today', value: 10000.20, uom: 'M3' }, '#43a047'),
        new CardItem(SimpleMetricComponent, 'Metric', 2, 2, { name: 'Produced Today', value: 10000.20, uom: 'M3' }, '#43a047'),
        new CardItem(NotificationDisplayComponent, 'Model', 2, 2,
          AppNotificationService.APP_CHANEL
        ),
        new CardItem(SimpleMetricComponent, 'Metric', 2, 2, { name: 'Produced Today', value: 10000.20, uom: 'M3' }, '#43a047'),
        new CardItem(NotificationDisplayComponent, 'Production', 2, 2, AppNotificationService.APP_OPERATIONAL)
      ]
    };

    return def;
  }

  GetTackticalDashbord(): DashbordDef {
    const def: DashbordDef = {
      titel: 'Tactical Dashbord',
      type: DashbordDefs.Generic,
      cards: [
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Total Cost Contribution', value: 5000000, uom: 'SEK' }, '#3f51b5'),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Production Pace', value: 500.55, uom: 'M3/DAY' }, '#2196f3'),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Produced Today', value: 10000.20, uom: 'M3' }, '#43a047'),
        new CardItem(SimpleMetricComponent, 'Metric', 1, 1, { name: 'Avg Stop time', value: 20, uom: 'H/DAY' }, '#ffc107'),
        new CardItem(DiagramComponent, 'Diagram', 4, 3, {}),
        new CardItem(PieDiagramComponent, 'Diagram', 2, 2, [
          { category: 'Eaten', value: 0.42 },
          { category: 'Not eaten', value: 0.58 }
        ]),
        new CardItem(PieDiagramComponent, 'Red or Blue Pill', 2, 2, [
          { category: 'Red pill ', value: 0.30 },
          { category: 'Blue Pill', value: 0.40 },
          { category: 'Orange Pill', value: 0.30 }
        ])
      ]
    };

    return def;
  }
}
