<div class="section-header">
  <button
    mat-icon-button
    *ngIf="!isOperator"
    (click)="editGluingPlan(null)">
    <mat-icon>add_circle_outline</mat-icon>
  </button>
  <h3 translate>GluePlan.GluePlans</h3>
</div>
<div class="gp-card-container">
  <mat-card
    *ngFor="let gluingPlan of gluingPlans; let i = index"
    [ngClass]="{'selected-glue-plan': gluingPlan.gluingPlanID === selectedPlan?.gluingPlanID }"
    (click)="onSelectionChange(gluingPlan)">
    <mat-card-title>
      {{ gluingPlan.gluingPlanID }} - {{ gluingPlan.name }}
    </mat-card-title>
    <mat-card-content style="display: flex; flex-direction: column;">
      <div style="display: flex; flex-direction: row;">
        <div
          [style.color]="getStateColor(gluingPlan.glueSetStateId)" >
          {{getStateText(gluingPlan.glueSetStateId) | translate | uppercase }}
        </div>
        <div style="flex-grow: 1;"></div>
        <span [title]="'GluePlan.QuantityTitle' | translate:{beamsProduced:gluingPlan.beamsProduced,beamsRejected:gluingPlan.beamsRejected,totalBeams:gluingPlan.totalBeams}">
          <span [style.color]="getStateColor(5)">{{gluingPlan.beamsProduced}}</span>
          <span
            *ngIf="gluingPlan.beamsRejected && gluingPlan.beamsRejected > 0"
            [style.color]="getStateColor(6)">+{{gluingPlan.beamsRejected}}</span>/{{ gluingPlan.totalBeams }}</span>
      </div>
      <div style="display: flex; flex-direction: row;">
        <div>{{ gluingPlan.dimension?.width }}</div>
        <div style="flex-grow: 1;"></div>
        <div *ngIf="gluingPlan.prodOrderID">{{ gluingPlan.prodOrderID }}</div>
      </div>
      <div style="display: flex; flex-direction: row;">
        <div>
          {{ gluingPlan.plannedExecutionStartDate | siteDateFormat}}
        </div>
        <div style="flex-grow: 1;"></div>
        <mat-icon
          *ngIf="gluingPlan.instruction"
          [matTooltip]="gluingPlan.instruction">
          assignment
        </mat-icon>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-mini-fab
        mat-flat-button
        color="primary"
        *ngIf="gluingPlan.glueSetStateId!=1"
        (click)="download(gluingPlan)">
        <mat-icon>cloud_download</mat-icon>
      </button>
      <button
        mat-mini-fab
        mat-flat-button
        color="primary"
        *ngIf="!isOperator"
        (click)="editGluingPlan(gluingPlan)">
        <mat-icon>create</mat-icon>
      </button>
      <button
        mat-mini-fab
        mat-flat-button
        color="warn"
        (click)="openConfirmationDialog(gluingPlan, i)"
        *ngIf="!isOperator && gluingPlan.glueSetStateId==1">
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-mini-fab
        mat-flat-button
        color="warn"
        [matMenuTriggerFor]="menu">
        <mat-icon>print</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          [routerLink]="['/glulam/plan-print-first', gluingPlan.gluingPlanID]">
          <mat-icon>print</mat-icon>
          <span translate>GluePlanPrint.FloorOnePrint</span>
        </button>
        <button
          mat-menu-item
          [routerLink]="['/glulam/plan-print-second', gluingPlan.gluingPlanID]">
          <mat-icon>print</mat-icon>
          <span translate>GluePlanPrint.FloorTwoPrint</span>
        </button>
      </mat-menu>
    </mat-card-actions>
  </mat-card>
</div>
<app-confirmation-dialog *ngIf="itemToRemove"
                         [msg] = "'GluePlan.GluePlanConfirmDelete'"
                         (conformationDialogCloseEvent) = "confirmRemove($event)">
</app-confirmation-dialog>


<app-confirmation-dialog *ngIf="selectedGluingPlanAfterGluesetChange"
                         [msg] = "'GluePlan.alert_gluing_plan_save'"
                         (conformationDialogCloseEvent) = "confirmUnsavedGlueSet($event)">
</app-confirmation-dialog>
