
<div style="border-radius:2px; padding:5px;">
  <div
    style="padding:5px;"
    *ngIf="bml">
    {{ bml.getLongText() }}
  </div>
  <div *ngIf="bml == null && bm != null">
    <div
      style="padding:5px;"
      *ngFor="let bml of bm.beamMaterialLines; let i = index">
      #{{ i }} - {{ bml.getLongText() }}
    </div>
  </div>
  <div style="margin-bottom: 5px;">
    <div style="width: 45px;display: inline-block;">BM</div>
    <button
      *ngIf="isStaticalGluePlan"
      style="margin-right: 5px"
      mat-raised-button
      color="primary"
      (click)="onManualBeamMaterialAdd()"
      [disabled]="!canManualBeamMaterialAdd()">
      <mat-icon>add</mat-icon>
    </button>
    <button
      style="margin-right: 5px"
      mat-raised-button
      color="primary"
      (click)="onUpp()"
      [disabled]="!bm || showTestPscSection">
      <mat-icon>keyboard_arrow_upp</mat-icon>
    </button>
    <button
      style="margin-right: 5px"
      mat-raised-button
      color="primary"
      (click)="onDown()"
      [disabled]="!bm || showTestPscSection">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <button
      style="margin-right: 5px"
      mat-raised-button
      color="primary"
      (click)="onCutBM()"
      [disabled]="!bm || showTestPscSection">
      <mat-icon>content_cut</mat-icon>
    </button>
  <button
  style="margin-right: 5px"
  mat-raised-button
  color="primary"
  (click)="showTestPscForm()"
  [disabled]="showTestPscSection"
  *ngIf="bm && !bm.BeamMaterialType.IsManual && !bm.takeTestPsc">
  + tp
</button>
  <button
  style="margin-right: 5px"
  mat-raised-button
  color="primary"
  (click)="onRemoveTestPsc()"
  *ngIf="bm && bm.takeTestPsc">
  - tp
  </button>
    <button
      style="margin-right: 5px"
      mat-raised-button
      color="warn"
      (click)="onRemoveMaterial()"
      [disabled]="!bm || showTestPscSection">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
  <div >
    <div style="width: 45px; display: inline-block;">BML</div>
    <button
      title="alt a"
      accesskey="a"
      style="margin-right: 5px"
      mat-raised-button
      color="primary"
      (click)="onAdd()"
      [disabled]="!canAdd() || showTestPscSection">
      <mat-icon>add</mat-icon>
    </button>
    <button
      style="margin-right: 5px"
      mat-raised-button
      color="primary"
      (click)="onCut()"
      [disabled]="!bml || bm?.BeamMaterialType.IsManual || showTestPscSection">
      <mat-icon>content_cut </mat-icon>
    </button>
    <button
      style="margin-right: 5px"
      mat-raised-button
      color="primary"
      (click)="onPaste()"
      [disabled]="!hasPaste || showTestPscSection">
      <mat-icon>content_paste</mat-icon>
    </button>
    <button title="alt r"  accesskey="r"
      style="margin-right: 5px"
      mat-raised-button
      color="warn"
      (click)="onRemoveMaterialLine()"
      [disabled]="!bml || showTestPscSection">
      <mat-icon>remove</mat-icon>
    </button>
  </div>
  <div *ngIf="showTestPscSection">
    <form class="form" [formGroup]="testPieceForm" (ngSubmit)="onAddTestPsc()">
     <input type="text" formControlName="testPscValue" [ngModel]="testPieceForm.get('testPscValue').value | ReplaceDotToCommaPipe"
        style="margin-right: 5px; position: relative; left:40px; top:33px;" />
     <div class="save">
     <button style="margin-right: 5px; position: relative; left:236px; top:4px;" mat-raised-button
            type="submit"  [disabled]="testPieceForm?.invalid">
            {{"App.Save" | translate }} </button>
    </div>
    <div class="cancel">
      <button style="margin-right: 5px; position: relative; left:313px; bottom:32px;" mat-raised-button
              (click)="onCancel()" color="warn" type="cancel">
        {{ 'App.Cancel' | translate}}
      </button>
     </div>
    </form>
  </div>
</div>
