<ngx-spinner name="createProdOrderdetailsLoader"></ngx-spinner>
<h1>
  {{ 'Operative.ProdOrder' | translate }}
</h1>
<form [formGroup]="prodOrderForm">
  <section class="row" *ngIf="prodOrder">
    <kendo-formfield appearance="fill">
      <kendo-label text="{{ 'App.Name' | translate }}"></kendo-label>
      <kendo-textbox formControlName="name"></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield appearance="fill">
      <kendo-label text="{{ 'Operative.ExternalProdOrderId' | translate }}"></kendo-label>
      <kendo-textbox formControlName="externalProdOrderId"></kendo-textbox>
    </kendo-formfield>
    <app-machine-selector-dropdown [machineGroupId]="prodOrder?.machine?.machineGroupId" formControlName="machineId"> </app-machine-selector-dropdown>
    <app-select-prod-order-state formControlName="stateId" [prodOrderId]="prodOrder.prodOrderId"
      [isNewProdOrder]="isNew"></app-select-prod-order-state>
    <kendo-formfield appearance="fill">
      <kendo-label text="{{ 'Operative.Duration' | translate }}"></kendo-label>
      <kendo-textbox formControlName="duration">
        <ng-template kendoTextBoxSuffixTemplate>
          <kendo-textbox-separator></kendo-textbox-separator>
          <kendo-icon name="reload" (click)="calculateDuration()"></kendo-icon>
        </ng-template>
      </kendo-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label text="{{ 'Operative.ActualDuration' | translate }}"></kendo-label>
      <kendo-textbox formControlName="actualDuration"></kendo-textbox>
    </kendo-formfield>
    <div style="flex-grow: 1"></div>
    <app-sequence-display *ngIf="!prodOrder.glueSetID" [sequenceId]="prodOrder.sequenceId"></app-sequence-display>
    <app-glueset-sequence-display *ngIf="prodOrder.glueSetID" [gluesetID]="prodOrder.glueSetID"></app-glueset-sequence-display>
    <app-file-upload [disabled]="!prodOrder.firstActivityGuid" [container]="'activity-attachments'"
      [folder]="prodOrder.firstActivityGuid" [canEdit]="false"
      [downloadMode]="fileDownloadMode.SingleFileUsingDropdown"></app-file-upload>
    <div>
      <button kendoButton #anchor (click)="showDemandInfo(prodOrder.demandGUID)" [disabled]="isDemandBindToPO">
        {{ 'Operative.ShowDemandInfo' | translate }}
      </button>
    </div>
  </section>
  <section class="row" *ngIf="prodOrder">
    <kendo-formfield>
      <kendo-label text="{{ 'Operative.PlannedStartDate' | translate }}"></kendo-label>
      <kendo-datetimepicker formControlName="startDateTime"></kendo-datetimepicker>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label text="{{ 'Operative.ActualStartDate' | translate }}"></kendo-label>
      <kendo-datetimepicker formControlName="actualStartDateTime"></kendo-datetimepicker>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label text="{{ 'Operative.PlannedEndDate' | translate }}"></kendo-label>
      <kendo-datetimepicker formControlName="endDateTime"></kendo-datetimepicker>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label text="{{ 'Operative.ActualEndDate' | translate }}"></kendo-label>
      <kendo-datetimepicker formControlName="actualEndDateTime"></kendo-datetimepicker>
    </kendo-formfield>
  </section>
  <section class="row" *ngIf="prodOrder">
    <kendo-formfield appearance="fill" class="inst-box">
      <kendo-label text="{{ 'Operative.Instruction' | translate }}"></kendo-label>
      <kendo-textarea formControlName="instruction" [rows]="3"></kendo-textarea>
    </kendo-formfield>
    <kendo-formfield appearance="fill" class="inst-box">
      <kendo-label text="{{ 'Operative.OperatorComment' | translate }}"></kendo-label>
      <kendo-textarea formControlName="operatorComment" [rows]="3"></kendo-textarea>
    </kendo-formfield>
  </section>
  <section>
    <kendo-grid #grdProdOrderdetails
     [data]="prodOrderLinesGrid"
     [size]="'small'"
     (columnResize)="onGridStateChange()"
     (edit)="editHandler($event)"
     (cancel)="cancelHandler($event)"
     (save)="saveHandler($event)"
     (add)="addHandler($event)"
     (remove)="removeHandler($event)"
     (cellClick)="cellClickHandler($event)"
     (cellClose)="cellCloseHandler($event)"
     [resizable]="true"
     [navigable]="true">
      <kendo-grid-column title="{{ 'Operative.Line' | translate }}" [width]="15" [editable]="false">
        <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-isNew="isNew">
          {{ isNew ? this.prodOrderLinesGrid?.length + 1 : rowIndex + 1 }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="mainReportingLine" title="{{ 'Operative.Main' | translate }}" [width]="20"
        [editable]="!isActivityRelationLocked()">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.mainReportingLine ? ('App.Yes' | translate) : '' }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isNew">
          <input [formControl]="formGroup.get('isMain')" type="checkbox" />
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="material.description" title="{{ 'Operative.Material' | translate }}" [width]="50"
        [editable]="isEditable">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isNew">
          <app-select-entity [formControl]="formGroup.get('materialIndex')" [entityTypeCode]="entityTypeCode"
            (selectedEntity)="setSelectedMaterial($event)">
          </app-select-entity>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="{{ 'Operative.Activity' | translate }}" [width]="50" [editable]="false">
        <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
          {{ getActivityText(dataItem) }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="defaultInstructionLines.PlannedWidth" title="{{ 'Operative.PlannedWidth' | translate }}" [width]="30"
        [style]="{ 'text-align': 'left' }">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isNew"
        *ngIf="prodOrder.machine.isPlannedWidthEditable">
          <kendo-textbox kendoGridFocusable [formControl]="formGroup.get('plannedWidth')"
            [ngModel]="formGroup.get('plannedWidth').value | ReplaceDotToCommaPipe"></kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate  let-dataItem let-isNew="isNew">
          {{ dataItem.plannedWidth ? (dataItem.plannedWidth | ReplaceDotToCommaPipe) : 'n/s'}}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="defaultInstructionLines.PlannedThickness" title="{{ 'Operative.PlannedThickness' | translate }}" [width]="30"
        [style]="{ 'text-align': 'left' }">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isNew"
        *ngIf="prodOrder.machine.isPlannedThicknessEditable">
          <kendo-textbox kendoGridFocusable [formControl]="formGroup.get('plannedThickness')"
            [ngModel]="formGroup.get('plannedThickness').value | ReplaceDotToCommaPipe"></kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate kendoGridFocusable let-dataItem let-isNew="isNew">
          {{ dataItem.plannedThickness ? (dataItem.plannedThickness | ReplaceDotToCommaPipe) : 'n/s' }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="defaultInstructionLines.PlannedLength" title="{{ 'Operative.PlannedLength' | translate }}" [width]="30"
        [style]="{ 'text-align': 'left' }">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isNew"
          *ngIf="prodOrder.machine.isPlannedLengthEditable">
          <kendo-textbox kendoGridFocusable [formControl]="formGroup.get('plannedLength')"
            [ngModel]="formGroup.get('plannedLength').value | ReplaceDotToCommaPipe"></kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
          {{ dataItem.plannedLength ? (dataItem.plannedLength | ReplaceDotToCommaPipe) : 'n/s' }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column [field]="defaultInstructionLines.PlannedQuality" title="{{ 'Operative.PlannedQuality' | translate }}" [width]="30"
        [style]="{ 'text-align': 'left' }">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isNew" *ngIf="prodOrder.machine.isPlannedQualityEditable">
          <kendo-textbox kendoGridFocusable [formControl]="formGroup.get('plannedQuality')"></kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
          {{ dataItem.plannedQuality != null ? dataItem.plannedQuality : 'n/s' }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column *ngFor="let instructionLine of instructionLines; let i = index"
        title="{{ instructionLine.instructionName }}"
        field="{{ instructionLine.instructionLineId }}"
        [width]="30">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ getAdditionalInstructionValue(dataItem, instructionLine.instructionLineId) }}
        </ng-template>
        <ng-template
          *ngIf="instructionLine.instructionConfigurations?.length === 0"
          kendoGridEditTemplate
          let-dataItem
          let-formGroup="formGroup"
          let-isNew="isNew">
          <kendo-textbox kendoGridFocusable
            [formControl]="getAdditionalInstructionformControl(formGroup, instructionLine.instructionLineId)">
          </kendo-textbox>
        </ng-template>
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isNew"
          *ngIf="instructionLine.instructionConfigurations?.length > 0">
        <kendo-dropdownlist kendoGridFocusable
          [data]="instructionLine.instructionConfigurations"
          [valuePrimitive]="true"
          valueField="configurationValue"
          textField="configurationValue"
          [formControl]="getAdditionalInstructionformControl(formGroup, instructionLine.instructionLineId)">
        </kendo-dropdownlist>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column title="{{ 'Operative.Relation' | translate }}" [width]="30" [editable]="false">
        <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
          {{
          isNew
          ? ('App.NA' | translate)
          : dataItem.materialRelationSum == 0
          ? ('App.NA' | translate)
          : dataItem.materialRelationSum
          }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="planedQtyUOM" title="{{ 'Operative.UnitCode' | translate }}" [width]="40"
        [editable]="isEditable || !isActivityRelationLocked()">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <app-select-unit [formControl]="formGroup.get('unitCode')"> </app-select-unit>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="directionText" title="{{ 'Operative.Direction' | translate }}" [width]="40"
        [editable]="isEditable || !isActivityRelationLocked()">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <app-select-entity-relation-direction [formControl]="formGroup.get('direction')"
                                                (onSelectDirection)="onSelectDirection($event)">
          </app-select-entity-relation-direction>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="stockLocationName" title="{{ 'App.StockLocation' | translate }}" [width]="40"
        [editable]="isEditable || !isActivityRelationLocked()">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup">
          <app-stock-location-selector [formControl]="formGroup.get('stockLocationId')" [isEdge]="true"
            (selectedStockLocationText)="setSelectedStockLocationText($event)">
          </app-stock-location-selector>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="isEmptyStock" title="{{ 'Operative.EmptyStock' | translate }}" [width]="20"
        [editable]="!isActivityRelationLocked() && isEmptyStockEditable()">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.isEmptyStock ? ('App.Yes' | translate) : '' }}
        </ng-template>
        <ng-template let-rowIndex="rowIndex" kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isEmptyStock"
          let-dataItem="dataItem">
          <input [formControl]="formGroup.get('isEmptyStock')" type="checkbox" />
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="planedQty" title="{{ 'App.Quantity' | translate }}" [format]="{ number: '1.3' }"
        [width]="30" [style]="{ 'text-align': 'right' }">
        <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isNew">
          <kendo-textbox [formControl]="formGroup.get('quantity')"
            [ngModel]="formGroup.get('quantity').value | ReplaceDotToCommaPipe"
            (keyup)="calculatePlanedQty($event)"></kendo-textbox>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{ dataItem.planedQty | number }}
        </ng-template>
        <ng-template kendoGridFooterTemplate>
          <div style="text-align: right;"> {{ total["planedQty"].sum.toFixed(4) }} </div>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="totalReportedQty" title="{{ 'Operative.ReportedQty' | translate }}"
        [format]="{ number: '1.3' }" [width]="30" [style]="{ 'text-align': 'right' }">
        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
          {{ dataItem.totalReportedQty | number }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-command-column title="{{ 'Operative.Command' | translate }}" [width]="50">
        <ng-template kendoGridCellTemplate let-dataItem let-isNew="isNew">
          <button kendoGridEditCommand [primary]="true" [disabled]="!enableProdOrderLineEdit(dataItem)">
            {{ 'App.Edit' | translate }}
          </button>
          <button kendoGridRemoveCommand [disabled]="!enableProdOrderLineRemove(dataItem)" themeColor="error">
            {{ 'App.Remove' | translate }}
          </button>
          <button kendoGridSaveCommand [disabled]="prodOrderLineForm?.invalid" themeColor="warning">
            {{ isNew ? ('App.Add' | translate) : ('App.Update' | translate) }}
          </button>
          <button kendoGridCancelCommand themeColor="error">{{ isNew ? ('App.Discard' | translate) : ('App.Cancel' | translate) }}</button>
        </ng-template>
      </kendo-grid-command-column>
      <ng-template kendoGridToolbarTemplate let-dataItem>
        <button kendoGridAddCommand themeColor="primary">
          {{ 'App.AddNew' | translate }}
        </button>
        <button kendoButton themeColor="primary" (click)="recreateChain()"
         [disabled]="!prodOrder.allowKilnSequenceRecreateChaining">
          {{ 'Operative.ReCreateChain' | translate }}
        </button>
        <button kendoButton themeColor="secondary" (click)="createReport()"
         [disabled]="prodOrderStateId < prodOrderState.Released">
          {{ 'Operative.CreateReport' | translate }}
        </button>
        <button kendoButton themeColor="secondary" (click)="viewEventDetails()"
          [disabled]="prodOrderForm.get('prodOrderId').value === 0">
          {{ 'Operative.ViewEvent' | translate }}
        </button>
        <button kendoButton themeColor="secondary"
                (click)="showGlueset()"
                [disabled]="isShowGluesetDisabled()">
          {{ 'GluePlan.ShowGlueset' | translate }}
        </button>
        <kendo-grid-spacer></kendo-grid-spacer>
        <div style="padding-right: 10px">
          <button kendoButton themeColor="primary" (click)="unlockRelation()" [disabled]="!isActivityRelationLocked()">
            {{ 'Operative.UnlockRelation' | translate }}
          </button>
        </div>
      </ng-template>

      <ng-template let-dataItem kendoGridDetailTemplate [kendoGridDetailTemplateShowIf]="showReportGrid">
        <kendo-grid [data]="dataItem.prodOrderReport.reverse()" [resizable]="true" [size]="'small'">
          <kendo-grid-column field="materialCode" title="{{ 'Operative.Material' | translate }}"> </kendo-grid-column>

          <kendo-grid-column field="reportedQty" title="{{ 'Operative.ReportedQty' | translate }}"> </kendo-grid-column>

          <kendo-grid-column field="reportedQtyUOM" title="{{ 'Operative.UnitCode' | translate }}"> </kendo-grid-column>

          <kendo-grid-column field="eventDate" title="{{ 'Operative.EventDate' | translate }}" format="g">
          </kendo-grid-column>
          <kendo-grid-column field="materialDescription" title="{{ 'Operative.Material' | translate }}">
          </kendo-grid-column>
          <kendo-grid-column field="stockLocationName" title="{{ 'App.StockLocation' | translate }}">
          </kendo-grid-column>

          <kendo-grid-column field="stockLocationName" title="{{ 'App.StockLocation' | translate }}">
          </kendo-grid-column>
          <kendo-grid-column field="reportDate" title="{{ 'Operative.ReportDate' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.reportDate | siteDateFormat }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="eventDate" title="{{ 'Operative.EventDate' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.eventDate | siteDateFormat }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="isActive" title="{{ 'App.Active' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.isActive == 1 ? ('App.Yes' | translate) : '' }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="message" title="{{ 'App.Message' | translate }}"> </kendo-grid-column>

          <kendo-grid-column field="mainIdentifier" title="{{ 'Stock.HandelingUnit' | translate }}">
          </kendo-grid-column>

          <kendo-grid-column field="reportedQty" title="{{ 'Operative.ReportedQty' | translate }}"> </kendo-grid-column>

          <kendo-grid-column field="reportedQtyUOM" title="{{ 'Operative.UnitCode' | translate }}"> </kendo-grid-column>

          <kendo-grid-column field="isPartial" title="{{ 'App.Partial' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.isPartial == 1 ? ('App.Yes' | translate) : ('App.No' | translate) }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="reportData" title="{{ 'Operative.ReportData' | translate }}"> </kendo-grid-column>

          <kendo-grid-column field="message" title="{{ 'App.Message' | translate }}"> </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-grid>
  </section>
  <section class="row" style="width: 100%;">
    <div style="flex-grow: 1"></div>

    <a routerLink="/prod-order">
      <button mat-raised-button type="button" (click)="onClose()">
        {{ 'App.Close' | translate }}
      </button>
    </a>

    <button mat-raised-button type="submit" color="primary" [disabled]="disableSubmitButton()" [mat-dialog-close]="true"
      (click)="onSubmit()">
      {{ 'App.Submit' | translate }}
    </button>
  </section>
  </form>

  <app-confirmation-dialog *ngIf="itemToRemoveLineNo" (conformationDialogCloseEvent)="confirmRemove($event)">
  </app-confirmation-dialog>

  <app-confirmation-dialog
    *ngIf="confirmStateUpdate"
    (conformationDialogCloseEvent)="confirmUpdate($event)" [msg]="changeStateBackToReleaseMsg">
</app-confirmation-dialog>
