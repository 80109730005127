import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { UnitService } from '@app/core/services/http-services/common/unit-service';
import { IUnit } from '@app/core/models/unit.model';

marker('App.UnitCode');

@Component({
  selector: 'app-select-unit',
  template: `
  <kendo-formfield *ngIf="showLable">
    <kendo-label translate>{{label}}</kendo-label>
    <kendo-dropdownlist
    [data]="units"
    textField="unitCode"
    valueField="unitCode"
    [loading]="loading"
    [valuePrimitive]="true"
    [(value)]="selectedUnitCode"
    (selectionChange)="onChangedVal($event)"
    [disabled]="disableTextbox">
  </kendo-dropdownlist>
  </kendo-formfield>
<kendo-dropdownlist
  [data]="units"
  textField="unitCode"
  valueField="unitCode"
  [loading]="loading"
  [valuePrimitive]="true"
  [(value)]="selectedUnitCode"
  (selectionChange)="onChangedVal($event)"
  *ngIf="!showLable"
  class="c">
</kendo-dropdownlist>
`,
  styleUrls: ['./select-unit.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectUnitComponent
    }
  ]
})
export class SelectUnitComponent implements OnInit, ControlValueAccessor, OnDestroy {
  units: IUnit[] = [];
  selectedUnitCode:string = 'M3';
  loading:boolean = true;

  @Input() label:string = 'App.UnitCode';
  @Input() showLable:boolean = false;
  @Input() disableTextbox:boolean = false;

  @Input() set selectedUnitCodeInput(unitCode: string) {
    this.selectedUnitCode = unitCode;
  };

  @Output() onUnitChange = new EventEmitter<string>();

  private onTouched!: Function;
  private onChanged!: Function;

  constructor(private unitService: UnitService) {

  }

  ngOnInit(): void {
    this.loadUnit();
  }

  ngOnDestroy() {
  }

  private loadUnit() {
    this.unitService.query({}).subscribe(u => {
      this.units = u;
      this.loading = false;
    });
  }

  onChangedVal(unit:IUnit) {
    this.selectedUnitCode = unit != null ? unit.unitCode : '';
    this.onUnitChange.emit(this.selectedUnitCode);
    this.onTouched();
    this.onChanged(this.selectedUnitCode);
  }

  writeValue(unitCode: string): void {
    this.selectedUnitCode = unitCode;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
