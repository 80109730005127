import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-qr-code-generator',
  templateUrl: './qr-code-generator.component.html',
  styleUrls: ['./qr-code-generator.component.css']
})
export class QrCodeGeneratorComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA)
              public input: { qrCodeInput: string }
  , private dialogRef: MatDialogRef<QrCodeGeneratorComponent>
  ) { }

  public qrCode: string;

  ngOnInit(): void {
    this.qrCode = this.input.qrCodeInput;
  }

  onClose() {
    this.dialogRef.close();
  }
}
