<h1 mat-dialog-title>
    {{ 'Stock.QuantityReportTitle' | translate  }} {{ hu.identifiers[0]?.identifier }}
</h1>
<mat-dialog-content class="app-dialog-content">
    <form
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        class="row">
        <kendo-formfield>
            <kendo-label>QTY</kendo-label>
            <kendo-numerictextbox formControlName="qty"></kendo-numerictextbox>
        </kendo-formfield>
        <app-select-unit
            formControlName="qtyUnitCode"
            [showLable]="true">
        </app-select-unit>
    </form>
    <div>
        <div *ngFor="let i of hu.identifiers">{{i.assignedBy}}: {{i.identifier}}</div>
    </div>
    <kendo-grid
        [data]="viewquantity | async"
        style="width: 100%;height: 100ch;"
        [selectable]="true"
        [reorderable]="true"
        [resizable]="true"
        [filterable]="true"
        [filter]="state.filter"
        [pageable]="true"
        [pageSize]="state.take"
        [skip]="state.skip"
        [loading]="gridLoading"
        (dataStateChange)="dataStateChange($event)"
        [size]="'small'">
        <kendo-grid-column
            filter="text"
            field="stockLocationName"
            title="{{ 'Stock.StockLocation' | translate }}">
        </kendo-grid-column>
        <kendo-grid-column
            filter="numeric"
            field="qty"
            title="{{ 'Stock.Quantity' | translate }}">
            <ng-template
            kendoGridCellTemplate
            let-dataItem=dataItem >
           {{dataItem.qty | number}}
        </ng-template>
          </kendo-grid-column>
        <kendo-grid-column
            filter="text"
            field="qtyUnit"
            title="{{ 'Stock.QuantityUnit'| translate  }}"></kendo-grid-column>
        <kendo-grid-column
            filter="boolean"
            field="isActive"
            title="{{ 'Stock.IsActive'| translate  }}"></kendo-grid-column>
        <kendo-grid-column
            filter="boolean"
            field="isPartioal"
            title="{{ 'Stock.IsPartioal'| translate  }}"></kendo-grid-column>


        <kendo-grid-column
            filter="date"
            field="eventDate"
            title="{{ 'Stock.EventDate'| translate  }}">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.eventDate | siteDateFormat}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
            filter="date"
            field="reportDate"
            title="{{ 'Stock.ReportDate'| translate  }}">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.eventDate | siteDateFormat}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
            filter="text"
            field="changeUser"
            title="{{ 'Stock.ChangeUser'| translate  }}"></kendo-grid-column>


        <kendo-grid-column
            *ngFor="let p of handelingunitprop; let i = index"
            title="{{p.propCode}}"
            field="{{p.propCode}}"
            [width]="100">
            <ng-template
                kendoGridCellTemplate
                let-dataItems
                let-rowIndex="rowIndex"
                *ngIf="p!==undefined">
                {{getPropertyValue(dataItems,p.propCode)}}
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</mat-dialog-content>


<mat-dialog-actions align="end">
    <button
        mat-raised-button
        type="button"
        [mat-dialog-close]="true">
        {{"App.Close" | translate }}
    </button>
    <div style="flex-grow: 1;"></div>
    <button
        mat-raised-button
        type="button"
        color="primary"
        (click)="onSubmit()">
        {{"App.Save" | translate }}
    </button>
</mat-dialog-actions>
