import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, timer } from 'rxjs';

import { RunJobService } from '@app/core/services/http-services/run-job/run-job.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { WatsonStatus, WatsonRunStatus, WatsonSolveStatus } from '@app/core/models/watson-status';
import { AdminService } from '@app/core/services/http-services/admin/admin.service';
import { WatsonDataSetEnum } from '@app/core/models/watson-dataset.enum';
import { WatsonDeployedModel } from '@app/core/models/watson-deployed.model';
import { IGridSetting, IGeneralFilterSetting } from '@app/core/models/gridSetting';
import { UserSettingkeyEnum } from '@app/core/models/user-setting-key.enum';
import { IUserSettingDTO } from '@app/core/models/userSettingDTO';
import { UserSettingService } from '@app/core/services/http-services/common/user-setting.service';

marker('OptimizationOrderTranslation.SequencerNotFound');
marker('OptimizationOrderTranslation.SequencerQueued');
marker('OptimizationOrderTranslation.SequencerRunning');
marker('OptimizationOrderTranslation.SequencerFaliedOrCancelled');
marker('OptimizationOrderTranslation.SequencerCompletedWithFeasibleSolution');
marker('OptimizationOrderTranslation.SequencerCompletedWithoutFeasibleSolution');

@Component({
  selector: 'app-sequencer',
  templateUrl: './sequencer.component.html',
  styleUrls: ['./sequencer.component.css']
})
export class SequencerComponent implements OnInit {
  min: number = 1;
  max: number = 10;
  step: number = 1;
  sequencerForm: UntypedFormGroup;
  loadingPanelVisible = false;
  watsonDeployedModels: WatsonDeployedModel[] = [];
  watsonJobId: string;
  jobState: WatsonStatus;

  private pollCounter: Observable<number>;
  private counterSub: Subscription;
  private settings: IUserSettingDTO;
  private numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  private settingsId = UserSettingkeyEnum.glulam_sequence_optimizer;

  public title = (value: number): string => {
    return `${this.numbers[value]}`;
  };

  constructor(private adminService: AdminService,
     private runJobService: RunJobService,
     private userSettingService: UserSettingService) { }

  ngOnInit(): void {
    this.setSequencerForm();
    this.getDeployedModels();
  }

  ngAfterViewInit(): void {
    this.getUserSettings();

    this.sequencerForm.valueChanges.subscribe((values) => {
      if (values.wf_lpd || values.wf_batch || values.wf_avg_start_end) {
        setTimeout(() => {
          this.convertSettingsToDTO(this.createUserFilterSettingsDTO());
          this.saveOrUpdateUserSetting();
        }, 1000);
      }
    });

    this.pollCounter = timer(0, 3000); // polling
  }

  optimize() {
    this.loadingPanelVisible = true;
    this.isDisabled = true;

    const value = { ...this.sequencerForm.value };

    value.params = {
      wf_lpd: value.wf_lpd,
      wf_avg_start_end: value.wf_avg_start_end,
      wf_batch: value.wf_batch
    };

    this.runJobService.startOptimization(value)
      .subscribe(d => {
        this.loadingPanelVisible = false;
        this.watsonJobId = d.jobId;
        this.jobState = d;
        this.StartJobPole();
        this.PollState();
      }, _ => {
        this.loadingPanelVisible = false;
        this.isDisabled = false;
      });
  }

  StartJobPole() {
    this.counterSub = this.pollCounter.subscribe(n => {
      if (this.watsonJobId) {
        this.PollState();
      }
    });
  }

  message = '';
  isDisabled = false;
  private PollState() {
    this.loadingPanelVisible = true;
    this.runJobService.PollState(this.watsonJobId, true)
      .subscribe(d => {
        this.loadingPanelVisible = false;
        const [ws, state] = d;
        this.jobState = ws;
        switch (state) {
          case -1:
            this.message = 'OptimizationOrderTranslation.SequencerNotFound';
            break;
          case 1:
            this.message = 'OptimizationOrderTranslation.SequencerQueued';
            break;
          case 2:
            this.message = 'OptimizationOrderTranslation.SequencerRunning';
            break;
          case 3:
            this.message = 'OptimizationOrderTranslation.SequencerFaliedOrCancelled';
            this.StopCounters();
            break;
          case 4:
            this.message = 'OptimizationOrderTranslation.SequencerCompletedWithFeasibleSolution';
            this.StopCounters();
            break;
          case 5:
            this.message = 'OptimizationOrderTranslation.SequencerCompletedWithoutFeasibleSolution';
            this.StopCounters();
            break;
        }
      }, _ => {
        this.loadingPanelVisible = false;
        this.StopCounters();
        this.jobState.status = WatsonRunStatus.appError;
        this.jobState.solveStatus = WatsonSolveStatus.appError;
      });
  }

  private setSequencerForm() {
    this.sequencerForm = new UntypedFormGroup({
      deployedModelId: new UntypedFormControl(null, [Validators.required]),
      days: new UntypedFormControl(30, [Validators.required]),
      wf_lpd: new UntypedFormControl('', [Validators.required]),
      wf_avg_start_end: new UntypedFormControl('', [Validators.required]),
      wf_batch: new UntypedFormControl('', [Validators.required]),
      timeOrigin: new UntypedFormControl(new Date(Date.now()), [Validators.required]),
      timeZoneOffset: new UntypedFormControl(new Date(Date.now()).getTimezoneOffset(), [Validators.required])
    });
  }

  private setDefaultSequencerFormValues() : void {
    this.sequencerForm.patchValue({
      wf_lpd: 7,
      wf_avg_start_end: 7,
      wf_batch: 2
    });
  }

  private getDeployedModels() {
    this.adminService.getWatsonDeployments(WatsonDataSetEnum.Sequencer)
      .subscribe(d => {
        this.watsonDeployedModels = d;
        this.sequencerForm.get('deployedModelId').setValue(this.watsonDeployedModels[0].Id);
      });
  }

  private StopCounters() {
    this.counterSub.unsubscribe();
    this.isDisabled = false;
  }

  private getUserSettings(): void {
    this.userSettingService.getUserSetting(this.settingsId).subscribe(savedSettings => {
      if (savedSettings) {
        this.applySettingsToForm(savedSettings);
      } else {
        this.setDefaultSequencerFormValues();
      }
    });
  }

  private applySettingsToForm(savedSettings: any): void {
    savedSettings.filterSettings.forEach(setting => {
      switch (setting.field) {
        case 'wf_lpd':
          this.sequencerForm.patchValue({ wf_lpd: setting.value });
          break;
        case 'wf_avg_start_end':
          this.sequencerForm.patchValue({ wf_avg_start_end: setting.value });
          break;
        case 'wf_batch':
          this.sequencerForm.patchValue({ wf_batch: setting.value });
          break;
      }
    });
  }

  private createUserFilterSettingsDTO(): IGridSetting {
    const userSettings: IGridSetting = {
      columnSettings: [],
      filterSettings: []
    };

    const formValue = this.sequencerForm.value;

    userSettings.filterSettings.push(this.createGeneralFilterSettings('wf_lpd', formValue.wf_lpd));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('wf_avg_start_end', formValue.wf_avg_start_end));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('wf_batch', formValue.wf_batch));
    return userSettings;
  }

  private createGeneralFilterSettings(field: string, value: any): IGeneralFilterSetting {
    const setting : IGeneralFilterSetting = {
      field,
      value
    };
    return setting;
  }

  private convertSettingsToDTO(newSettings: any): void {
    this.settings = {
      key: this.settingsId,
      value: JSON.stringify(newSettings)
    };
  }

  private saveOrUpdateUserSetting(): void {
    this.userSettingService.saveUserSetting(this.settings).subscribe((result) => {
      console.log(result);
    });
  }
}
