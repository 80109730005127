import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Demandline } from '@app/core/models/demand-line.model';
import { IDemandLamellaSpecificationDTO } from '@app/core/models/demand-lamella-specification.model';
import { DemandSpecificationDialogComponent } from '@app/modules/aot/demand-specification-dialog/demand-specification-dialog.component';
import { IDemandDTO } from '@app-core-httpservice/gluelam/demand.service';

@Injectable({
  providedIn: 'root'
})
// need to test
export class DemandSpecificationService {
  constructor(private http: HttpClient,
    private dialog: MatDialog) { }

  get(beamOrderRowId: number): Observable<IDemandLamellaSpecificationDTO[]> {
    return this.http.get<IDemandLamellaSpecificationDTO[]>(`${environment.apiUrl}DemandSpecification/${beamOrderRowId}`);
  }

  save(demandSpecifications: IDemandLamellaSpecificationDTO[]): Observable<IDemandDTO> {
    return this.http.post<IDemandDTO>(`${environment.apiUrl}DemandSpecification`, demandSpecifications);
  }

  openDialog(demand: Demandline) : MatDialogRef<DemandSpecificationDialogComponent> {
    const dialogConfig: MatDialogConfig = {
      height: 'auto',
      width: '1000px',
      data: demand,
      disableClose: true
    };

    return this.dialog.open(DemandSpecificationDialogComponent, dialogConfig);
  }
}
