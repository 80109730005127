<ngx-spinner name="glueplanLoader"></ngx-spinner>
<button class="print-button" mat-raised-button color="primary" (click)="printComponent('contentToConvert')">{{'GluePlanPrint.Print' |
  translate}}</button>
<div   id="contentToConvert">
  <span class="print-one-heading">{{'GluePlanPrint.FloorOnePrint' | translate }}</span>
  <div class="row">
    <div class="column" style="margin-left: 1em;">
      <table class="print-table">
        <thead>
          <tr>
            <th>
              {{'GluePlanPrint.Code' | translate }}
            </th>
            <th>
              {{'GluePlanPrint.Value' | translate }}
            </th>
            <th>
              {{'GluePlanPrint.Code' | translate }}
            </th>
            <th>
              {{'GluePlanPrint.Value' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="gluePlan">
          <tr>
            <td>
              {{'GluePlanPrint.Glueplan' | translate }}
            </td>
            <td>
              {{gluePlan.gluingPlanID}}
            </td>
            <td>
              {{'GluePlanPrint.PlanedProductionDate' | translate }}
            </td>
            <td>
              {{gluePlan.plannedExecutionStartDate | date}}
            </td>
          </tr>
          <tr>
            <td>
              {{'GluePlanPrint.Width' | translate }}
            </td>
            <td>
              {{gluePlan.dimension?.width}}
            </td>
            <td>
              {{'GluePlanPrint.State' | translate }}
            </td>
            <td>
              <span [style.color]="getStateColor(gluePlan.glueSetStateId)">
                {{ getStateText(gluePlan.glueSetStateId) | translate}}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              {{'GluePlanPrint.Quality' | translate }}
            </td>
            <td>
              {{qualitys}}
            </td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td>
              {{'GluePlanPrint.Instruction' | translate }}
            </td>
            <td colspan="3">{{gluePlan.instruction}}</td>
          </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="column">
      <table class="print-table">
        <thead>
          <tr>
            <th>
              {{'GluePlanPrint.Code' | translate }}
            </th>
            <th>
              {{'GluePlanPrint.TotalVolume' | translate }}
            </th>
            <th>
              {{'GluePlanPrint.PendingVolume' | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="lamNeed">
            <tr *ngFor="let k of lamNeed.lamNeeds">
              <td>
                {{k.code}}
              </td>
              <td style="text-align: right;">
                {{k.volume | number:'1.1-1'}} M3
              </td>
              <td style="text-align: right;">
                {{k.pendingVolume | number:'1.1-1'}} M3
              </td>
            </tr>
            <tr class="print-table-total">
              <td>
                {{'GluePlanPrint.Total' | translate }}
              </td>
              <td style="text-align: right;">
                {{lamNeed.volume | number:'1.1-1'}} M3
              </td>
              <td style="text-align: right;">
                {{lamNeed.volumePending | number:'1.1-1'}} M3
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div [ngClass]="{'gluesetTable':glusets?.length > 20}">
    <table class=" print-table">
      <thead>
        <tr>
          <th>
            {{'GluePlanPrint.State' | translate }}
          </th>
          <th>
            {{'GluePlanPrint.GlusetId' | translate }}
          </th>
          <th>
            {{'GluePlanPrint.WE' | translate }}
          </th>
          <th>
            {{'GluePlanPrint.PlaningThickness' | translate }}
          </th>
          <th>
            {{'GluePlanPrint.StrengthClass' | translate }}
          </th>
          <th>
            {{'GluePlanPrint.Lenght' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let gs of glusets" [ngClass]="{'bold-row': gs.length > 20200}">

          <td>
            {{ getStateText(gs.beamMaterialGlueSetState.glueSetStateId) | translate }}
          </td>
          <td>
            {{gs.beamMaterialGlueSetID}}
          </td>
          <td>
            <span *ngIf="gs.isMainProductWE">
              {{ 'App.Yes' | translate}}
            </span>
          </td>
          <td>
            <span color="red" *ngIf="firstBm(gs)?.planedThickness !=  40.5">
              {{firstBm(gs)?.planedThickness}}
            </span>
          </td>
          <td>
            <span color="red">{{lamellas[gs.beamMaterialGlueSetID.toString()]}}</span>
          </td>
          <td>
            {{gs.length}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
