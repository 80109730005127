<mat-card>
    <kendo-chart>
        <kendo-chart-title text="Sold M3"></kendo-chart-title>
        <kendo-chart-category-axis>
            <kendo-chart-category-axis-item
                [categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul']"
                [title]="{ text: 'Months' }">
            </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
        <kendo-chart-series>
          <kendo-chart-series-item type="area" [stack]="true" name="Stone" [data]="[123, 276, 310, 212, 240, 156, 98]">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="area" name="Wood" [data]="[165, 210, 287, 144, 190, 167, 212]">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="area" name="Plastic" [data]="[56, 140, 195, 46, 123, 78, 95]">
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
</mat-card>
