
    <div class="btn-row">
      <button mat-icon-button
      [disabled]="!plan ||plan?.glueSetStateId >= 2"
      (click)="addEmptyGlueSet()">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <h3 class="fw-bold"
         [ngClass]="{'margin0': isDeleteAllowed, 'margin15': !isDeleteAllowed}"
         [translate]="'GlueSet.Glueing'"
         [translateParams]="{no: bmgs.beamMaterialGlueSetID > 0 ? bmgs.beamMaterialGlueSetID : 'NEW'}">
      </h3>
      &nbsp; - &nbsp;
      <span [translate]="'GlueSet.Length'"
      [translateParams]="{lenght: bmgs.length }">
      </span>
      <div style="flex-grow: 1;"></div>
        <mat-icon class="color_green" *ngIf="!bmgs.save">
          done icon
        </mat-icon>
        <mat-icon class="color_blue" *ngIf="bmgs.save && !bmgs.errorMessageText">
          edit
        </mat-icon>
        <mat-icon color="warn" *ngIf="bmgs.errorMessageText" matTooltip="{{bmgs.errorMessageText | translate}}">
          error outline icon
        </mat-icon>
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
    </div>
    <div style="display: flex; flex-direction: row;">
      <div class="info-pill info-pill-accsent"
         *ngIf="bmgs.glusetBatch > 0">
        {{ "GlueSet.Batch" | translate }}: {{bmgs.glusetBatch}}
      </div>
    </div>
    <div #bmgsContainer *ngFor="let layer of bmgs.layers; let i_layer = index">
      <div class="btn-row">
        <button mat-icon-button [disabled]="!plan || plan?.glueSetStateId >= glueSetStateEnum.PLANNED" (click)="onAddNewLayer()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
          <h3>
            {{ "GlueSet.Layer" | translate }} : {{bmgs.layers.length - i_layer}}
          </h3>
          <div style="flex-grow: 1;"></div>
        <button mat-icon-button [disabled]="!isDeleteAllowed" (click)="onDeleteLayer(i_layer)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div>
        <svg
          [attr.height]="containerHight"
          [attr.width]="gsWidth"
          #layer
          [attr.y]="calcY(i_layer)"
          x="0">
          <rect class="pressbed" *ngFor="let ram of ramConfiguration; let i = index"
            [attr.width]="getWidth(ram.width)"
            [attr.x]="getWidth(ram.position)"
            height="100%"
            >
          </rect>
            <rect x="0"
              y="0"
              [attr.width]="gsWidth"
              [attr.height]="containerHight"
              style="fill: #ffffff00; stroke-width: 1; stroke: rgb(0, 0, 0);"
              (contextmenu)="openBMGSContextMenu(layer, $event,i_layer)">
            </rect>
          <svg app-beam-material
              *ngFor="let bm of layer.BeamMaterials; let i = index"
              [bm]="bm" [ch]="ch"
              [attr.y]="calcYForLayer(i, layer)"
              [isSplitView]="false"
              (openBeamMaterialLineMenu)="openContextMenu($event,i_layer)">
          </svg>
        </svg>
      </div>
    </div>
<mat-menu #menu="matMenu">
  <button mat-menu-item color="primary" (click)="splitView()">
    <mat-icon>view_agenda</mat-icon>
    <span translate>GlueSet.SplitView</span>
  </button>
  <button mat-menu-item (click)="download()" [disabled]="!(bmgs.beamMaterialGlueSetState?.glueSetStateId > 1)">
    <mat-icon>cloud_download</mat-icon>
    <span translate>GlueSet.Download</span>
  </button>
  <button mat-menu-item color="warn" (click)="onDelete()"
    [disabled]="!isOperator && bmgs.beamMaterialGlueSetState?.glueSetStateId > 1">
    <mat-icon>delete</mat-icon>
    <span translate>App.Delete</span>
  </button>
</mat-menu>
