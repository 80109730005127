<div class="main">
  <mat-card>
    <mat-card-header>
      <img
      mat-card-avatar
      src="../../assets/img/fx_x.svg">
      <mat-card-title> {{ 'AppComponent.WelcomeMsg' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>{{ 'AppComponent.PageNotFound' | translate }}</p>
    </mat-card-content>
  </mat-card>
</div>
