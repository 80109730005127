import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IEntity } from '@app/core/services/http-services/model/entity.service';
import { CommonHelper } from '@app/shared/helpers/common-helper';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DemandTypeEnum, getDemmandTypeValues } from '@app/core/models/aot-demand-type.enum';
import { PlanningTypeEnum, getPlanningTypeValues } from '@app/core/models/aot-planning-type.enum';
import { IDemand, DeamndService, IDemandPOSequenceDTO, IDemandInputForActivitySelection } from '@app/core/services/http-services/gluelam/demand.service';
import { Guid } from 'guid-typescript';
import { CreateDemandForm } from '@app/core/models/forms/aot/demand-form/create-demand-form.model';

marker('Operative.AddDemandErrMsg');
marker('Operative.DemandAddedSuccessfully');
marker('Operative.DemandAndPOSeuqenceCreation');

const createFormGroup = (d: IDemand) => new FormGroup<CreateDemandForm>({
  siteId: new FormControl<Guid>(d.siteId, [Validators.required]),
  demandID: new FormControl<number>(d.demandID, [Validators.required]),
  entityGroupCode: new FormControl<string>(d.entityGroupCode, [Validators.required]),
  materialIndex: new FormControl<number|null>(d.materialIndex),
  externalItemID: new FormControl<string>(d.externalItemID),
  qty: new FormControl<number>(d.qty ? Math.abs(d.qty) : null, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  qtyUnit: new FormControl<string>(d.qtyUnit ? d.qtyUnit : 'PCS'),
  length: new FormControl<number>(d.length, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  hight: new FormControl<number>(d.hight, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  width: new FormControl<number>(d.width, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  species: new FormControl<string>(d.species),
  isSpecialFormBeam: new FormControl<boolean>(false),
  specialFormName: new FormControl<string>({ value: '', disabled: true }),
  radius: new FormControl<string>({ value: '', disabled: true }, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
  orderID: new FormControl<string>(d.orderID),
  orderRowID: new FormControl<string>(d.orderRowID),
  latestProductionDate: new FormControl<Date>(d.latestProductionDate ? new Date(d.latestProductionDate) : null, [Validators.required]),
  beamOrderRowID: new FormControl<number>(d.beamOrderRowID),
  materialCode: new FormControl<string>(d.materialCode),
  instruction: new FormControl<string>(''),
  demandType: new FormControl<number>(DemandTypeEnum.StockOrder),
  isActive: new FormControl<boolean>(false),
  planningType: new FormControl<number>(PlanningTypeEnum.Normal)
});

@Component({
  selector: 'app-demand-form',
  templateUrl: './demand-form.component.html',
  styleUrls: ['./demand-form.component.css']
})
export class DemandFormComponent implements OnInit {
  public _demand: IDemand;
  public disableSubmit: boolean = false;
  public isDemandPOSequence: boolean = false;
  public demandForm: FormGroup;

  public demandTypes = getDemmandTypeValues();
  public planningTypes = getPlanningTypeValues();

  constructor(public formBuilder: FormBuilder,
    public beamOrderRowService: DeamndService,
    @Inject(MAT_DIALOG_DATA)
    public data: { demand: IDemand },
    public dialogRef: MatDialogRef<DemandFormComponent>,
    public notificationService: AppNotificationService) {
  }

  ngOnInit(): void {
    this._demand = this.data.demand;
    this.demandForm = createFormGroup(this._demand);

    this.demandForm.get('demandType').valueChanges.subscribe(value => {
      if (value) {
        switch (value) {
          case DemandTypeEnum.StockOrder:
            this.demandForm.get('isActive').patchValue(false);
            break;
          default:
            this.demandForm.get('isActive').patchValue(true);
        }
      }
    });
    this.demandForm.get('isSpecialFormBeam').valueChanges.subscribe(value => {
      if (value) {
        this.demandForm.get('specialFormName').enable();
        this.demandForm.get('radius').enable();
      } else {
        this.demandForm.get('specialFormName').reset({ value: '', disabled: true });
        this.demandForm.get('radius').reset({ value: '', disabled: true });
      }
    });
  }

  setSelectedMaterial(material: IEntity) {
    if (material != null) {
      this.demandForm.patchValue({
        length: material.standardProperties.length,
        hight: material.standardProperties.thickness,
        width: material.standardProperties.width,
        species: material.standardProperties.species,
        entityGroupCode: material.entityGroup.index.toString(),
        externalItemID: material.code,
        materialCode: material.code
      });
    }
  }

  onSubmit() {
    const formValue = this.demandForm.value;

    const d: IDemand = <IDemand>{
      demandID: formValue.demandID ?? 0,
      entityGroupCode: formValue.entityGroupCode,
      materialIndex: formValue.materialIndex,
      externalItemID: formValue.externalItemID,
      qty: Number(CommonHelper.replaceCommawithDot(formValue.qty ?? -1)),
      qtyUnit: formValue.qtyUnit,
      length: formValue.length ?? 0,
      hight: formValue.hight ?? 0,
      width: formValue.width ?? 0,
      species: formValue.species,
      orderID: formValue.orderID,
      orderRowID: formValue.orderRowID,
      latestProductionDate: formValue.latestProductionDate,
      materialCode: formValue.materialCode,
      demandType: formValue.demandType,
      isActive: formValue.isActive,
      siteId: formValue.siteId,
      planningType: formValue.planningType,
      isSpecialFormBeam: formValue.isSpecialFormBeam,
      specialFormName: formValue.specialFormName,
      radius: formValue.radius
    };

    this.disableSubmit = true;
    const createDemandPOSequence = <IDemandPOSequenceDTO>{
      demand: d,
      isGenerateProdOrder: this.isDemandPOSequence,
      instruction: formValue.instruction,
      siteId: formValue.siteId
    };
    if (this.isDemandPOSequence) {
      const d : IDemandInputForActivitySelection = {
        beamOrderRowID: 0,
        orderId: formValue.orderID,
        orderRowId: formValue.orderRowID,
        materialIndex: formValue.materialIndex,
        currentPosition: 1,
        totalCount: 1
      };
      this.beamOrderRowService.openDialogForGlulamSeq(d)
        .afterClosed()
        .subscribe(data => {
          if (data) {
            createDemandPOSequence.activitysSelected = data;
            createDemandPOSequence.isGenerateProdOrder = true;
            this.insertDemand(createDemandPOSequence);
          } else {
            this.disableSubmit = false;
          }
        });
    } else {
      this.insertDemand(createDemandPOSequence);
    }
  }

  private insertDemand(createDemandPOSequence: IDemandPOSequenceDTO) {
    this.beamOrderRowService.insertDemand(createDemandPOSequence).subscribe(
      (beamOrderRowID) => {
        this.disableSubmit = false;
        if (this.isDemandPOSequence) {
          this.notificationService.notifySucsessAppChanel('Operative.DemandAndPOSeuqenceCreation');
        } else {
          this.notificationService.notifySucsessAppChanel('Operative.DemandAddedSuccessfully');
        }
        this.isDemandPOSequence = false;
        createDemandPOSequence.demand.beamOrderRowID = beamOrderRowID;
        this.dialogRef.close(createDemandPOSequence);
      },
      () => {
        this.disableSubmit = false;
        this.notificationService.notifyErrorAppChanel('Operative.AddDemandErrMsg');
      }
    );
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onDemandPOSequence() {
    if (this.demandForm.get('qtyUnit').value && this.demandForm.get('qtyUnit').value !== 'PCS') {
      this.notificationService.notifyErrorAppChanel('Operative.AddAndGenerateSequenceErrorMsg');
      return;
    }
    this.isDemandPOSequence = true;
    this.onSubmit();
  }
}
