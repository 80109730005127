import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { Demandline } from '@app/core/models/demand-line.model';
import { BeamMaterialGlueSet } from '../../../core/models/beam-material-glue-set.model';
import { BeamMaterial } from '../../../core/models/beam-material.model';
import { IGluingPlanDemandVolumeReportDTO } from '../../../core/models/gluing-plan-demand-volume-report';
import { IGluingPlanDemandVolumeReportAltDTO } from '../../../core/models/gluing-plan-demand-volume-report-alt';
import { GluingPlan } from '../../../core/models/gluing-plans.model';

@Component({
  selector: 'app-glueplan-print-second',
  templateUrl: './glueplan-print-second.component.html',
  styleUrls: ['./glueplan-print-second.component.css']
})

export class GlueplanPrintSecondComponent implements OnInit {
  gluePlan: GluingPlan;
  glusets: BeamMaterialGlueSet[];
  demandVolumeRepot: IGluingPlanDemandVolumeReportDTO;
  demandVolumeRepotAlt: IGluingPlanDemandVolumeReportAltDTO;
  gluesetsByThickness: { [thickness: string]: string; } = {};
  keys: string[] = [];
  gluesetsWithMoreLength: string[] = [];
  qualitys: string = 'N/A';

  constructor(private route: ActivatedRoute,
              public gpservice: GluePlanService,
              public gsservice: GlueSetService) {
    this.route.params.subscribe({
      next: params => {
        console.log(`LOAD: ${params.id}`);
        const gpID = params.id;

        this.gpservice.getGluingPlan(gpID).subscribe({
          next: gp => {
            console.log('FOUND GP');
            this.gluePlan = gp;
          }
        });

        this.gsservice.getGlueSetByGluingPlanId(gpID).subscribe({
          next: gps => {
            this.glusets = gps;
            let indexCounter = 0;
            this.glusets.forEach(gs => {
              if (gs.length > 20200) {
                if (!this.gluesetsWithMoreLength.includes(gs.beamMaterialGlueSetID.toString())) {
                  indexCounter = indexCounter + 1;
                  (indexCounter) % 24 === 0 ? this.gluesetsWithMoreLength.push('\n') : this.gluesetsWithMoreLength.push(gs.beamMaterialGlueSetID.toString());
                }
              }
            });

            this.glusets.forEach((gs, index) => {
              gs.layers.forEach(l => {
                l.BeamMaterials.forEach(bm => {
                  if (bm.planedThickness !== 40.5) {
                    this.keys.push(bm.planedThickness.toString());
                  }
                  const value = (this.gluesetsByThickness[bm.planedThickness.toString()] ?? '');
                  if (value.indexOf(gs.beamMaterialGlueSetID.toString()) < 0) {
                    this.gluesetsByThickness[bm.planedThickness.toString()] = (index + 1) % 20 === 0 ? value + gs.beamMaterialGlueSetID + ',' + '\n' : value + gs.beamMaterialGlueSetID + ',';
                  }
                });
              });
            });
            this.keys = this.keys.filter((value, index, self) => self.indexOf(value) === index);
            this.qualitys = this.firstDemand(this.glusets[0]).ConstructionCertification;
          }
        });

        this.gpservice.getGluingPlanDemandVolumeReportAlt(gpID).subscribe({
          next: gps => {
            // have missed one propertys
            gps.gluingplanDemandVolumes.forEach(gpdv => {
              const prop:string[] = [];
              const wrappingDescription:string[] = [];

              if (gpdv.beaM_FILM_WRAPPING) {
                prop.push('W');
              }

              if (gpdv.beaM_SPLIT) {
                prop.push('Spalter');
              }

              if (gpdv.beaM_BEVELLING !== 'F1') {
                prop.push(gpdv.beaM_BEVELLING_DESC);
              }

              if (gpdv.beaM_DIMMENTIONAL_ACCURACY) {
                prop.push('Vollmassig');
              }

              if (gpdv.oveR_MAX_LENGHT) {
                prop.push('>20,2m');
              }

              if (gpdv.beaM_MAIN_PRODUCT === 'DE') {
                prop.push(`${gpdv.beaM_MAIN_PRODUCT} ${gpdv.beaM_PROFILE} ${gpdv.beaM_PROFILE_DESC}`);
              } else if (gpdv.beaM_MAIN_PRODUCT === 'WE') {
                prop.push(`${gpdv.beaM_MAIN_PRODUCT} ${gpdv.lamminaWidth}x${gpdv.lamminaHeight} ${gpdv.beaM_PROFILE} ${gpdv.beaM_PROFILE_DESC}`);
              } else if (gpdv.oveR_HIGHT) {
                prop.push('Breite>600mm');
              }

              if (gpdv.beaM_COVER_DESC) {
                wrappingDescription.push(gpdv.beaM_COVER_DESC);
              }

              gpdv.propertyValue = prop.join(', ');
              gpdv.wrappingDescription = wrappingDescription.join(', ');
            });
            this.demandVolumeRepotAlt = gps;
          }
        });
      }
    });
  }

  ngOnInit(): void { }

  firstBm(bmgs: BeamMaterialGlueSet): BeamMaterial {
    const layer = bmgs.layers[0];
    return layer.BeamMaterials[0];
  }

  firstDemand(bmgs: BeamMaterialGlueSet): Demandline {
    const bm = this.firstBm(bmgs);
    return bm.beamMaterialLines[0].transportPackageDemand;
  }

  printComponent(cmpName) {
    const printContents = document.getElementById(cmpName).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.location.reload();
  }
}
