<h1 mat-dialog-title class="primaryColor section-header-operative">{{"App.View" | translate}}</h1>

<mat-dialog-content>
    <div
        #bmgsContainer
        class="bmgs-cont col"
        *ngIf="data.beamMaterials && data.beamMaterials.length > 0">
        <div
            *ngFor="let bm of data.beamMaterials; let i = index"
            class="pt-3">
            <h4 class="sub-header-margin">
                {{"GlueSet.BEAM_MATERIAL" | translate}} - {{i+1}}
            </h4>
            <div *ngFor="let c of cutsToShow; let i_x = index">
                <h5 class="sub-header-margin">
                    {{"GlueSet.BEAM_MATERIAL_CUT" | translate}} - {{c}}
                </h5>
                <svg
                    [attr.height]="getBMHeight(bm)"
                    app-beam-material
                    [bm]="bm"
                    [ch]="ch"
                    style="width: 99%;"
                    [containerWidth]="bmgsWidth"
                    [cut]="c"
                    [isSplitView]="true">
                </svg>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
  mat-raised-button
  (click)="closeDialog()">
  Close
  </button>
</mat-dialog-actions>

