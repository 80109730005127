import { Component, ViewChild } from '@angular/core';
import { ProdOrderCreateModelService } from '@app/core/services/http-services/operative/prod-order-create-model.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IProdOrderCreateInput } from '@app/core/models/prod-order-create-input.model';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { ProdOrderGridComponent } from '@app/modules/operative/controls/prod-order-grid/prod-order-grid.component';
import { ProdOrderCalendarService } from '@app/modules/operative/services/prod-order-calendar.service';

marker('Operative.SelectMaterial');

@Component({
  selector: 'app-prod-order',
  templateUrl: './prod-order.component.html',
  styleUrls: ['./prod-order.component.css']
})
export class ProdOrderComponent {
  selectEntityForm: UntypedFormGroup = new UntypedFormGroup({
    materialIndex: new UntypedFormControl(null, [Validators.required])
  });

  prodorderFilterForm: UntypedFormGroup = new UntypedFormGroup({
    siteId: new UntypedFormControl(null, [Validators.required])
  });

  @ViewChild(ProdOrderGridComponent) grid: ProdOrderGridComponent;
  deniedRole = [UserRoleEnum.Operator];
  constructor(
      private prodOrderCreateModelService: ProdOrderCreateModelService
    , public prodOrderCalendarService: ProdOrderCalendarService
  ) { }

  openProdOrderMaterialDialog() {
    this.prodOrderCreateModelService.openDialog(<IProdOrderCreateInput>{
      productIndex: this.selectEntityForm.get('materialIndex').value,
      startDateTime: new Date(),
      quantity: '',
      unitCode: '',
      siteId: this.prodorderFilterForm.get('siteId').value
    }).then((d) => {
      if (d) {
        this.grid.loadProdOrder();
      }
    });
  }

  reloadProdOrderGrid() {
    this.grid.loadProdOrder();
  }

  openAutoSchedulerPopup() {
    this.prodOrderCalendarService.openAutoSchedulerPopup()
      .afterClosed()
      .subscribe((d) => {
        if (d) {
          this.grid.loadProdOrder();
        }
      });
  }
}
