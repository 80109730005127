<h1 mat-dialog-title translate>
  Beams.ManualBeamMaterial
</h1>
<mat-dialog-content class="margin-top-10">
  <kendo-grid [data]="manulaBeamMaterial.demands"
              (cellClick)="cellClickHandler($event)"
              (cellClose)="cellCloseHandler($event)">
    <kendo-grid-column field="orderId" title="OrderID" [editable]="false"></kendo-grid-column>
    <kendo-grid-column field="length" title="Length" [editable]="false"></kendo-grid-column>
    <kendo-grid-column field="qty" title="Qty" [editable]="true" editor="numeric"></kendo-grid-column>
  </kendo-grid>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <kendo-numerictextbox
        [(ngModel)]="manulaBeamMaterial.length"  placeholder="Lenght">
    </kendo-numerictextbox>
  </div>
  <button mat-raised-button type="submit" color="primary" (click)="addManualBeamMaterial()" translate>
    {{'App.Add' | translate}}
  </button>
  <button mat-raised-button type="button" (click)="closeDialog()" translate>
    {{'App.Close' | translate}}
  </button>
</mat-dialog-actions>
