import { Component, Inject, Output, EventEmitter, LOCALE_ID, OnInit, OnDestroy, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@app/core/services/auth-service/auth.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { UserService, OptinexUserDTO, AppLocale, UserSiteDTO } from '@app/core/services/http-services/common/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { ResolveEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';

@Component({
  selector: 'app-user-settings',
  template: `
  <section class="user-profile mat-typography">
  <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
      <div class="col">
        <img [src]="userAvatar" (error)="avatarError()" alt="Avatar" [matTooltip]="localeId">
        <div style="flex-grow: 1;"></div>
        <!--<flag-icon class="pointer" *ngFor="let l of locales" (click)="setLang(l, true)" [country]="l.flag" [style]="{'grayscale(1)' : true }"></flag-icon>-->
        <div *ngFor="let l of locales">
        <span *ngIf="l.flag" (click)="setLang(l, true)"  [ngClass]="['fi', 'fi-' + l.flag ]"></span>
        </div>
        <mat-icon  *ngIf="userInfo?.isGlobalAdmin"  (click)="setLang(locales[4], true)">title</mat-icon>
        <mat-icon aria-label="Lock menu open." class="pointer" (click)="onPinMenu()">{{userInfo?.isMenuExpanded ? 'lock' : 'lock_open'}}</mat-icon>
        <mat-icon aria-label="Menu" class="pointer" *ngIf="!userInfo?.isMenuExpanded" (click)="onToggleMenu()">menu</mat-icon>
      </div>
      <h2 class='title'>{{name}} ({{userInfo?.tennatId}})</h2>
      <h3 class="subheading-2">{{account?.username}} ({{userInfo?.userName}})</h3>
      <app-select-tenant *ngIf="userInfo?.isGlobalAdmin" [showLable]="true" [label]="'App.Tenant'" [selectedTenant]='userInfo?.tennatId'></app-select-tenant>
  </section>
  `,
  styleUrls: ['user-settings.component.css']
})
export class UserSettingsComponent implements OnInit, OnDestroy {
  userInfo: OptinexUserDTO;
  userAvatar: SafeUrl;
  locales:AppLocale[];

  private _isMenuExpanded: boolean = true;
  @Input('isMenuExpanded')

  get isMenuExpanded(): boolean {
    return this._isMenuExpanded;
  }

  set isMenuExpanded(value: boolean) {
    this._isMenuExpanded = value;
    this.isMenuExpandedChange.emit(this._isMenuExpanded);
  }

  get account(): AccountInfo {
    return this.authService.getAccount();
  }

  get name(): string {
    // eslint-disable-next-line dot-notation
    return `${this.account?.idTokenClaims['name']}`;
  }

  private userImgRetrived = false;

  private readonly _destroying$ = new Subject<void>();

  @Output('isMenuExpandedChange') isMenuExpandedChange = new EventEmitter<boolean>();
  @Output('toggleMenu') toggleMenu = new EventEmitter<{}>();

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private userService: UserService,
    @Inject(LOCALE_ID) public localeId: string,
    public intlService: IntlService,
    private spinner: NgxSpinnerService,
    public router: Router,
    private msalBroadcastService: MsalBroadcastService,
    private _sanitizer: DomSanitizer,
    private localStorageService:LocalStorageService
  ) {
    // This language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    this.locales = userService.locales;
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof ResolveEnd),
      takeUntil(this._destroying$)
    ).subscribe((event: ResolveEnd) => {
      if (this.authService.isLoggedIn()) {
        this.getUserInfo();
      }
    });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.getUserInfo();
      });
  }

  private getUserProfileImg() {
    if (!this.userImgRetrived) {
      this.userService.getPofileImage().subscribe(b => {
        const objectURL = URL.createObjectURL(b);
        this.userAvatar = this._sanitizer.bypassSecurityTrustResourceUrl(objectURL);
      });
      this.userImgRetrived = true;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  getUserInfo() {
    if (!this.userInfo) {
      this.userService.getUser().then(userInfo => {
        this.userInfo = userInfo;
        this.isMenuExpanded = userInfo.isMenuExpanded;
        this.setLang(this.userService.getLocaleByCode(userInfo.languageCode), false);
        this.getUserProfileImg();
        const userRoles = { [UserRoleEnum.Developer]: userInfo.isDeveloper, [UserRoleEnum.Planner]: userInfo.isPlanner, [UserRoleEnum.Operator]: userInfo.isOperator };
        this.localStorageService.setItem(LocalStorageService.userRoles, JSON.stringify(userRoles));
        this.setUserSite(userInfo.userSites);
      });
    }
  }

  avatarError(): void {
    this.userAvatar = 'assets/img/img_avatar.png';
  }

  setLang(locale: AppLocale, save:boolean = true): void {
    if (this.userService.getLocale().id !== locale.id) {
      this.changeLocale(locale, save);
    } else {
      console.info('Using Old Locale');
    }

    this.translate.use(locale.language).subscribe(() => {
      this.localStorageService.localeChanged();
    });
  }

  private changeLocale(locale: AppLocale, save: boolean) {
    console.info('Switching Locale');

    this.userInfo.languageCode = locale.id.toLocaleLowerCase();

    if (save) {
      this.saveInfoWithLoadingIndicator();
    }

    this.userService.setLocale(locale.id);

    // window.location.reload();
  }

  onPinMenu() {
    this.userInfo.isMenuExpanded = !this.userInfo.isMenuExpanded;
    this.isMenuExpanded = this.userInfo.isMenuExpanded;
    this.userService.saveUser(this.userInfo).subscribe();
  }

  onToggleMenu() {
    this.toggleMenu.emit();
  }

  saveInfoWithLoadingIndicator(): void {
    this.spinner.show();
    this.userService.saveUser(this.userInfo).subscribe(
      () => this.spinner.hide(),
      () => this.spinner.hide()
    );
  }

  setUserSite(userSites: UserSiteDTO[]) {
    // set site in local storage here
    if (userSites !== null) {
      // set userSites
      this.localStorageService.setItem(LocalStorageService.userSites, JSON.stringify(userSites));

      // set selected site id
      const selectedSiteId = this.localStorageService.getItem(LocalStorageService.selectedSiteId) ?? '';
      if (selectedSiteId === '') {
        this.setDefaultSiteAsSelectedSite(userSites);
      } else {
        // check if selectedSiteId is part of userSites
        if (!userSites.some(x => x.siteId === selectedSiteId)) {
          this.setDefaultSiteAsSelectedSite(userSites);
        }
      }
    }
  }

  private setDefaultSiteAsSelectedSite(userSites: UserSiteDTO[]) {
    const defaultSite = this.userService.getDefaultSite(userSites);
    this.localStorageService.setItem(LocalStorageService.selectedSiteId, defaultSite.siteId);
  }
}
