import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { IProdOrderLineDTO, ICreateProdOrderReportDTO, IProdOrderReportStateDTO, IProdOrderReportDTO, IHandelingUnitReportDTO } from '@app/core/models/prod-order';
import { ProdOrderReportComponent } from '@app/modules/operative/controls/prod-order-report/prod-order-report.component';

@Injectable({
  providedIn: 'root'
})
export class ProdOrderReportService {
  constructor(private http: HttpClient
    , private dialog: MatDialog) {
  }

  openDialog(prodOrderLines:IProdOrderLineDTO[], prodOrderId: number) : MatDialogRef<ProdOrderReportComponent, any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '1700px';
    dialogConfig.height = '900px';
    dialogConfig.data = {
      prodOrderLines,
      prodOrderId
    };

    return this.dialog.open(ProdOrderReportComponent, dialogConfig);
  }

  addUpdateProdOrderLineReport(prodOrderReport:ICreateProdOrderReportDTO) : Observable<IProdOrderReportStateDTO> {
    const url = `${environment.apiUrl}ProdOrder/addUpdateProdOrderLineReport`;
    return this.http.post<IProdOrderReportStateDTO>(url, JSON.stringify(prodOrderReport), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  getHandelingUnitIdentifer(prodOrderReports:IProdOrderReportDTO):Observable<IHandelingUnitReportDTO[]> {
    return this.http.post<IHandelingUnitReportDTO[]>(`${environment.apiUrl}ProdOrder/GetHandelingUnitIdentifier`, prodOrderReports)
    ;
  }
}
