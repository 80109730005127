<div
  class="planner-container k-h-full">
  <div [ngClass]="{ 'major_left': isLeftMajor, 'minor_left': !isLeftMajor, 'left-section' : true}">
    <app-order-view
      [selectedLamminaDimension]="selectedLamminaDim"
      [uiQty]="uiQty"
      [isLeftMajor]="isLeftMajor"
      (optimise)="openOptimizationEditor()"
      (updateSize)="resizeGrid($event)"
      [selectedPlan]="selectedPlan">
      *ngIf="selectedPlan">
    </app-order-view>
  </div>
  <div [ngClass]="{ 'minor_right': isLeftMajor, 'major_right': !isLeftMajor}">
    <div class="k-w-full k-h-full k-d-flex k-flex-col">
    <app-gluing-plans
      [isLeftMajor]="isLeftMajor"
      [updatedTotalBeamCount]="updatedTotalBeamCount"
      [isNewGluesetsCreatedByOptimization]="isNewGluesetsCreatedByOptimization"
      (onConfirmUnsavedGlueSet)="onConfirmUnsavedGlueSet($event)">
    </app-gluing-plans>
    <div class="section-header">
      <button
        mat-icon-button
        (click)="AddEmptyBmgs()"
        [disabled]="!selectedPlan ||selectedPlan?.glueSetStateId >= 2">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
      <h3 translate>GluePlan.GlueSets</h3>
      <div style="flex-grow: 1;"></div>
      <button
        mat-raised-button
        (click)="saveGluesetsToGluingPlan()"
        [disabled]="disableSaveGluesetsButton()"
        class="float-right">
        {{ "GlueSet.SAVE_ALL" | translate }}
      </button>
    </div>

    <div class="glue-set-container">
      <app-glue-set-view
        [gluesets]="BeamMaterialGlueSetSuggestions"
        [plan] = "selectedPlan"
        [cols]="gsCols"
        [dimension]="selectedLamminaDim"
        (onUpdate)="onCalcTotalsPerBeamOrderRow()"
        (onDeleteGlueSet)="DeleteBeamMaterialGlueSet($event.gs, $event.index)">
      </app-glue-set-view>
    </div>
  </div>
  </div>
</div>
