import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FileRestrictions, UploadEvent } from '@progress/kendo-angular-upload';
import { FileStorageService, IFileDetails } from '@app/core/services/http-services/common/file-storage.service';

import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { FileDownloadMode } from '@app/core/models/file-download-mode-enum';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, OnChanges {
  files:IFileDetails[] = [];
  removeUploadedFile:boolean = false;
  removeFileName:string = null;
  loading = true;
  uploadSaveUrl = null;
  fileDownloadMode = FileDownloadMode;

  constructor(
    public fileStorageService:FileStorageService,
    private appNotificationService: AppNotificationService
  ) { }

  @Input() container:string;
  @Input() folder:string;
  @Input() canEdit:boolean = true;
  @Input() downloadMode : FileDownloadMode;
  @Input() disabled : boolean;

  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.pdf'], // remove or add extensions as per requirement
    maxFileSize: 52428800 // 50MB = 52428800Bytes
  };

  ngOnInit(): void {
    this.getFiles();
    this.uploadSaveUrl = this.fileStorageService.createUpploadUrl(this.container);
  }

  ngOnChanges(): void {
    this.getFiles();
  }

  public getFiles() {
    this.loading = true;
    this.fileStorageService.getUploadedFileNames(this.container, this.folder).subscribe(files => {
      this.loading = false;
      this.files = files;
    }, () => {
      this.loading = false;
    });
  }

  uploadEventHandler(upploadEvent: UploadEvent): void {
    upploadEvent.data = {
      folder: this.folder
    };
  }

  tryRemoveFile(fileName:string) {
    this.removeUploadedFile = true;
    this.removeFileName = fileName;
  }

  public downloadFile(fileURL:string) {
    this.fileStorageService.downloadPDF(fileURL).subscribe(result => {
      const fileURL = URL.createObjectURL(result);
      window.open(fileURL, '_blank');
    });
  }

  /**
   * Downloads the files at once and opens all of them
   * in a new browser tab
   */
  public downloadAndPreviewPDF(): void {
    if (this.files && this.files.length > 0) {
      this.loading = true;
      this.files.forEach(file => {
        this.downloadFile(file.fileURL);
      });
      this.loading = false;
    }
  }

  public confirmRemoveUploadedFile(confirmChange:boolean) {
    if (confirmChange) {
      this.fileStorageService.removeUploadedFile(this.container, this.folder, this.removeFileName).subscribe(t => {
        this.removeUploadedFile = false;
        this.appNotificationService.notifyInfoAppChanel('EntityTranslation.InstructionFileDeleted');
        this.getFiles();
      });
    } else {
      this.removeUploadedFile = false;
      this.removeFileName = null;
    }
  }
}
