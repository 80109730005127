import { Component, OnDestroy, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EntityRelationDirection } from '@app/core/services/http-services/model/entity.service';
import { EnumToObject } from '@app/shared/helpers/transform-enum-to-object.helper';
import { IEnumKeyValue } from '@app/core/models/enumKeyValue.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.Direction');

@Component({
  selector: 'app-select-entity-relation-direction',
  template: `
  <mat-button-toggle-group [(ngModel)]="selectedDirectionValue"  appearance="legacy" (change)="onChangedVal($event.value)">
    <mat-button-toggle [value]="0">
      <mat-icon>add</mat-icon>
  </mat-button-toggle>
    <mat-button-toggle [value]="1">
    <mat-icon>remove</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  `,

  styleUrls: ['./select-entity-relation-direction.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectEntityRelationDirectionComponent
    },
    EnumToObject
  ]
})
export class SelectEntityRelationDirectionComponent implements OnInit, ControlValueAccessor, OnDestroy {
  relationDirection: IEnumKeyValue[];
  selectedDirectionValue: number = null;

 @Output() onSelectDirection: EventEmitter<IEnumKeyValue> = new EventEmitter<IEnumKeyValue>();

  @Input() label: string = 'App.Direction';
  @Input() showLable: boolean = false;

  private onTouched!: Function;
  private onChanged!: Function;

  constructor(private enumToObject: EnumToObject) {
  }

  ngOnInit(): void {
    this.relationDirection = this.enumToObject.transform(EntityRelationDirection);
  }

  ngOnDestroy() {

  }

  onChangedVal(directionValue: number) {
    this.onTouched();
    if (directionValue != null) {
      const e = this.relationDirection.find(d => d.value === directionValue);
      this.selectedDirectionValue = directionValue;
      this.onSelectDirection.emit(e);
    } else {
      this.selectedDirectionValue = null;
      this.onSelectDirection.emit(null);
    }

    this.onChanged(this.selectedDirectionValue);
  }

  writeValue(value: number): void {
    this.selectedDirectionValue = value;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
