import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IMachineGroupDTO, MachineGroupService } from '@app/core/services/http-services/operative/machine-group.service';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { IMachineDTO } from '@app/core/models/machineDTO';

@Component({
  selector: 'app-machine-selector-multi',
  template: `
  <kendo-formfield>
    <kendo-label translate>Operative.SelectMachines</kendo-label>
    <kendo-multiselecttree
        kendoDropDownTreeExpandable
        [kendoDropDownTreeHierarchyBinding]="machineGroups"
        [textField]="'name'"
        [valueField]="'machineId'"
        childrenField="machines"
        [value]="value"
        [expandedKeys]="['0']"
        [checkAll]="true"
        [loadOnDemand]="false"
        [dataItems]="dataItems"
        [(value)]="value"
        (valueChange)="onChangedVal($event)"
        [tagMapper]="tagMapper"
      >
    </kendo-multiselecttree>
  </kendo-formfield>
  `,
  styleUrls: ['./machine-selector-multi.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MachineSelectorMultiComponent,
      multi: true
    }
  ]
})

export class MachineSelectorMultiComponent implements AfterViewInit, OnInit, ControlValueAccessor {
  machineGroups: IMachineGroupDTO[];
  value = [];
  dataItems = [];
  @Input() disableControl: boolean = false;
  @Input() widthClass: string = 'min-width-dropdown';
  @Input() machineGroupId: number = null;
  @Input() set refreshCounter(counter: number) {
    if (counter > 0) {
      this.loadMachines();
    }
  }

  @Output() selectedMachine : EventEmitter<IMachineDTO[]> = new EventEmitter<IMachineDTO[]>();

  private onTouched!: Function;
  private onChanged!: Function;

  constructor(private machineGroupService: MachineGroupService
    , private localStorageService: LocalStorageService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.loadMachines();
  }

  loadMachines() {
    this.machineGroupService.query(this.machineGroupId).subscribe(ret => {
      const mgs = ret.data as IMachineGroupDTO[];

      console.warn(ret.data);

      // below code will load only those machine group which has any machines
      const machineGroupwithMachines = mgs.filter(x => x.machines.length > 0);

      // In below code we are setting MachineId of MachineGroup because we have used valueField as MachineId while rendering control
      let defaultValue = 0;
      machineGroupwithMachines.forEach(x => {
        x.machineId = defaultValue += -1;
      });

      this.machineGroups = [...machineGroupwithMachines];

      const selectedMachines = JSON.parse(this.localStorageService.getItem(LocalStorageService.selectedMachines)) as IMachineDTO[];

      // Below logic to check if selected machines are part of machine group or not
      if (this.machineGroupId > 0) {
        const machinesBelongToMachineGroup: IMachineDTO[] = [];
        selectedMachines.forEach(m => {
          this.machineGroups.forEach(mg => {
            if (mg.machines.find(x => x.machineId === m.machineId)) {
              machinesBelongToMachineGroup.push(m);
            }
          });
        });
        this.setValueToMachineGroupObject(machinesBelongToMachineGroup);
        this.selectedMachine.emit(machinesBelongToMachineGroup);
      } else {
        this.setValueToMachineGroupObject(selectedMachines);
        this.selectedMachine.emit(selectedMachines);
      }
    });
  }

  setValueToMachineGroupObject(machines: any[]) {
    this.value = machines;
    this.dataItems = machines;
  }

  onChangedVal(valuesSelected:any[]) {
    const selectedMachines = [];
    valuesSelected.forEach(m => {
      if (m.machineId > 0) {
        selectedMachines.push(m);
      }
    });

    this.localStorageService.setItem(LocalStorageService.selectedMachines, JSON.stringify(selectedMachines));
    this.selectedMachine.emit(selectedMachines);

    // If we want to show group text also from UI then use below code
    this.setValueToMachineGroupObject(valuesSelected);

    // If we want to remove group text also from UI then use below code
    // this.setValueToMachineGroupObject(selectedMachines);
  }

  writeValue(machineId: number) {
    // this.selectedMachineId = machineId;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 8 ? tags : [tags];
  }
}
