import * as calendar from 'cldr-data/main/de/ca-gregorian.json';
import * as calendarHI from 'cldr-data/main/hi/ca-gregorian.json';
import * as calendarSV from 'cldr-data/main/sv/ca-gregorian.json';
import * as currencies from 'cldr-data/main/de/currencies.json';
import * as currenciesHI from 'cldr-data/main/hi/currencies.json';
import * as currenciesSV from 'cldr-data/main/sv/currencies.json';
import * as currencyData from 'cldr-data/supplemental/currencyData.json';
import * as dateFields from 'cldr-data/main/de/dateFields.json';
import * as dateFieldsHI from 'cldr-data/main/hi/dateFields.json';
import * as dateFieldsSV from 'cldr-data/main/sv/dateFields.json';
import * as likelySubtags from 'cldr-data/supplemental/likelySubtags.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as numbersHI from 'cldr-data/main/hi/numbers.json';
import * as numbersSV from 'cldr-data/main/sv/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import * as timeZoneNamesHI from 'cldr-data/main/hi/timeZoneNames.json';
import * as timeZoneNamesSV from 'cldr-data/main/sv/timeZoneNames.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';

import { DateInputModule, DateInputsModule, DateRangeModule } from '@progress/kendo-angular-dateinputs';
import { DropDownListModule, DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { IntlModule, load } from '@progress/kendo-angular-intl';

import { BarcodesModule } from '@progress/kendo-angular-barcodes';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ConfirmationDialogComponent } from '@app/core/components/confirmation-dialog/confirmation-dialog.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { FormsModule } from '@angular/forms';
import { IconModule } from '@progress/kendo-angular-icons';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { KendoGridDropDownFilterComponent } from './components/kendo-grid-drop-down-filter/kendo-grid-drop-down-filter.component';
import { KendoGridMultiselectDropDownFilterComponent } from './components/kendo-grid-multiselect-drop-down-filter/kendo-grid-multiselect-drop-down-filter.component';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { MenusModule } from '@progress/kendo-angular-menu';
import { NgModule } from '@angular/core';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PopupModule } from '@progress/kendo-angular-popup';
import { SiteFilterComponent } from './components/site-filter/site-filter.component';
import { StockLocationFilterComponent } from './components/stock-location-filter/stock-location-filter.component';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { UploadsModule } from '@progress/kendo-angular-upload';

load(
  likelySubtags,
  weekData,
  currencyData,
  numbers,
  currencies,
  calendar,
  dateFields,
  timeZoneNames,
  numbersSV,
  currenciesSV,
  calendarSV,
  dateFieldsSV,
  timeZoneNamesSV,
  numbersHI,
  currenciesHI,
  calendarHI,
  dateFieldsHI,
  timeZoneNamesHI
);

@NgModule({
  declarations: [
    KendoGridDropDownFilterComponent,
    KendoGridMultiselectDropDownFilterComponent,
    SiteFilterComponent,
    ConfirmationDialogComponent,
    StockLocationFilterComponent
  ],
  imports: [
    IntlModule,
    GridModule,
    MultiSelectModule,
    DropDownListModule,
    DropDownsModule,
    DateRangeModule,
    DateInputModule,
    InputsModule,
    FormsModule,
    TranslateModule,
    DateInputsModule,
    PopupModule,
    LabelModule,
    DialogModule,
    MenusModule,
    ChartsModule,
    ButtonsModule,
    IconModule,
    ExcelModule,
    IndicatorsModule,
    LayoutModule,
    TooltipsModule,
    UploadsModule,
    TreeViewModule,
    PDFExportModule,
    BarcodesModule
  ],
  exports: [
    GridModule,
    MultiSelectModule,
    DropDownListModule,
    DropDownsModule,
    DateRangeModule,
    DateInputModule,
    InputsModule,
    FormsModule,
    KendoGridDropDownFilterComponent,
    DateInputsModule,
    PopupModule,
    LabelModule,
    DialogModule,
    MenusModule,
    KendoGridMultiselectDropDownFilterComponent,
    SiteFilterComponent,
    ChartsModule,
    ConfirmationDialogComponent,
    ButtonsModule,
    IconModule,
    LayoutModule,
    ExcelModule,
    IndicatorsModule,
    TooltipsModule,
    UploadsModule,
    TreeViewModule,
    PDFExportModule,
    BarcodesModule,
    StockLocationFilterComponent
  ]
})
export class KendoImportModule { }
