import { Type } from '@angular/core';

export class CardItem {
  constructor(public component: Type<any>,
    public title:string,
    public cols:number,
    public rows:number,
    public data: any,
    public backgroundColor:string = null,
    public showTitle:boolean = true
  ) {}
}
