import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { State } from '@progress/kendo-data-query';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { IDeviceDTO, IDeviceSearchCriteriaDTO } from '@app/core/models/deviceDTO';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  constructor(public http: HttpClient
    , private kendoGridService: KendoGridService) {
  }

  get(state: State, criteria: IDeviceSearchCriteriaDTO): Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = new HttpParams()
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString());

    criteria.machines.forEach(m => {
      params = params.append('machineIds', m.machineId?.toString());
    });

    stateFilter.filter.forEach(element => {
      params = params.append(element.code, element.value);
    });

    return this.http.get<GridDataResult>(`${environment.apiUrl}Device`, { params });
  }

  insert(device: IDeviceDTO): Observable<IDeviceDTO> {
    return this.http.post<IDeviceDTO>(`${environment.apiUrl}Device`, JSON.stringify(device), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  update(device: IDeviceDTO): Observable<IDeviceDTO> {
    return this.http.put<IDeviceDTO>(`${environment.apiUrl}Device`, JSON.stringify(device), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  delete(deviceId: number): Observable<boolean> {
    return this.http.delete<boolean>(`${environment.apiUrl}Device/${deviceId}`);
  }

  generateNewAuthKey(deviceId: number): Observable<IDeviceDTO> {
    return this.http.put<IDeviceDTO>(`${environment.apiUrl}Device/generateNewAuthKey/${deviceId}`, null);
  }
}
