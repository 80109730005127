<h1 mat-dialog-title  translate>App.ScanQRCode </h1>

<mat-dialog-content class="popupContent">
  <qrcode [qrdata]="qrCode" [width]="400" [imageHeight]="250" [errorCorrectionLevel]="'M'"></qrcode>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-raised-button
    type="button"
    (click)="onClose()">
    {{"App.Close" | translate }}
  </button>
</mat-dialog-actions>
