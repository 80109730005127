<h1 mat-dialog-title>
    {{ "OptimizationOrderTranslation.OrderOptimization" | translate }}
</h1>
<mat-dialog-content>
    <kendo-stepper
        [steps]="optiSteps"
        [stepType]="stepType"
        [(currentStep)]="current">
        <ng-template
            kendoStepperLabelTemplate
            let-step>
            <div style="display: flex; flex-direction: column;">
                <span>{{ step.label }}</span>
                <span *ngIf="step.time">{{ step.time }} sec</span>
                <span>{{ step.state }}</span>
            </div>



        </ng-template>
    </kendo-stepper>
    <form
        [formGroup]="form"
        class="col">
        <div class="row">
            <kendo-formfield>
                <kendo-label translate>OptimizationOrderTranslation.Min_Length</kendo-label>
                <kendo-numerictextbox format="n0"
                      formControlName="minLenght"></kendo-numerictextbox>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label translate>OptimizationOrderTranslation.Max_Length</kendo-label>
                <kendo-numerictextbox format="n0"
                    formControlName="maxLength"></kendo-numerictextbox>
            </kendo-formfield>
        </div>
        <div class="row">
            <kendo-formfield>
                <kendo-label translate>OptimizationOrderTranslation.Min_Height</kendo-label>
                <kendo-numerictextbox format="n0"
                    formControlName="minHight"></kendo-numerictextbox>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label translate>OptimizationOrderTranslation.Max_Height</kendo-label>
                <kendo-numerictextbox format="n0"
                    formControlName="maxHight"></kendo-numerictextbox>
            </kendo-formfield>
        </div>
        <div class="row">
            <kendo-formfield>
                <kendo-label translate>
                    OptimizationOrderTranslation.NumberOfGlueSetBatches
                </kendo-label>
                <kendo-numerictextbox format="n0"
                    formControlName="numberOfGlueSetBatches"></kendo-numerictextbox>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label translate>
                    OptimizationOrderTranslation.MaxOrdersPerGlueSetBatch
                </kendo-label>
                <kendo-numerictextbox format="n0"
                    formControlName="maxOrdersPerGlueSetBatch"></kendo-numerictextbox>
            </kendo-formfield>
        </div>
        <div class="row">
            <kendo-formfield>
                <kendo-label translate>
                    OptimizationOrderTranslation.MaxBatchesPerOrder
                </kendo-label>
                <kendo-numerictextbox format="n0"
                    formControlName="maxBatchesPerOrder"></kendo-numerictextbox>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label translate>
                    OptimizationOrderTranslation.NumberOfGlusets
                </kendo-label>
                <kendo-numerictextbox format="n0"
                    formControlName="numberOfGlusets"></kendo-numerictextbox>
            </kendo-formfield>
        </div>



        <div class="row">
            <kendo-formfield>
                <kendo-label
                    translate
                    style="flex-grow: 1;">
                    OptimizationOrderTranslation.Optimization_Model
                </kendo-label>
                <kendo-dropdownlist
                    style="flex-grow: 1;"
                    [data]="watsonDeployedModels"
                    formControlName="deployedModelId"
                    [valuePrimitive]="true"
                    [textField]="'Name'"
                    [valueField]="'Id'">
                </kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label translate>
                    OptimizationOrderTranslation.MaxSolveTime
                </kendo-label>
                <kendo-numerictextbox format="n0"
                    formControlName="maxSolveTime"></kendo-numerictextbox>
            </kendo-formfield>
        </div>
        <div class="row">
            <kendo-formfield>
                <kendo-label translate>
                    OptimizationOrderTranslation.Optimization_WeightFactorAreaWaste
                </kendo-label>
                <kendo-slider
                    [min]="min"
                    [max]="max"
                    [largeStep]="step"
                    [title]="title"
                    formControlName="weightFactorAreaWaste"></kendo-slider>
            </kendo-formfield>
        </div>
        <div class="row">
            <kendo-formfield>
                <kendo-label translate>
                    OptimizationOrderTranslation.Optimization_WeightFactorHeightWaste
                </kendo-label>
                <kendo-slider
                    [min]="min"
                    [max]="max"
                    [largeStep]="step"
                    [title]="title"
                    formControlName="weightFactorHeightWaste"></kendo-slider>
            </kendo-formfield>
        </div>
        <div class="row">
            <kendo-formfield>
                <kendo-label translate>
                    OptimizationOrderTranslation.Optimization_WeightFactorFewOrdersPerBatch
                </kendo-label>
                <kendo-slider
                    [min]="min"
                    [max]="max"
                    [largeStep]="step"
                    [title]="title"
                    formControlName="weightFactorFewOrdersPerBatch"></kendo-slider>
            </kendo-formfield>
        </div>
        <div>
            <div class="row">
                <mat-label style="width: 200px;">ExecutionStatus:</mat-label>
                {{jobState?.status ?? 'Not Started'}}
            </div>
            <div class="row">
                <mat-label style="width: 200px;">SolveStatus:</mat-label>
                {{jobState?.solveStatus ?? 'Not Started'}}
            </div>
            <div class="row">
                <mat-label style="width: 200px;">JobID:</mat-label>
                {{JobUrl ?? 'Not Started'}}
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="row">
    <button
        mat-raised-button
        mat-dialog-close
        (click)="close()">
        {{ "App.Close" | translate }}
    </button>
    <div style="flex-grow: 1;"></div>
    <button
        mat-raised-button
        color="primary"
        (click)="setDefaultValues()">
        {{ "OptimizationOrderTranslation.SetDefault" | translate }}
    </button>
    <button
        mat-raised-button
        color="primary"
        (click)="saveSettings()">
        {{ "App.SaveSetting" | translate }}
    </button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <!-- <a class="btn btn-success" (click)="saveBeamMaterial()">Save</a> -->
    <button
        mat-raised-button
        color="primary"
        (click)="runOptimazation()"
        [disabled]="!form.valid"
        translate>
        {{ "OptimizationOrderTranslation.Optimize" | translate }}
    </button>
</mat-dialog-actions>
