import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IMachineGroupDTO, MachineGroupService } from '@app/core/services/http-services/operative/machine-group.service';
import { GroupResult, groupBy } from '@progress/kendo-data-query';

marker('Operative.selectMachineGroup');

@Component({
  selector: 'app-machine-group-selector-multi',
  template: `
 <kendo-formfield>
  <kendo-label *ngIf="showLable" class="min-width-dropdown">{{label | translate}}</kendo-label>
    <kendo-multiselect
        [loading]="loading"
        [data]="groupMachineGroups"
        [textField]="'name'"
        [valueField]="'machineGroupId'"
        [value]="value"
        [checkboxes]="true"
        (valueChange)="onChangedVal($event)"
        [tagMapper]="tagMapper"
        [checkboxes]="true"
        [valuePrimitive]="true"
        placeholder="{{label | translate}}"
      >
      <ng-template kendoMultiSelectHeaderTemplate>
        <span style="margin-left:11px">
        <input
            type="checkbox"
            id="chk"
            kendoCheckBox
            [checked]="isChecked"
            [indeterminate]="isIndet"
            (click)="selectAllItems()"
          />
          <kendo-label for="chk">{{ toggleAllText }}</kendo-label>
        </span>
      </ng-template>

      <ng-template kendoMultiSelectItemTemplate let-dataItem>
          <input
              type="checkbox"
              id="chk-{{ dataItem.name }}"
              kendoCheckBox
              [checked]="isItemSelected(dataItem)"
            />
        <span>{{ dataItem.SiteCode }} {{ dataItem.name }}</span>
      </ng-template>
    </kendo-multiselect>
  </kendo-formfield>
  `,
  styleUrls: ['./machine-group-selector-multi.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MachineGroupSelectorMultiComponent,
      multi: true
    }
  ]
})
export class MachineGroupSelectorMultiComponent implements AfterViewInit, OnInit, ControlValueAccessor {
  value:number[];
  dataItems = [];
  isChecked = false;
  machineGroups: IMachineGroupDTO[];

  groupMachineGroups: GroupResult[];

  data: any[] = [];

  @Input() disableControl: boolean = false;
  @Input() widthClass: string = 'min-width-dropdown';
  @Input() machineGroupId: number = null;
  @Input() label: string = 'Operative.selectMachineGroup';
  @Input() showLable: boolean = false;
  @Input() loading: boolean = false;
  @Input() set refreshCounter(counter: number) {
    if (counter > 0) {
      this.loadMachines();
    }
  }

  @Output() selectedMachineGroup : EventEmitter<IMachineGroupDTO[]> = new EventEmitter<IMachineGroupDTO[]>();

  @ViewChild('multiselect', { static: false }) multiselect: MultiSelectComponent;

  private onTouched!: Function;
  private onChanged!: Function;

  constructor(private machineGroupService: MachineGroupService
    , private localStorageService: LocalStorageService) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.loadMachines();
  }

  loadMachines() {
    this.machineGroupService.queryv2(this.machineGroupId, null, null, null).subscribe(ret => {
      this.machineGroups = ret.data as IMachineGroupDTO[];

      this.groupMachineGroups = groupBy(this.machineGroups, [{ field: 'siteCode' }]) as GroupResult[];

      const selectedIds = JSON.parse(this.localStorageService.getItem(LocalStorageService.selectedMachineGroups)) as number[];

      // Below logic to check if selected machines are part of machine group or not

      if (selectedIds !== null) {
        this.value = selectedIds;
        this.onChangedVal(selectedIds);
      }else{
        this.value = [];
        this.onChangedVal([]);
      }
    });
  }

  onChangedVal(valuesSelected:number[]) {
    this.value = valuesSelected;

    const toEmit = [];

    valuesSelected.forEach(id => {
      toEmit.push(this.machineGroups.find(x => x.machineGroupId === id));
    });

    console.log('toEmit', toEmit);

    this.localStorageService.setItem(LocalStorageService.selectedMachineGroups, JSON.stringify(valuesSelected));
    this.selectedMachineGroup.emit(toEmit);

    // If we want to show group text also from UI then use below cod

    // If we want to remove group text also from UI then use below code
    // this.setValueToMachineGroupObject(selectedMachines);
  }

  writeValue(machineId: number) {
    // this.selectedMachineId = machineId;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public get toggleAllText() {
    return this.isChecked ? 'Deselect All' : 'Select All';
  }

  public isItemSelected(item) {
    return this.value.some((x) => x === item.machineGroupId);
  }

  public selectAllItems() {
    this.isChecked = !this.isChecked;
    this.value = this.isChecked ? this.machineGroups.map(mg => mg.machineGroupId) : [];
    this.onChanged(this.value);
  }

  public get isIndet() {
    return (
      this.value.length !== 0 && this.value.length !== this.machineGroups.length
    );
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 8 ? tags : [tags];
  }
}
