import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ResolveEnd, Router } from '@angular/router';
import { IMenuItem, MenuService } from '../../services/http-services/common/menu.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AuthService } from '../../services/auth-service/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

interface ExampleFlatNode {
  expandable: boolean;
  level: number;
  selected: boolean;
  name: string;
  icon: string;
  url: string;
  loading: boolean;
  item: IMenuItem;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: [
    'menu.component.scss',
    'menu-theme.component.scss'
  ]
})
export class MenuComponent implements OnInit, OnDestroy {
  private items: IMenuItem[] = [];

  private _transformer = (node: IMenuItem, level: number) => {
    return {
      expandable: !!node.childItems && node.childItems.length > 0,
      level,
      selected: node.selected,
      url: node.url,
      loading: false,
      icon: node.icon,
      name: node.name,
      item: node
    };
  };

  treeControl = new CustomTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.childItems);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  private readonly _destroying$ = new Subject<void>();

  constructor(private menuService: MenuService,
    private msalBroadcastService: MsalBroadcastService,
    public router: Router,
    private authService: AuthService,
    public spinnerService: NgxSpinnerService) { }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.spinnerService.show('menuSpinner');
    this.dataSource.data = [];
    let routerEvent: ResolveEnd = null;

    this.router.events.pipe(
      filter(event => event instanceof ResolveEnd),
      takeUntil(this._destroying$)
    ).subscribe(async(event: ResolveEnd) => {
      if (this.authService.isLoggedIn()) {
        this.items = await this.menuService.getItems();
        this.setupSelection(event);
      }
      routerEvent = event;
    });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe(async(result) => {
        this.items = await this.menuService.getItems();
        this.setupSelection(routerEvent);
      });
  }

  private setupSelection(event: ResolveEnd) {
    this.setSelectedR(this.items, event.url);
    this.dataSource.data = this.items;

    this.treeControl.expandAll();

    const data = [...this.treeControl.dataNodes];

    this.treeControl.collapseAll();

    data.forEach(n => {
      if (n.selected) {
        this.treeControl.expandParents(n);
      }
    });
    this.spinnerService.hide('menuSpinner');
  }

  private setSelectedR(items: IMenuItem[], url: string) {
    items.forEach(i => {
      if (i.url === url) {
        i.selected = true;
      } else {
        i.selected = false;
      }

      if (!!i.childItems && i.childItems.length > 0) {
        this.setSelectedR(i.childItems, url);
      }
    });
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  itemClicked(e: ExampleFlatNode) {
    e.loading = true;
    e.selected = true;

    this.router.navigate([e.url]);
  }

  openInNewTab(e: ExampleFlatNode) {
    window.open(e.url, '_blank');
  }
}

export class CustomTreeControl<T> extends FlatTreeControl<T> {
  /**
   * Recursively expand all parents of the passed node.
   */
  expandParents(node: T) {
    const parent = this.getParent(node);
    this.expand(parent);

    if (parent && this.getLevel(parent) > 0) {
      this.expandParents(parent);
    }
  }

  /**
   * Iterate over each node in reverse order and return the first node that has a lower level than the passed node.
   */
  getParent(node: T) {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
  }
}
