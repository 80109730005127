<form [formGroup]="gluelamSchedulerForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="flx-grow-3">
      <kendo-formfield>
        <app-machine-selector-dropdown [machineGroupId]="machineGroup?.machineGroupId" formControlName="machineId" >
        </app-machine-selector-dropdown>
      </kendo-formfield>
    </div>

    <div class="flx-grow-1">
      <kendo-formfield>
        <kendo-label translate>Scheduler.StartDate</kendo-label>
        <kendo-datetimepicker formControlName="startDate">
        </kendo-datetimepicker>
      </kendo-formfield>
    </div>

    <div class="flx-grow-1">
      <kendo-formfield>
        <kendo-label translate>Scheduler.EndDate</kendo-label>
        <kendo-datetimepicker  [max]="maxDate" formControlName="endDate">
        </kendo-datetimepicker>
      </kendo-formfield>
    </div>

    <div class="flx-grow-0.5" >
      <button mat-button
              type="submit"
              class="scheduler-mat-btn"
              color="primary"
              title="Prev"
              color="primary"
              [disabled]="!gluelamSchedulerForm.valid"
              (click)="prev()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button mat-button
              type="submit"
              class="scheduler-mat-btn"
              color="primary"
              title="Next"
              color="primary"
              [disabled]="!gluelamSchedulerForm.valid"
              (click)="next()" >
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>

    <div class="flx-grow-1">
      <kendo-formfield>
        <kendo-label translate>Scheduler.TimeResolution</kendo-label>
        <kendo-dropdownlist [data]="timeSlots" textField="text" valueField="value" [valuePrimitive]="true"
          formControlName="timeResolution">
        </kendo-dropdownlist>
      </kendo-formfield>
    </div>

    <div class="flx-grow-1">
      <kendo-formfield>
        <kendo-label translate>Scheduler.LengthResolution</kendo-label>
        <kendo-dropdownlist [data]="lengthSlots" textField="text" valueField="value" [valuePrimitive]="true"
        formControlName="lengthResolution">
      </kendo-dropdownlist>
      </kendo-formfield>
    </div>

    <div class="flx-grow-1">
      <button class="w-100 mt-15" kendoButton themeColor="primary" [disabled]="!gluelamSchedulerForm.valid"
        type="submit">
        Submit
      </button>
    </div>
  </div>
</form>

<ngx-spinner type="ball-beat" [name]="'glueLamSchedulerLoader'" [fullScreen]="true">
</ngx-spinner>
<div oncontextmenu="return false;"  class="k-d-flex k-h-full k-overflow-auto">
  <kendo-chart [ngStyle]="{ 'minHeight.px': chartHeight }"
      (legendItemClick)="onLegendItemClick($event)"
      (seriesClick)="onSeriesClick($event)"
      class="gluePlanSchedulerKendoChart"
      *ngIf="data">
     <kendo-chart-series>
      <kendo-chart-series-item
        type="heatmap"
        xField="x"
        yField="y"
        [data]="data"
        [color]="color"
        [labels]="{ visible: false }"
        [highlight]="{visible:true}"
        [markers]="{ type: 'rect', border: { width: 1 } }">

        <kendo-chart-series-item-tooltip background="white">
          <ng-template let-value="value" let-category="category" let-dataItem="dataItem">
            <span *ngIf="getData(dataItem)?.length"> {{ getData(dataItem)?.name}} ({{ dataItem.ids }})
              <br> Length : {{ getData(dataItem)?.length}}
              <br> Offset : {{ getData(dataItem)?.offset}}
              <br> {{ getData(dataItem)?.plannedExecutionStartDate | date}} to {{getData(dataItem)?.plannedExecutionEndDate | date}}
            </span>
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>

    <kendo-chart-x-axis>
      <kendo-chart-x-axis-item [labels]="{ content: lenghtLabelContent }">
      </kendo-chart-x-axis-item>
    </kendo-chart-x-axis>

    <kendo-chart-y-axis>
      <kendo-chart-y-axis-item [labels]="{ content: dateLabelContent }">
      </kendo-chart-y-axis-item>
    </kendo-chart-y-axis>
  </kendo-chart>

  <kendo-contextmenu #chartmenu>
    <ng-template kendoContextMenuTemplate>
      <div class="gluePlanContextMenu">
        <button
        mat-mini-fab
        mat-flat-button
        title = "Edit"
        color="primary" (click)="onGluePlanEdit()">
        <mat-icon>create</mat-icon>
      </button>
      <button
        mat-mini-fab
        mat-flat-button
        disabled
        color="warn">
        <mat-icon>delete</mat-icon>
      </button>
      <button
        mat-mini-fab
        mat-flat-button
        title = "print"
        color="warn"
        [routerLink]="['/glulam/glueplan-print-third', selectedGluePlan.gluingPlanID]"
        >
        <mat-icon>print</mat-icon>
      </button>
      <button
        mat-mini-fab
        mat-flat-button
        color="primary"
        (click)="download()">
        <mat-icon>cloud_download</mat-icon>
      </button>
      </div>
    </ng-template>

  </kendo-contextmenu>
</div>

