import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DeamndService, IDemand } from '@app/core/services/http-services/gluelam/demand.service';
import { getDemmandTypeValues } from '@app/core/models/aot-demand-type.enum';
import { Demandline } from '@app/core/models/demand-line.model';

@Component({
  selector: 'app-demand-info',
  templateUrl: './demand-info.component.html',
  styleUrls: ['./demand-info.component.css']
})
export class DemandInfoComponent implements OnInit {
  public demandLine: Demandline;
  public demandTypes = getDemmandTypeValues();

  constructor(public beamOrderRowService: DeamndService,
    @Inject(MAT_DIALOG_DATA)
    public data: { guid: string, demand: IDemand },
    public dialogRef: MatDialogRef<DemandInfoComponent>) {
  }

  ngOnInit(): void {
    if (this.data.guid) {
      this.beamOrderRowService.get(this.data.guid).subscribe((response) => {
        if (response) {
          this.demandLine = response;
        }
      });
    }
  }

  getDeamndTypeText(id: number):string {
    return this.demandTypes.find(d => d.value === id)?.text;
  }
}
