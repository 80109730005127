import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { QrCodeGeneratorComponent } from '@app/modules/app-controls/qr-code-generator/qr-code-generator.component';

@Injectable({
  providedIn: 'root'
})
export class QRCodeService {
  constructor(private dialog: MatDialog) {}

  openDialog(qrCodeInput: string) : MatDialogRef<QrCodeGeneratorComponent, any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.width = '500px';
    dialogConfig.height = '550px';
    dialogConfig.data = { qrCodeInput };

    return this.dialog.open(QrCodeGeneratorComponent, dialogConfig);
  }
}
