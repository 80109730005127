<mat-card *ngIf="bmgs.layers.length > 0">
  <mat-card-content
    style="display: flex; flex-direction: column;"
    class="mat-typography">
    <div style="display: flex;flex-direction: row;">
      <div>
        <h1
          class="mat-card-title margin-bottom"
          [translate]="'GlueSet.Glueing'"
          [translateParams]="{no: bmgs.beamMaterialGlueSetID > 0 ? bmgs.beamMaterialGlueSetID : 'NEW'}">
      </h1>
      <div class="h2box">
        <h2 class="mat-card-subheading-1 margin-bottom color-grey"
          [translate]="'GlueSet.Length'"
          [translateParams]="{lenght: bmgs.length }"
          ></h2>
      </div>
      <h3 class="mat-card-subheading-1 margin-bottom color-grey">{{getStateText(bmgs.beamMaterialGlueSetState.glueSetStateId) | translate | uppercase }}</h3>
      </div>
      <div style="flex-grow: 1;"></div>
      <div>
        <mat-icon
          class="color_green"
          *ngIf="!bmgs.save">
          done icon
        </mat-icon>
        <mat-icon
          class="color_blue"
          *ngIf="bmgs.save && !bmgs.errorMessageText">
          edit
        </mat-icon>
        <mat-icon
          color="warn"
          *ngIf="isAnyErrorOrWarning(bmgs)"
          [matTooltip]="getTooltip(bmgs)">
          error outline icon
        </mat-icon>
        <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      </div>
    </div>

    <div style="display: flex; flex-direction: row;">
      <div
        class="info-pill info-pill-accsent"
        *ngIf="bmgs.glusetBatch > 0">
        {{ "GlueSet.Batch" | translate }}: {{bmgs.glusetBatch}}
      </div>
      <key-value-display
        *ngIf="bmgs.Template"
        [lable]="'GlueSet.Template'"
        [value]="bmgs.Template.BeamMaterialTypeID">
      </key-value-display>
      <key-value-display
        [lable]="'GlueSet.PerformedTimes'"
        [value]="bmgs.performedTimes"
        *ngIf="!editable">
      </key-value-display>
    </div>
    <div style="display: flex; flex-direction: row;">
      <svg [attr.width]="ch.getHight(widthSideMM)" [attr.height]="bmgs.layers.length * hightSideLayerPX"
            style="margin-right: 3px;">
        <rect [attr.width]="ch.getHight(widthSideMM)" height="100%" style="fill: white; stroke-width: 1; stroke: rgb(0, 0, 0);"></rect>

        <svg *ngFor="let layer of bmgs.layers; let i = index" [attr.width]="ch.getHight(widthSideMM)">
          <rect *ngFor="let bm of layer.BeamMaterials; let ib = index" [attr.x]="calcBMSideX(layer, ib)"
                [attr.y]="i * hightSideLayerPX" [attr.width]="ch.getHight(bm.getHight())"
                [attr.height]="hightSideLayerPX" [ngStyle]="bm.getColor()"></rect>
          <text *ngFor="let bm of layer.BeamMaterials; let ib = index" [attr.x]="calcBMSideX(layer, ib) + 5"
                [attr.y]="i * hightSideLayerPX + 14" [attr.width]="ch.getHight(bm.getHight())"
                [attr.height]="hightSideLayerPX">
            {{ bm.numberOfLamminas }}
          </text>
        </svg>
      </svg>
      <div style="display: flex; flex-direction: column;">
        <div
          *ngFor="let l of bmgs.layers"
          style="height: 25px;">
          <key-value-display
            [lable]="'H'"
            [value]="getHeightAllocation(l.totalHeight)"
            [useBackground]="false">
          </key-value-display>
        </div>
      </div>
      <div style="flex-grow: 1;"></div>
      <kendo-formfield
        *ngIf="editable"
        class="w-50px">
        <kendo-textbox
          placeholder="{{ 'GlueSet.Performed_Times' | translate }}"
          [ngModel]="bmgs.performedTimes"
          (ngModelChange)="onRepeatsChanged($event)"></kendo-textbox>
      </kendo-formfield>
    </div>
    <div #bmgsContainer>
      <svg [attr.height]="containerHight" [attr.width]="gsWidth" #layer
              *ngFor="let layer of bmgs.layers; let i_layer = index" [attr.y]="calcY(i_layer)" X="0">
        <rect x="0" y="0" [attr.width]="gsWidth" [attr.height]="containerHight"
                style="fill: white; stroke-width: 1; stroke: rgb(0, 0, 0);"
                (contextmenu)="openBMGSContextMenu(layer, $event)">
        </rect>
        <svg app-beam-material *ngFor="let bm of layer.BeamMaterials; let i = index"
                [bm]="bm" [ch]="ch" [attr.y]="calcYForLayer(i, layer)" [isSplitView]="false"
                (openBeamMaterialLineMenu)="openContextMenu($event)"></svg>
      </svg>
    </div>

  </mat-card-content>
  <!-- <mat-card-actions align="end">
    <button
      mat-mini-fab
      mat-flat-button
      color="primary"
      (click)="splitView()">
      <mat-icon>view_agenda</mat-icon>
    </button>
    <button
      mat-mini-fab
      mat-flat-button
      color="primary"
      (click)="download()"
      *ngIf="bmgs.beamMaterialGlueSetState.glueSetStateId > 1">
      <mat-icon>cloud_download</mat-icon>
    </button>
    <button
      mat-mini-fab
      color="warn"
      (click)="onDelete()"
      *ngIf="!isOperator && bmgs.beamMaterialGlueSetState.glueSetStateId <= 1">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-card-actions> -->
</mat-card>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    color="primary"
    (click)="splitView()">
    <mat-icon>view_agenda</mat-icon>
    <span translate>GlueSet.SplitView</span>
  </button>
  <button
    mat-menu-item
    (click)="download()"
    [disabled]="!(bmgs.beamMaterialGlueSetState.glueSetStateId > 1)">
    <mat-icon>cloud_download</mat-icon>
    <span translate>GlueSet.Download</span>
  </button>
  <button
    mat-menu-item
    color="warn"
    (click)="onDelete()"
    [disabled]="!isOperator && bmgs.beamMaterialGlueSetState.glueSetStateId > 1">
    <mat-icon>delete</mat-icon>
    <span translate>App.Delete</span>
  </button>
</mat-menu>
