<button class="print-button" mat-raised-button color="primary" (click)="printComponent('contentToConvert')">{{'GluePlanPrint.Print' |
  translate}}</button>
<div id="contentToConvert">
    <ng-template #spinnerTemplate>
        <mat-spinner strokeWidth="5" [diameter]="50" style="margin:0 auto;"></mat-spinner>
    </ng-template>
    <span class="print-two-heading">{{'GluePlanPrint.FloorTwoPrint' | translate }}</span>
    <div class="row">
        <div class="column" style="margin-left: 1em;">
            <table class="print-table">
                <thead>
                    <tr>
                        <th>
                            {{'GluePlanPrint.Code' | translate }}
                        </th>
                        <th>
                            {{'GluePlanPrint.Value' | translate }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{'GluePlanPrint.Glueplan' | translate }}
                        </td>
                        <td>{{gluePlan.gluingPlanID}}</td>
                    </tr>
                    <tr>
                        <td>
                            {{'GluePlanPrint.Width' | translate }}
                        </td>
                        <td>{{gluePlan.dimension?.width}}</td>
                    </tr>
                    <tr>
                        <td>
                            {{'GluePlanPrint.Gluesets' | translate }}
                        </td>
                        <td>{{glusets.length}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <table class="print-table">
                    <thead>
                        <tr>
                            <th>
                                {{'GluePlanPrint.LamminaWidth' | translate }}
                            </th>
                            <th>
                                {{'GluePlanPrint.Quality' | translate }}
                            </th>
                            <th>
                                {{'GluePlanPrint.GluesetIds' | translate }}
                            </th>
                            <th>
                                {{'GluePlanPrint.NoOfGluesets' | translate }}
                            </th>
                            <th>
                                {{'GluePlanPrint.OrderId' | translate }}
                            </th>
                            <th>
                                {{'GluePlanPrint.TotalVolume' | translate }}
                            </th>
                            <th>
                                {{'GluePlanPrint.DemandProperty' | translate }}
                            </th>
                            <th>
                              {{'GluePlanPrint.Wrapping' | translate }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <th colspan="7" *ngIf="!demandVolumeRepotAlt">
                            <ng-container [ngTemplateOutlet]="spinnerTemplate">
                            </ng-container>
                        </th>
                        <ng-container *ngIf="demandVolumeRepotAlt">
                            <tr *ngFor="let k of demandVolumeRepotAlt.gluingplanDemandVolumes">
                                <td>{{k.lamminaWidth}}</td>
                                <td>{{k.quality}}</td>
                                <td>
                                    <span *ngIf="k.numberOfGlusets == 1">{{k.minGluesetId}}</span>
                                    <span *ngIf="k.numberOfGlusets > 1">{{k.minGluesetId}} - {{k.maxGluesetId}}</span>
                                </td>
                                <td>{{k.numberOfGlusets}}</td>
                                <td>{{k.orderID}}</td>
                                <td>{{k.totalVolume | number:'1.1-1'}} M3</td>
                                <td>{{k.propertyValue}}</td>
                                <td>{{k.wrappingDescription}}</td>
                            </tr>
                            <tr>
                                <td colspan="4">
                                </td>
                                <td>
                                    {{'GluePlanPrint.Total' | translate }}
                                </td>
                                <td>{{demandVolumeRepotAlt.volume | number:'1.1-1'}} M3</td>
                                <td></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div>
      <table class="print-table">
          <tbody>
              <thead>
                  <tr>
                      <th colspan="2">
                          {{'GluePlanPrint.PressingLengthLongerThat222' | translate }}
                      </th>

                  </tr>
              </thead>
              <ng-container>
                  <tr>
                      <td *ngIf="gluesetsWithMoreLength && gluesetsWithMoreLength.length > 0" style="white-space: pre-line;">{{ gluesetsWithMoreLength }}
                      </td>
                  </tr>
              </ng-container>
          </tbody>
      </table>
  </div>

    <div>
        <table class="print-table" *ngIf="keys.length > 0">
            <thead>
                <tr>
                    <th>
                        {{'GluePlanPrint.PlaningThickness' | translate }}
                    </th>
                    <th>
                        {{'GluePlanPrint.GlusetId' | translate }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <th colspan="2" *ngIf="!keys">
                    <ng-container [ngTemplateOutlet]="spinnerTemplate">
                    </ng-container>
                </th>
                <ng-container *ngIf="keys">
                    <tr *ngFor="let key of keys">
                        <td>{{key}}</td>
                        <td style="white-space: pre-line;">{{gluesetsByThickness[key]}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
