<h1 mat-dialog-title>{{ 'Operative.AutoScheduler' | translate }}</h1>
<form [formGroup]="autoSchedulerForm" style="display: flex; flex-direction: column;" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div style="flex-direction: row; padding-top: 10px;">
      <kendo-stepper [steps]="optiSteps" [stepType]="stepType" [(currentStep)]="current">
        <ng-template kendoStepperLabelTemplate let-step>
          <div style="display: flex; flex-direction: column;">
            <span>{{ step.label }}</span>
            <span *ngIf="step.time">{{ step.time }} sec</span>
            <span>{{ step.state }}</span>
          </div>
        </ng-template>
      </kendo-stepper>
    </div>
    <div style="display: flex; flex-direction: row; padding-top: 10px;">
      <table class="tbl" style="flex-direction: column;">
        <thead>
          <tr>
            <th translate>AppComponent.MachineGroup</th>
            <th translate>Operative.Selection</th>
            <th translate>Operative.PreserveOrder</th>
          </tr>
        </thead>
        <tbody formArrayName="machineGroups">
          <kendo-loader *ngIf="!_machineGroupsData" size="large"></kendo-loader>
          <ng-container *ngIf="_machineGroupsData.length > 0">
          <tr *ngFor="let mg of getMachineGroups.controls let i = index" [formGroupName]="i">
            <td>
              <kendo-label>{{ mg.value['name']}}</kendo-label>
            </td>
            <td align="center">
              <kendo-formfield>
                <input formControlName="isSelected" class="k-checkbox group-check" type="checkbox"
                  [attr.disabled]="mg.value['isDisabledForAutoSheduler'] ? true : null"
                  [ngStyle]="{'background-color': mg.value['isDisabledForAutoSheduler'] ? 'grey' : null }"
                  kendoCheckBox>
              </kendo-formfield>
            </td>
            <td align="center">
              <input class="k-checkbox group-check" formControlName="preserveOrder" type="checkbox" kendoCheckBox>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      <div style="display: flex; flex-direction: column;">
        <kendo-formfield class="min-width-dropdown" appearance="fill" style="padding: 10px; padding-top: 0px !important;">
          <kendo-label translate>
            OptimizationOrderTranslation.OptimizationSequencer_Model
          </kendo-label>
          <kendo-dropdownlist style="flex-grow: 1;" [data]="watsonDeployedModels" formControlName="deployedModelId"
            [valuePrimitive]="true" [textField]="'Name'" [valueField]="'Id'">
          </kendo-dropdownlist>
        </kendo-formfield>
        <kendo-formfield style="padding: 10px;">
          <kendo-label translate>Optimization.OriginDate</kendo-label>
          <kendo-datepicker formControlName="timeOrigin"></kendo-datepicker>
        </kendo-formfield>
        <kendo-formfield style="padding: 10px;">
          <kendo-label translate>Optimization.TimeZoneOffset</kendo-label>
          <kendo-textbox formControlName="timeZoneOffset"></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield style="padding: 10px;">
          <kendo-label translate>AoT.NoOfDays</kendo-label>
          <kendo-numerictextbox format="n" formControlName="noOfDays" [min]="15" [max]="365"></kendo-numerictextbox>
        </kendo-formfield>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div></div>
    <button mat-raised-button type="submit" color="primary" [disabled]="(!autoSchedulerForm.valid  || disableSubmit)">
      {{ 'Operative.RunAutoScheduler' | translate }}
    </button>
    <button mat-raised-button mat-dialog-close (click)="close()" color="warn">
      {{ 'EntityTranslation.Cancle' | translate }}
    </button>
  </mat-dialog-actions>
</form>
