import { Component, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { InstructionService } from '@app/core/services/http-services/operative/instruction.service';
import { KendoGridSetUp } from '@app/core/services/custom-services/kendo-grid-setup';
import { IInstruction } from '@app/core/models/instruction.model';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';

marker('Operative.SaveInstructionMsg');
marker('Operative.UpdateInstructionMsg');
marker('Operative.InstructionDeleteMsg');
marker('App.AlreadyExistsMsg');
marker('Operative.CanNotDeleteInstructionIsBindToInstructionLine');

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.css']
})
export class InstructionComponent implements OnInit {
  instructions : GridDataResult;
  rowIndexN:any;
  itemToRemove: IInstruction;
  isNew = false;
  loadInstructionData = false;
  state: State = {
    skip: 0,
    take: KendoGridSetUp.pageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(public instructionService: InstructionService,
    private notificationService: AppNotificationService) { }

  ngOnInit(): void {
    this.loadInstructionData = true;
    this.loadData();
    this.loadInstructionData = false;
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.loadInstructionData = true;
    this.state = state;
    this.loadData();
    this.loadInstructionData = false;
  }

  loadData(): void {
    this.instructionService.getInstructions(this.state).subscribe(d => {
      this.instructions = d;
    });
    this.loadInstructionData = false;
  }

  editHandler({ dataItem }): void {
    this.instructionService.openDialog(dataItem, false).afterClosed().subscribe((reloadParent) => {
      if (reloadParent) {
        this.loadData();
      }
    });
  }

  addInstructionRow(): void {
    this.instructionService.openDialog(this.defaultInstruction(), true).afterClosed().subscribe((reloadParent) => {
      if (reloadParent) {
        this.loadData();
      }
    });
  }

  removeHandler({ dataItem, rowIndex }): void {
    this.itemToRemove = dataItem;
    this.rowIndexN = rowIndex;
  }

  confirmRemove(isRemove: boolean): void {
    if (isRemove) {
      this.instructionService.remove(this.itemToRemove).subscribe(() => {
        this.instructions.data.splice(this.rowIndexN, 1);
        this.loadData();
        this.notificationService.notifySucsessAppChanel('Operative.InstructionDeleteMsg');
      });
    }
    this.itemToRemove = null;
  }

  private defaultInstruction(): IInstruction {
    const newInstructionToAdd = <IInstruction>{
      instructionID: 0,
      name: '',
      code:'',
      materialPropertyCode:'',
      instructionConfigurations: []
    };
    return newInstructionToAdd;
  }
}
