import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Guid } from '@app/core/models/Guid';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { UserSiteDTO } from '@app/core/services/http-services/common/user.service';

@Component({
  selector: 'app-site-multi-select',
  templateUrl: './site-multi-select.component.html',
  styleUrls: ['./site-multi-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SiteMultiSelectComponent
    }
  ]
})
export class SiteMultiSelectComponent implements OnInit, ControlValueAccessor {
  sites: UserSiteDTO[] = [];

  private onChanged!: Function;
  private onTouched!: Function

  public selectedSiteIds: Guid[] = [];

  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.setSites();
  }

  private setSites() {
    this.sites = this.getSites();
  }

  private getSites(): UserSiteDTO[] {
    return JSON.parse(this.localStorageService.getItem(LocalStorageService.userSites)) as UserSiteDTO[];
  }

  onChangedVal(selectedSite: any) {
    if (this.onChanged) {
      this.onTouched(selectedSite);
      this.onChanged(selectedSite);
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.selectedSiteIds = value;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

}
