<h1 class="header">{{'Operative.Scheduler' | translate}}</h1>
    <div class="scheduler-top">
        <app-machine-group-selector-multi
            style="width: 600px"
            [showLable]="false"
            (selectedMachineGroup)="setSelectedMachineGroup($event)"
            [loading]="loading"></app-machine-group-selector-multi>
        <div style="flex-grow: 1;"></div>
        <div style="width: 300px">
            <app-select-demand
                [showLable]="false"
                [(ngModel)]="demandId"
                (ngModelChange)="goToSequence($event)"
                [loading]="loading"></app-select-demand>
        </div>
        <div class="auto-complete-switch">
            <kendo-switch
                [(ngModel)]="overlapPO"
                (valueChange)="onOverlapPO($event)"
                color="primary"
                [disabled]="loading"></kendo-switch>
            <kendo-label
                title="{{ 'Operative.AutoCalculateTooltip' | translate }}"
                translate>
                Operative.AutoCalculateSwitch
            </kendo-label>
        </div>
        <button
            kendoButton
            (click)="openAutoSchedulerPopup()"
            [disabled]="loading"
            appAccessControl deniedRoles="{{deniedRoles}}"
            themeColor="primary">
            {{'Operative.AutoScheduler' | translate}}
        </button>
        <div
            style="width: 100px; align-items: center;gap: 10px"
            class="row">
            <div style="flex-grow: 1;"></div>
            <kendo-loader
                *ngIf="loading"
                size="small">
            </kendo-loader>
            <button
                kendoButton
                (click)="loadSchedule()"
                themeColor="primary"
                [disabled]="loading">
                <kendo-icon name="reload"></kendo-icon>
            </button>
        </div>
    </div>
<div style="height: 80vh;">
    <mbsc-eventcalendar
        [locale]="mbscLocale"
        [data]="mbscEvents"
        [resources]="mbscResources"
        [options]="mbscOptions"
        [connections]="mbscConnections"
        [colors]="mbscColors"
        [view]="calView"
        [selectedDate]="selectedDate"
        [refDate]="refDate"
        [scheduleEventContentTemplate]="myTemplate"
        [headerTemplate]="TimeSlots"
        (onPageLoading)="onPageLoading($event)">
        <ng-template
            #myTemplate
            let-data>
            <div>
                <mat-icon
                    *ngIf="data.original.voiletesPOChain"
                    style="color:orange;font-size: 18px;">
                    warning
                </mat-icon>
                <span>{{ data.title }}</span>
            </div>
            <div>
                <span>{{data?.original?.stateText}}</span>
            </div>
        </ng-template>
        <ng-template #TimeSlots>
            <div>
                {{'Operative.SelectDates' | translate }}
            </div>
            <div style="display: flex; align-items: center;">
                <mbsc-datepicker
                    [locale]="mbscLocale"
                    [disabled]="loading"
                    [(ngModel)]="selectedDate"
                    dateFormat = "DD.MM.YYYY"
                    [showWeekNumbers]="true"
                    [min]="refDate"
                    [max]="lastDate"
                    class="datePicker"></mbsc-datepicker>
            </div>
            <div style="flex-grow: 1;"></div>
            <div style="display: flex; align-items: center;">
                <mbsc-datepicker
                    [locale]="mbscLocale"
                    [disabled]="loading"
                    [value]="firstDate"
                    dateFormat = "DD.MM.YYYY"
                    [showWeekNumbers]="true"
                    (onClose)="onFirstDayClosed($event)"
                    class="datePicker"></mbsc-datepicker>
                <span *ngIf="firstDate">
                    - {{lastDate | date }}
                </span>
                <app-mbsc-calendar-slot-selection
                    (slotTime)="changeSlotDuration($event)"
                    [disabled]="loading"></app-mbsc-calendar-slot-selection>
                <div *ngIf="!loading">
                <mbsc-calendar-prev class="md-work-week-prev"></mbsc-calendar-prev>
                <mbsc-calendar-today class="md-work-week-today"></mbsc-calendar-today>
                <mbsc-calendar-next class="md-work-week-next"></mbsc-calendar-next>
                </div>
            </div>
            <div class="mbsc-form-group">
                <mbsc-segmented-group
                    [(ngModel)]="mbscTimelineType"
                    (change)="changeTimelineType()">
                    <mbsc-segmented
                        color="secondary"
                        value="day"
                        [disabled]="loading">
                        {{ 'Operative.Day' | translate }}
                    </mbsc-segmented>
                    <mbsc-segmented
                        color="secondary"
                        value="week"
                        [disabled]="loading">
                        {{ 'Operative.Week' | translate }}
                    </mbsc-segmented>
                    <mbsc-segmented
                        color="secondary"
                        value="month"
                        [disabled]="loading">
                        {{ 'Operative.Month' | translate }}
                    </mbsc-segmented>
                </mbsc-segmented-group>
            </div>
        </ng-template>
    </mbsc-eventcalendar>
    <mbsc-popup
        [options]="editProdOrderOptions"
        [anchor]="editProdOrderAnchor"
        #popup>
        <app-event-calender-popup
            (popupCloseEvent)="popupCloseEvent($event)"
            (deleteEvent)="deleteEvent($event)"
            [selectedEvent]="mbscSelectedEvent"
            [isDeleteDisabled]="isDeleteAllowed"
            [isEditDisabled]="isEditDisabled"></app-event-calender-popup>
    </mbsc-popup>
</div>
<!-- Do not remove needed for delete dialog -->
<div kendoDialogContainer></div>
