import { Component, ViewChild } from '@angular/core';
import { DashbordCardDirective } from './dashbord-card.directive';
import { CardItemService, DashbordDef, DashbordDefs } from '../services/dashbord-card-item.service';
import { ActivatedRoute, Router } from '@angular/router';

export const LAYOUT_SETTINGS = {
  rowhight: 200,
  numberOfCols: 4
};

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.css']
})
export class DashboardLayoutComponent {
  @ViewChild(DashbordCardDirective, { static: true }) adHost: DashbordCardDirective;
  interval: any;

  def: DashbordDef;
  settings = LAYOUT_SETTINGS;

  constructor(
    public cardService: CardItemService,
    public activeRoute: ActivatedRoute,
    public router:Router) {
    activeRoute.params.subscribe(val => {
      const id = val['id'] ? +val['id'] : DashbordDefs.Generic;

      this.cardService.getCardItems(id).subscribe(def => {
        if (def) {
          this.def = def;
        } else {
          this.router.navigate(['/page-not-found']);
        }
      });
    });
  }
}
