<h1 mat-dialog-title>{{ 'Operative.DemandDetails' | translate }}</h1>
<div style="display: flex; flex-direction: row;justify-content: center;">
  <div style="display: flex; flex-direction: column;margin-right: 10px;">
  <table class="tbl tbl-row-background">
    <thead>
      <th style="text-align: center;" colspan="2" translate>Operative.DemandData</th>
    </thead>
    <tbody>
      <tr>
        <th translate>AoT.Type</th>
        <th translate>GluePlanPrint.Value</th>
      </tr>
      <tr>
        <th translate>GluePlanPrint.OrderId</th>
        <td>{{demandLine.orderID}}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.OrderRow</th>
        <td>{{demandLine.orderRowID}}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.CustomerNo</th>
        <td>{{demandLine.customerNo}}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.CustomerDesc</th>
        <td>{{demandLine.customerDesc}}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.OrderComment</th>
        <td>{{demandLine.orderComment}}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.OrderRowComment</th>
        <td>{{demandLine.orderRowComment}}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.PickingInst</th>
        <td>{{demandLine.pickingInst }}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.Qty</th>
        <td>{{demandLine.qty}} {{ demandLine.qtyUnit }}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.Width</th>
        <td>{{demandLine.width }}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.Height</th>
        <td>{{demandLine.hight }}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.Length</th>
        <td>{{demandLine.length }}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.VolumeM3</th>
        <td>{{demandLine.volumeM3}}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.Material</th>
        <td>{{demandLine.material.description}}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.LatestProductionDate</th>
        <td>{{demandLine.latestProductionDate | date:'yyyy-MM-dd' }}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.CreateDate</th>
        <td>{{demandLine.createDate | date:'yyyy-MM-dd' }}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.DemandType</th>
        <td>{{getDeamndTypeText(demandLine?.demandType) | translate}}</td>
      </tr>
      <tr>
        <th translate>OrderViewTranslation.ProdOrderCount</th>
        <td>{{demandLine.prodOrderCount }}</td>
      </tr>
    </tbody>
  </table>
  </div>
  <div style="display: flex; flex-direction: column;justify-content: space-between;">
    <div style="display: flex; flex-direction: row;">
    <table class="tbl tbl-row-background">
      <thead>
        <th style="text-align: center;" colspan="2" translate>Operative.DemandProperties</th>
      </thead>
      <tbody>
        <tr><th translate>AoT.Type</th><th translate>GluePlanPrint.Value</th></tr>
        <tr *ngFor="let property of demandLine.propertys;">
          <th>{{property.propertyCode}}</th>
          <td>{{property.displayValue}}</td>
        </tr>
      </tbody>
    </table>
    </div>
    <div style="display: flex; flex-direction: row;">
    <table class="tbl">
      <thead>
        <th style="text-align: center;" colspan="4" translate>Operative.TransportPackageAllocation</th>
        <tr>
          <th class="tr_background_grey" translate>OrderViewTranslation.PackageId</th>
          <th class="tr_background_grey" translate>OrderViewTranslation.Qty</th>
          <th class="tr_background_grey" translate>AoT.QtyReproduced</th>
          <th class="tr_background_grey" translate>OrderViewTranslation.VolumeM3</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let transportPackageAllocation of demandLine.transportPackageAllocations">
          <td>
            {{transportPackageAllocation.packageID}}
          </td>
          <td translate>
            {{transportPackageAllocation.qty}} {{ demandLine.qtyUnit }}
          </td>
          <td>
            {{transportPackageAllocation.unitsExtra}}
          </td>
          <td>
            {{transportPackageAllocation.volumeM3}}
          </td>
        </tr>
        <tr *ngIf="demandLine.transportPackageAllocations?.length < 1">
          <td colspan=6 style="text-align: center;" translate>
            Operative.NoTransportPackageAllocation
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</div>
<div style="display: flex; flex-direction: row; float: right;">
  <mat-dialog-actions align="end">
  <button
       mat-raised-button
       mat-dialog-close>
       {{ 'App.Close' | translate }}
   </button>
 </mat-dialog-actions>
</div>
