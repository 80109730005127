import { Component, Input, OnInit } from '@angular/core';
import { State } from '@progress/kendo-data-query';
import { MbscCalendarColor, MbscCalendarEvent, MbscEventcalendarOptions, MbscEventConnection, MbscResource, setOptions } from '@mobiscroll/angular';
import { ICalendarLinesCriteriaInputDTO } from '@app/core/models/CalendarLinesCriteriaInputDTO';
import { IScheduleLineDTO } from '@app/core/models/scheduleLineDTO.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { ProdOrderCalendarService } from '../services/prod-order-calendar.service';

marker('App.ShiftCalendar');
marker('App.MachineGroupCalendar');
marker('App.ScheduleCalendar');

setOptions({
  theme: 'material',
  themeVariant: 'light'
});

@Component({
  selector: 'app-shift-calendar',
  template: `
  <h1 mat-dialog-title translate>{{_label | translate}}</h1>
  <mat-dialog-content>
    <div style="height: 500px;">
      <mbsc-eventcalendar
          [data]="myEvents"
          [resources]="myResources"
          [options]="eventcalendarOptions"
          [connections]="myConnections"
          [locale]="'de'"
          [firstDay]="1"
          [colors]="colors">
      </mbsc-eventcalendar>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
    mat-raised-button
    mat-dialog-close>
    {{"App.Close" | translate }}
    </button>
  </mat-dialog-actions>
  `,
  styleUrls: ['./shift-calendar.component.css']
})
export class ShiftCalendarComponent implements OnInit {
  eventcalendarOptions: MbscEventcalendarOptions = {
    view: {
      schedule: {
        type: 'week'
      }
    },
    dragToMove: true,
    dragTimeStep: 1,
    onSelectedDateChange: (e) => {
      this.getDeviationsForWeek(new Date(e.date.toString()));
      this.mapscheduleLinesToEvent(new Date(e.date.toString()));
    }
  };

  resources: any[] = [];
  themeColors = ['#fde4c880', '#d5f1ea80', '#ffdbdb80'];
  shiftId: number[];
  myEvents: MbscCalendarEvent[] = [];
  myConnections: MbscEventConnection[] = [];
  myResources: MbscResource[] = [];
  colors: MbscCalendarColor[] = [];
  shiftColor = new Map<number, string>();

  constructor(public prodOrderCalendarService:ProdOrderCalendarService) { }

  public selectedDate: Date = new Date(Date.now());
  _lines: IScheduleLineDTO[];
  _label: string = 'App.ShiftCalendar';
  public gridState: State = {
    skip: 0,
    take: 0,
    filter: {
      logic: 'and',
      filters: [
      ]
    }
  };

  @Input() set criteria(criteria: ICalendarLinesCriteriaInputDTO) {
    this._lines = criteria.scheduleLines;
    this.shiftId = criteria.shiftIds;
  }

  @Input() set label(label: string) {
    if (label) {
      this._label = label;
    }
  }

  ngOnInit(): void {
    this.createShiftColorMap();
    this.getDeviationsForWeek(new Date());
    this.mapscheduleLinesToEvent(new Date());
  }

  createShiftColorMap() {
    const uniqueShifts = [...new Map(this._lines.map(item =>
      [item['shiftId'], item])).values()];

    for (const i in uniqueShifts) {
      this.shiftColor.set(uniqueShifts[i].shiftId, this.themeColors[i]);
    }
  }

  private mapscheduleLinesToEvent(date: Date) {
    const validScheduleLines = this.prodOrderCalendarService.getValidScheduleLines(date, this._lines);
    this.colors = validScheduleLines.map(l => <MbscCalendarColor>{
      title: l.shiftName,
      background: this.shiftColor.get(l.shiftId) ?? '#c8fdce',
      start: l.start,
      end: l.end,
      recurring: {
        repeat: 'weekly',
        weekDays: ProdOrderCalendarService.getWeekdayString(l.dayOfWeek)
      }
    });
  }

  private getDeviationsForWeek(date: Date) {
    if (this.shiftId) {
      this.prodOrderCalendarService.getDeviationsForWeek(date, this.shiftId).subscribe(events => {
        this.myEvents = [...events];
      });
    }
  }
}
