import { Component, Input, OnInit } from '@angular/core';

import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { ProdOrderSequenceReturnDTO } from '@app/core/models/prod-order';

@Component({
  selector: 'app-sequence-display',
  template: `
<button kendoButton *ngIf="!badge" (click)="onToggle()" [disabled]="!sequenceId">
    {{"Operative.LinkedProdOrder" | translate}}
    <kendo-loader *ngIf="loading && !badge"></kendo-loader>
</button>
<kendo-icon name="hyperlink" *ngIf="badge && !loading" (click)="onToggle()"></kendo-icon>
<kendo-loader *ngIf="loading && badge"></kendo-loader>

<div #anchor></div>
<kendo-popup [anchor]="anchor" (anchorViewportLeave)="showPopup = false" *ngIf="showPopup">
      <div class="content main_table" style="width: 800px;">
        <table class="tbl" style="width: 100%;">
          <tbody>
          <tr>
              <th><b>{{"GluePlanPrint.OrderNumber" | translate}}</b></th>
              <th><b>{{"OrderViewTranslation.LatestProductionDate" | translate}}</b></th>
            </tr>
            <tr>
              <td *ngIf="sequenceReturn.orderNumber">{{sequenceReturn.orderNumber}}</td>
              <td *ngIf="!sequenceReturn.orderNumber">n/a</td>
              <td *ngIf="sequenceReturn.demandLastProductionDate">{{sequenceReturn.demandLastProductionDate | siteDateFormat}}</td>
              <td *ngIf="!sequenceReturn.demandLastProductionDate">n/a</td>
            </tr>
          </tbody>
        </table>
      <div style="display: flex; flex-direction: column;">
        <table class="tbl" style="width: 100%;">
          <tbody>
          <tr>
              <th style="padding:4px ;"><b>{{"Operative.Color" | translate}}</b></th>
              <th><b>{{"App.Name" | translate}}</b></th>
              <th><b>{{"App.StartDate" | translate}}</b></th>
              <th><b>{{"App.EndDate" | translate}}</b></th>
              <th><b>{{"App.DependsOn" | translate}}</b></th>
              <th><b>{{"App.DependsOff" | translate}}</b></th>
            </tr>
            <tr *ngFor="let line of sequenceReturn.prodOrderSequence">
              <td style="text-align: center;"><div [ngStyle]="{'background-color': line.colorCode}" class="machineColor"><br/></div></td>
              <td class="tb-pad"  routerLink="/prod-order/{{line.prodOrderId}}" >{{line.prodOrderName}}</td>
              <td class="tb-pad" >{{line.poStartDate | siteDateFormat:true}}</td>
              <td class="tb-pad" >{{line.poEndDate | siteDateFormat:true}}</td>
              <td class="tb-pad" >{{line.dependsOnId}}</td>
              <td class="tb-pad" >{{line.dependsOfId}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
</kendo-popup>
  `,
  styleUrls: ['./sequence-display.component.css']
})
export class SelectProdOrderDemandComponent implements OnInit {
  public showPopup = false;

  @Input() sequenceId: string = null;
  @Input() badge: boolean = false;

  loading:boolean = false;

  sequenceReturn: ProdOrderSequenceReturnDTO;

  constructor(private poService: ProdOrderService) { }

  ngOnInit(): void {
  }

  public onToggle(): void {
    this.loading = true;
    if (this.sequenceId) {
      this.poService.getProdOrderSequence(this.sequenceId).subscribe((sequenceReturn) => {
        if (sequenceReturn) {
          this.sequenceReturn = sequenceReturn;
          this.showPopup = !this.showPopup;
        }
        this.loading = false;
      });
    }
  }
}
