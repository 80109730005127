<h1>
    {{ 'AppComponent.Admin_EntityGroup' | translate }}
</h1>
<div class="app-button-row">
    <div style="flex-grow: 1;"></div>
    <button
        mat-raised-button
        color='primary'
        [disabled]="!isCollapsed"
        (click)="onNewEntity()">
        {{'App.New' | translate}}
    </button>
</div>
<kendo-grid
     entityGroupBinding
    class="crud-grid"
    [pageable]="true"
    [sort]="state.sort"
    [sortable]="true"
    [pageSize]="state.take"
    [skip]="state.skip"
    (edit)="onEditEntity($event)"
    [resizable]="true"
    [filterable]="true"
    [filter]="state.filter"
    (dataStateChange)="dataStateChange($event)"
    [size]="'small'">
    <kendo-grid-column
        field="index"
        title="{{ 'EntityTranslation.Index' | translate }}"
        [width]="15">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
      </kendo-grid-column>
    <kendo-grid-column
        field="code"
        title="{{ 'EntityTranslation.Code' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="description"
        title="{{ 'EntityTranslation.Description' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="entityTypeCode"
        title="{{ 'EntityTranslation.EntityTypeCode' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="resourceTypeCode"
        title="{{ 'EntityTranslation.ResourceTypeCode' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="defaultBaseUnitCode"
        title="{{ 'EntityTranslation.DefaultBaseUnitCode' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="sortOrderIndex"
        title="{{ 'EntityTranslation.SortOrderIndex' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="userCode"
        title="{{ 'EntityTranslation.UserCode' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="changeDate"
        title="{{ 'EntityTranslation.ChangeDate' | translate }}"
        [width]="25"
        [filterable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div>{{  dataItem.changeDate | siteDateFormat }}</div>
        </ng-template>
      </kendo-grid-column>
    <kendo-grid-column
        field="machineGroupName"
        title="{{ 'EntityTranslation.MachineGroup' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
            <span>{{dataItem.machineGroupName}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="stockLocationProduceName"
        [format]="{ date: 'short' }"
        title="{{ 'EntityTranslation.ProduceStockLocation' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        field="stockLocationConsumeName"
        [format]="{ date: 'short' }"
        title="{{ 'EntityTranslation.ConsumeStockLocation' | translate }}"
        [width]="25">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column filter="boolean" field="inTactical" title="{{ 'EntityTranslation.Tactical'| translate  }}" [width]="25"></kendo-grid-column>
    <kendo-grid-column filter="boolean" field="inOperative" title="{{ 'EntityTranslation.Operative'| translate  }}" [width]="25"></kendo-grid-column>
    <kendo-grid-column  field="selectedEntityGroupSites" title="{{ 'App.Site'| translate  }}" [width]="30" [filterable]="false">
    </kendo-grid-column>
    <kendo-grid-command-column
        title="{{'EntityTranslation.Command' | translate}}"
        [width]="20">
        <ng-template
            kendoGridCellTemplate
            let-isNew="isNew">
            <button
                kendoGridEditCommand
                [primary]="true">
                Edit
            </button>
            <button kendoGridCancelCommand>
                {{ isNew ? 'Discard changes' : 'Cancel' }}
            </button>
        </ng-template>
    </kendo-grid-command-column>
</kendo-grid>
