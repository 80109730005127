import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { State } from '@progress/kendo-data-query';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { IAppGridDataResult } from '@app/core/models/IAppGridDataResult';
import { HandelingUnitFormComponent } from '@app/modules/stock/handeling-unit-form/handeling-unit-form.component';
import { SiteService } from '@app/core/services/http-services/app/site.service';

@Injectable({
  providedIn: 'root'
})
export abstract class HandelingUnitService extends BehaviorSubject<GridDataResult> {
  public loading: boolean;
  handelingUnitDataResult:GridDataResult = null;
  handleSearchSubscription = new Subject<IHandelingUnit[]>();
  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    private kendoGridService: KendoGridService,
    private siteService: SiteService
  ) {
    super(null);
  }

  query(state: State): void {
    this.loading = true;

    let p = this.kendoGridService.getHttpParamsFilter(state);

    p = p.set('skip', state.skip.toString())
      .set('take', state.take.toString());

    this.http.get<IAppGridDataResult<IHandelingUnit>>(`${environment.apiUrl}HandelingUnit`, { params: p })
      .pipe(map(async response => (<IAppGridDataResult<IHandelingUnit>> {
        data:  (await this.siteService.mapSiteIdsToNames(response)).data,
        total: response.total
      }))).subscribe(async result => {
        super.next(await result);
        this.handleSearchSubscription.next((await result).data);
        this.loading = false;
      });
  }

  public gethandelingunits(criteria:IHandlingUnitSearchCriteriaDTO): Observable<GridDataResult> {
    let p = new HttpParams();
    if (criteria.stockLocationId) {
      p = p.set('stockLocationId', criteria.stockLocationId);
    }
    if (criteria.isRecursive) {
      p = p.set('recursiveSL', criteria.isRecursive);
    }
    if (criteria.materialIndex) {
      p = p.set('materialIndex', criteria.materialIndex);
    }
    if (criteria.searchValue) {
      p = p.set('identifier', criteria.searchValue);
    }

    p = p.set('skip', 0).set('take', 10);

    return this.http.get<GridDataResult>(`${environment.apiUrl}HandelingUnit`, { params: p })
      .pipe(tap(m => {
        this.handelingUnitDataResult = m;
      }));
  }

  public get(id:number): Observable<IHandelingUnit> {
    console.log(id);
    const handelingunitUri = `${environment.apiUrl}HandelingUnit/${id}`;
    return this.http.get<IHandelingUnit>(`${handelingunitUri}`);
  }

  openDialog(quantityReport:IHandelingUnit) {
    this.dialog.open(HandelingUnitFormComponent, { disableClose: false, data: { id: quantityReport.handelingUnitId } });
  }

  public async getHandelingUnitReport(): Promise<Blob> {
    return await this.http.get<Blob>(`${environment.apiUrl}HandelingUnit/getHandelingUnitReport`, { responseType: 'blob' as 'json' }).toPromise();
  }
}

export interface IHandelingUnit {
  currentStockLocationId: number | null;
  handelingUnitId: number | null;
  qty: number;
  qtyUnitCode: number;
  stockLocationName: string;
  quantityReports: IQuantityReport[];
  identifiers: IIdentifier[];
  currentStockLocation:string;
  materialName:string | null;
  inStock:boolean;
}

export interface IIdentifier {
  handelingUnitIdentifierId: number;
  assignedBy: string;
  identifier: string;
  handelingUnitId: number;
}

export interface IQuantityReport {
  handelingUnitId: number;
  handelingUnitReportId: number;
  isActive: boolean;
  isPartioal: boolean;
  qty: number;
  qtyUnit: string;
  stockLocationId: number | null;
  stockTransTypeId: number;
  stockLocationName: string;
  stockTransTypeName: string;
  eventDate: string;
  reportDate: string;
  changeUser: string;
  handelingUnitPropValues: IPropVal[];
}

export interface IPropVal {
  propCode: string;
  handelingUnitId: number;
  valueInt: number;
  valueDecimal: number;
  valueString: string;
  valueDateTime: string;
  handelingReportId: number;
}

export interface IHandlingUnitSearchCriteriaDTO {
  materialIndex: number;
  stockLocationId: number;
  searchValue:string;
  isRecursive:boolean;
  inStock:boolean;
}
