import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { OrderView } from '../controls/order-view/order-view.component';
import {
  BeamMaterialGlueSet,
  BeamMaterialGlueSetLayer,
  BeamMaterialGlueSetState,
  IBeamMaterialGlueSets,
  ProposalState
} from '../../../core/models/beam-material-glue-set.model';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { GluingPlan } from '../../../core/models/gluing-plans.model';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { Demandline } from '@app/core/models/demand-line.model';
import { OptiInputModel, OptimizationResponce } from '@app/core/models/optimization-order.model';
import { DeamndService, UpdateSumUIQtyDTO } from '@app/core/services/http-services/gluelam/demand.service';
import { BeamLamminaDimension } from '../../../core/models/beam-lammina-dimension.model';
import { OptimazationOrderEditor } from '../controls/optimization-order/optimization-order.component';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';

marker('GlueSet.GLUSET_SAVE_ERROR');
marker('GlueSet.GLUSET_UPDATED');
marker('GlueSet.BMGS_NOT_Removed');
marker('GlueSet.GLUSET_SORTED_INFO');
marker('GlueSet.BMGS_Removed');

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'glulam-planner-component',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host:{
    class :"d-flex-col-component"
  }
})
export class PlannerComponent implements OnInit, OnDestroy {
  @ViewChild(OrderView) orderView: OrderView;
  isSavingGlueset: boolean = false;
  selectedPlan: GluingPlan;
  _BeamMaterialGlueSetSuggestions: BeamMaterialGlueSet[] = [];
  uiQty: UpdateSumUIQtyDTO[] = [];

  public get BeamMaterialGlueSetSuggestions(): BeamMaterialGlueSet[] {
    if (this.selectedState && this.selectedState.name !== 'All') {
      return this._BeamMaterialGlueSetSuggestions.filter((x) => {
        return (
          x.beamMaterialGlueSetState.glueSetStateId ===
          this.selectedState.glueSetStateId
        );
      });
    }
    return this._BeamMaterialGlueSetSuggestions;
  }

  public set BeamMaterialGlueSetSuggestions(value: BeamMaterialGlueSet[]) {
    this._BeamMaterialGlueSetSuggestions = value;
  }

  states: BeamMaterialGlueSetState[] = [];
  selectedState: BeamMaterialGlueSetState;
  /**
   * All beam ordered lines. These are sent to order-row-filter view, which filters them and then set to app-order-view.
   */

  LatestDeploymentDate: Date;

  LamminaDimms: BeamLamminaDimension[] = new Array<BeamLamminaDimension>();

  ready = false;
  /**
   * selected Lammina dimentions
   */
  selectedLamminaDim: BeamLamminaDimension;

  selectedBeamOrderLines: Demandline[] = [];

  public gsCols = 5;

  interval: any;
  public isLeftMajor: boolean = false;
  public updatedTotalBeamCount: number = 0;
  public isNewGluesetsCreatedByOptimization: boolean = false;
  private subscribeRouthParams: any;
  private destroy$: Subject<void> = new Subject<void>();
  public glueSetsIdInDB: number[] = [];

  constructor(
    public notificationService: AppNotificationService,
    public bmgsService: GlueSetService,
    public dimensionService: DimensionService,
    public beamOrderRowService: DeamndService,
    public translate: TranslateService,
    public conf: ConfigurationService,
    public dialog: MatDialog,
    public gluingPlanService: GluePlanService,
    public route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.subscribeRouthParams = this.route.params.subscribe(params => {
      if (params.solutionId && params.gluePlanId) {
        const solutionId = params.solutionId; // (+) converts string 'id' to a number
        const gluePlanId = params.gluePlanId;

        this.notificationService.notifySucsessAppChanel(`Loading Result for Soulution ${solutionId} into glueplan ${gluePlanId}`, 'Optimazation Soulution Loaded!');
      }
    });
  }

  removeOrder(order: any) {
    this.beamOrderRowService.unselectOrderRow(order);
  }

  ngOnInit(): void {
    this.conf.load().then(() => this.SetUpSubscriptions());
  }

  SetUpSubscriptions() {
    this.beamOrderRowService.selectedOrderRows.pipe(takeUntil(this.destroy$)).subscribe((rows) => {
      this.selectedBeamOrderLines = rows;
    });

    this.gluingPlanService.selectedPlan.pipe(takeUntil(this.destroy$)).subscribe((plan: GluingPlan) => {
      this.spinner.hide();
      this.selectedPlan = plan;
      this.glueSetsIdInDB = [];
      this.selectedLamminaDim = plan.dimension;
      this.GetSavedGlueSetsForGluingPlan(this.selectedPlan.gluingPlanID);
    });
  }

  public onCalcTotalsPerBeamOrderRow() {
    const updateList: UpdateSumUIQtyDTO[] = [];

    this.BeamMaterialGlueSetSuggestions.filter(g => g.beamMaterialGlueSetState.glueSetStateId < 2).forEach((bmgs) => {
      bmgs.layers.forEach((l) => {
        l.BeamMaterials.forEach((bm) => {
          bm.beamMaterialLines.forEach((bml) => {
            const u = updateList.find(g => g.demandId === bml.beamOrderRowID && bml.transportPackageId === g.transportPackageId);

            if (u) {
              u.newQty += bml.NumberOfBeams * bmgs.performedTimes;
            } else {
              updateList.push(<UpdateSumUIQtyDTO>{
                demandId: bml.beamOrderRowID,
                transportPackageId: bml.transportPackageId,
                newQty: bml.NumberOfBeams * bmgs.performedTimes,
                gluePlanId: this.selectedPlan.gluingPlanID
              });
            }
          });
        });
      });
    });

    this.uiQty = updateList;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private populateState() {
    this.states = [];
    const allState = new BeamMaterialGlueSetState(
      -1,
      'All',
      'All PlaceHolder',
      false,
      []
    );
    this.states.push(allState);

    this.BeamMaterialGlueSetSuggestions.forEach((bmgs) => {
      if (
        !this.states.find((x) => x.name === bmgs.beamMaterialGlueSetState.name)
      ) {
        this.states.push(bmgs.beamMaterialGlueSetState);
      }
    });

    this.selectedState = allState;
  }

  AddEmptyBmgs() {
    const bmgsl = new BeamMaterialGlueSetLayer([]);

    const bmgs = new BeamMaterialGlueSet(
      -1,
      null,
      0,
      false,
      [bmgsl],
      0,
      0,
      null,
      true,
      this.selectedLamminaDim,
      null,
      false,
      false,
      this.conf,
      null,
      ProposalState,
      1
    );

    this.BeamMaterialGlueSetSuggestions.unshift(bmgs);
    this.populateState();
  }

  openOptimizationEditor() {
    const dialogRef = this.dialog.open(OptimazationOrderEditor, {
      height: '1000px',
      width: '700px',
      data: <OptiInputModel>{
        BeamLamminaDimension: this.selectedLamminaDim,
        BeamOrderLines: this.selectedBeamOrderLines
      }
    });

    dialogRef.afterClosed().subscribe((result: OptimizationResponce) => {
      if (result) {
      this.isNewGluesetsCreatedByOptimization = result.beamMaterialGlueSets.length > 0;
      result.beamMaterialGlueSets.forEach((element: BeamMaterialGlueSet) => {
        element.beamMaterialGlueSetState = ProposalState;
      });

      this.BeamMaterialGlueSetSuggestions = result.beamMaterialGlueSets.concat(
        this.BeamMaterialGlueSetSuggestions.filter(
          (bmgs) => bmgs.beamMaterialGlueSetID > 0
        )
      );

      this.populateState();
      this.onCalcTotalsPerBeamOrderRow();
      }
    });
  }

  GetSavedGlueSetsForGluingPlan(id: number) {
    this.spinner.show();
    this.BeamMaterialGlueSetSuggestions = [];
    this.bmgsService.getGlueSetByGluingPlanId(id).subscribe((glueSets) => {
      if (glueSets && glueSets.length > 0) {
        this.BeamMaterialGlueSetSuggestions = glueSets;
        this.glueSetsIdInDB = [];
        glueSets.forEach(gs => {
          this.glueSetsIdInDB.push(gs.beamMaterialGlueSetID);
        });
        this.populateState();
      }
      this.onCalcTotalsPerBeamOrderRow();
    });
    this.spinner.hide();
  }

  disableSaveGluesetsButton(): boolean {
    return !this.selectedPlan ||
    this.selectedPlan?.glueSetStateId >= GlueSetState.PLANNED ||
    this.isSavingGlueset ||
    this.BeamMaterialGlueSetSuggestions.filter(gp => gp.save || gp.remove).length === 0;
  }

  saveGluesetsToGluingPlan() {
    this.isSavingGlueset = true;
    this.spinner.show();
    this.notificationService.notifyInfoAppChanel('Saving');
    const glueSets = <IBeamMaterialGlueSets> {
      glueSets: this.BeamMaterialGlueSetSuggestions.filter(gp => gp.save || gp.remove),
      existingGlueSetsId: this.glueSetsIdInDB
    };

    this.bmgsService
      .updateBeamMaterialGlueSetForGluingPlan(
        this.selectedPlan.gluingPlanID,
        glueSets
      )
      .subscribe(gpSaveResult => {
        this.isSavingGlueset = false;
        let hasError: boolean = false;
        const warnings: string[] = [];

        this.updatedTotalBeamCount = gpSaveResult.glueplanNumberOfBeams;
        gpSaveResult.glusetSaveResults.forEach(gp => {
          const bmgs = this.BeamMaterialGlueSetSuggestions.find(g => g.guid === gp.guiID);

          if (gp.isSuccsessfull && gp.errorMessage) {
            gp.warnings.forEach(element => {
              if (!warnings.includes(element)) {
                warnings.push(element);
              }
            });
            bmgs.errorMessageText = gp.errorMessage;
            bmgs.save = false;
          } else if (gp.errorMessage) {
            this.notificationService.notifyErrorAppChanel(gp.errorMessage, 'GlueSet.GLUSET_SAVE_ERROR');
            hasError = true;
            console.log(`Error Found ${gp.errorMessage}`);
            bmgs.errorMessageText = gp.errorMessage;
            bmgs.save = true;
          } else if (gp.isSuccsessfull) {
            bmgs.errorMessageText = null;
            bmgs.save = false;
          } else {
            bmgs.save = true;
          }

          bmgs.beamMaterialGlueSetID = gp.glusetID;
          if (gp.isSuccsessfull && !this.glueSetsIdInDB.includes(gp.glusetID)) {
            this.glueSetsIdInDB.push(gp.glusetID);
          }
        });

        if (!hasError) {
          this.bmgsService.isDirty = false;
          this.isNewGluesetsCreatedByOptimization = false;
          this.notificationService.notifySucsessAppChanel('GlueSet.GLUSET_UPDATED');
          this.notificationService.notifyInfoAppChanel('GlueSet.GLUSET_SORTED_INFO');
        }
        warnings.forEach(element => {
          this.notificationService.notifyWarningAppChanel(element, 'GlueSet.GLUSET_SAVE_ERROR');
        });

        this.spinner.hide();
      }, (e: HttpErrorResponse) => {
        this.spinner.hide();
        this.isSavingGlueset = false;
      });
  }

  DeleteBeamMaterialGlueSet(bmgs: BeamMaterialGlueSet, index: number) {
    this.spinner.show();
    if (!bmgs.beamMaterialGlueSetID || bmgs.beamMaterialGlueSetID === -1) {
      this.BeamMaterialGlueSetSuggestions.splice(index, 1);
      this.spinner.hide();
    } else {
      this.bmgsService.removeGlueSet(bmgs.beamMaterialGlueSetID, bmgs.guid, this.selectedPlan.gluingPlanID).subscribe(res => {
        if (res.isSuccsessfull) {
          this.updatedTotalBeamCount = res.glueplanNumberOfBeams;
          this.notificationService.notifySucsessAppChanel(
            'GlueSet.BMGS_Removed'
          );
          this.BeamMaterialGlueSetSuggestions = this.BeamMaterialGlueSetSuggestions.filter(b => b.guid !== res.guiID);
          this.glueSetsIdInDB = this.glueSetsIdInDB.filter(i => i !== bmgs.beamMaterialGlueSetID);
        } else {
          this.notificationService.notifySucsessAppChanel(
            'GlueSet.BMGS_NOT_Removed'
          );
          bmgs.errorMessageText = res.errorMessage;
        }
        this.onCalcTotalsPerBeamOrderRow();
        this.spinner.hide();
      });
    }
  }

  public resizeGrid(e: boolean) {
    this.isLeftMajor = e;
    this.gsCols = e ? 4 : 5;
  }

  onConfirmUnsavedGlueSet(result:boolean) {
    if (result) {
      this.isNewGluesetsCreatedByOptimization = false;
    }
  }
}
