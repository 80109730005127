import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { Injectable } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { IProdOrderCreate, IProdOrderCreateResult } from '@app/core/models/prod-order-create-form.model';
import { IProdOrderCreateInput } from '@app/core/models/prod-order-create-input.model';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { ProdOrderCreateV2Component } from '@app/modules/operative/prod-order-create-v2/prod-order-create-v2.component';
import { IEntityRelation } from '../model/entity.service';
import { DialogService } from '@progress/kendo-angular-dialog';

marker('Operative.ViewProdOrderwithAnchor');

@Injectable({
  providedIn: 'root'
})
export class ProdOrderCreateModelService {
  constructor(
      public dialogService: DialogService
    , private appNotificationService: AppNotificationService
    , private localStorageService: LocalStorageService) {
  }

  openDialog(prodOrderCreateInput: IProdOrderCreateInput): Promise<boolean> {
    return new Promise((resolve) => {
      const dialogRef = this.dialogService.open({
        content: ProdOrderCreateV2Component,
        maxWidth: '100vw',
        width: '95%',
        height: 'auto',
      });

      const content = dialogRef.content.instance as ProdOrderCreateV2Component;
      content.data = { input : prodOrderCreateInput };

      dialogRef.result.subscribe((d: IProdOrderCreateResult) => {
        if (d.processProdOrder) {
          if (d.prodOrder) {
            this.redirectToProdOrderCreate(d.prodOrder, d.relations);
          } else if (d.prodOrderId) {
              this.appNotificationService.notifySucsessAppChanel('Operative.ViewProdOrderwithAnchor'
                , 'Success'
                , { prodorderlink: "'" + '/prod-order/' + d.prodOrderId + "'" });

                resolve(true);
           }
        };
      });
    });
  }

  redirectToProdOrderCreate(po: IProdOrderCreate, re: IEntityRelation[] = null) {
    this.localStorageService.setItem(LocalStorageService.newPODetails, JSON.stringify(po));
    this.localStorageService.setItem(LocalStorageService.newPODetailLine, JSON.stringify(re));
    window.open('/prod-order-new', '_blank');
  }
}
