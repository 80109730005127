<h1
    mat-dialog-title
    *ngIf="isNew"
    translate>
    EntityTranslation.NewEntityProperty
</h1>
<h1
    mat-dialog-title
    *ngIf="!isNew"
    translate>
    EntityTranslation.UpdateEntityProperty
</h1>
<form
    [formGroup]="entityPropertyForm"
    (ngSubmit)="onSubmit()"
    style="height: 690px;">
    <mat-dialog-content>
        <div
            class="row"
            style="margin-top: 10px;">
            <div
                class="col"
                style="flex-grow: 1;">
                <h3 translate>EntityTranslation.Fields</h3>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.Code</kendo-label>
                    <kendo-textbox formControlName="code"></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.Description</kendo-label>
                    <kendo-textbox formControlName="description"></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.Datatype</kendo-label>
                    <kendo-dropdownlist
                        formControlName="dataType"
                        [data]="dataTypes"
                        textField="text"
                        valueField="value"
                        [valuePrimitive]="true"
                        [defaultItem]="defaultItem">
                    </kendo-dropdownlist>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label translate>EntityTranslation.AreValuesValidated</kendo-label>
                    <kendo-dropdownlist
                        formControlName="areValuesValidated"
                        [data]="areValuesValidated"
                        textField="text"
                        valueField="value"
                        [valuePrimitive]="true"
                        [defaultItem]="defaultItem">
                    </kendo-dropdownlist>
                </kendo-formfield>
                <div
                    class="row"
                    *ngIf="entityProperty.areValuesValidated">
                    <h3 translate>EntityTranslation.ValidValues</h3>
                    <div style="flex-grow: 1;"></div>
                    <a
                        mat-icon-button
                        class="margin-bottom-validatedValues"
                        color="primary"
                        (click)="addValidatedValue()"
                        title="{{ 'EntityTranslation.AddValidatedValue' | translate }}">
                        <mat-icon>add_circle</mat-icon>
                    </a>
                </div>
                <div
                    formArrayName="validValues"
                    class="column"
                    style="gap: 5px;"
                    *ngIf="entityProperty.areValuesValidated">
                    <ng-container
                        *ngFor="let vv of validValues.controls let i=index"
                        [formGroupName]="i"
                        class="row">
                        <span
                            class="row"
                            *ngIf="vv.get('isRemoved').value != true">
                            <kendo-textbox formControlName="value"></kendo-textbox>
                            <a
                                mat-icon-button
                                color="warn"
                                (click)="removeValidValue(i)"
                                title="{{'EntityTranslation.Remove' | translate}}">
                                <mat-icon>remove_circle</mat-icon>
                            </a>
                        </span>
                    </ng-container>
                </div>
            </div>
            <div
                class="col"
                style="flex-grow: 1;">
                <div
                    class="row"
                    style="align-items: center;">
                    <h3 translate>EntityTranslation.EntityGroupProperties</h3>
                    <div style="flex-grow: 1;"></div>
                    <kendo-label translate>EntityTranslation.SelectedGroup</kendo-label>
                    <kendo-dropdownlist
                        [data]="groups"
                        textField="description"
                        formControlName="selectedGroup"
                        valueField="index"
                        [valuePrimitive]="true"
                        [defaultItem]="defaultItem"
                        style="width: 300px;">
                    </kendo-dropdownlist>
                    <a
                        mat-icon-button
                        color="primary"
                        (click)="addProperty()"
                        title="{{ 'EntityTranslation.AddProperty' | translate }}">
                        <mat-icon>add_circle</mat-icon>
                    </a>
                </div>
                <span
                    *ngIf="entityGroupProperties?.errors != null"
                    class="validation-text"
                    translate>
                    EntityTranslation.EntityGroupDuplicate
                </span>
                <table formArrayName="entityGroupProperties">
                    <thead>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.EntityGroup
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.Default
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsMandatory
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsLenght
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsThickness
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsWidth
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsSpecies
                        </th>
                        <th
                            style="text-align: left;"
                            translate>
                            EntityTranslation.IsQuality
                        </th>
                        <th></th>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let lessonForm of entityGroupProperties.controls; let i = index">
                            <tr
                                [formGroup]="getAsFormGroup(lessonForm)"
                                *ngIf="!getAsFormGroup(lessonForm)?.value?.isRemoved">
                                <td>
                                    {{getEG(getAsFormGroup(lessonForm)?.value?.entityGroupIndex).description}}
                                </td>
                                <td>
                                    <kendo-textbox formControlName="defaultValue"></kendo-textbox>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isMandatory"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isLenght"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isThickness"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isWidth"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isSpecies"></kendo-switch>
                                </td>
                                <td>
                                    <kendo-switch formControlName="isQuality"></kendo-switch>
                                </td>
                                <td>
                                    <a
                                        mat-icon-button
                                        color="warn"
                                        (click)="removeProperty(i)"
                                        title="{{'EntityTranslation.Remove' | translate}}">
                                        <mat-icon>remove_circle</mat-icon>
                                    </a>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions class="row">
        <button
            mat-raised-button
            mat-dialog-close
            (click)="canclePropertyUpdate()"
            color="warn">
            {{ 'EntityTranslation.Cancle' |
  translate }}
        </button>
        <div style="flex-grow:1"></div>
        <button
            mat-raised-button
            type="submit"
            [disabled]="!entityPropertyForm?.valid"
            color="primary">
            {{  isNew ? 'App.Add' : 'App.Update' | translate }}
        </button>
    </mat-dialog-actions>
</form>
