import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { EntityService, IEntity } from '@app/core/services/http-services/model/entity.service';
import { Subscription } from 'rxjs';

marker('App.SelectEntity');

@Component({
  selector: 'app-select-entity',
  template: `
  <kendo-formfield *ngIf="showLable">
  <kendo-label class="min-width-dropdown">{{label | translate}}</kendo-label>
  <kendo-multicolumncombobox [ngClass]="entitycombobox_width_class"
                            [data]="entities"
                            textField="description"
                            valueField="index"
                            placeholder="{{'EntityTranslation.SearchEntity' | translate}}"
                            [loading]="loading"
                            [filterable]="true"
                            [(value)]="selectedEntityIndex"
                            (filterChange)="handleFilterChange($event)"
                            (selectionChange)="onSelectionChange($event)"
                            [valuePrimitive]="true"
        >
<kendo-combobox-column field="description" title="{{'EntityTranslation.Description' | translate}}" >
<ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
  <span class="white-space"> {{dataItem.description ?? 'Unknown'}} </span>
</ng-template>
</kendo-combobox-column>
<kendo-combobox-column field="entityGroup.description" title="{{'EntityTranslation.EntityGroupDescription' | translate}}" >
<ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
    <span class="white-space"> {{dataItem.entityGroup?.description ?? 'Unknown'}}  </span>
</ng-template>
</kendo-combobox-column>
</kendo-multicolumncombobox>
</kendo-formfield>

<kendo-multicolumncombobox [ngClass]="entitycombobox_width_class"
                          [data]="entities"
                          textField="description"
                          valueField="index"
                          placeholder="{{'EntityTranslation.SearchEntity' | translate}}"
                          [loading]="loading"
                          [filterable]="true"
                          [(value)]="selectedEntityIndex"
                          (filterChange)="handleFilterChange($event)"
                          (selectionChange)="onSelectionChange($event)"
                          [valuePrimitive]="true"
                          class="min-width-dropdown"
                          *ngIf="!showLable"
        >
<kendo-combobox-column field="description" title="{{'EntityTranslation.Description' | translate}}" >
<ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
  <span class="white-space"> {{dataItem.description ?? 'Unknown'}} </span>
</ng-template>
</kendo-combobox-column>
<kendo-combobox-column field="entityGroup.description" title="{{'EntityTranslation.EntityGroupDescription' | translate}}" >
<ng-template kendoMultiColumnComboBoxColumnCellTemplate let-dataItem>
        {{dataItem.entityGroup?.description ?? 'Unknown'}}
</ng-template>
</kendo-combobox-column>
</kendo-multicolumncombobox>
  `,
  styleUrls: ['./select-entity.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectEntityComponent
    }
  ]
})
export class SelectEntityComponent implements ControlValueAccessor {
  selectedEntityIndex: number = null;
  loading: boolean = false;

   /* Dynamic width class we can set from calling parent component */
  @Input() entitycombobox_width_class : string = 'entitycombobox-400';
  @Input() label: string = 'App.SelectEntity';
  @Input() showLable: boolean = false;

  @Output() selectedEntity: EventEmitter<IEntity> = new EventEmitter<IEntity>();
  @Input() entityTypeCode: string = 'RESOURCE';
  @Input() getMaterialStandardProperties: boolean = false;
  private entityTake: number = 20;
  private onChanged!: Function;
  private onTouched!: Function;

  public entities: IEntity[];
  private filterSubscription: Subscription;

  constructor(private entityService: EntityService) { }

  private getEntityByIndex(entityIndex: number) {
    this.loading = true;
    this.entityService.get(entityIndex, true)
      .subscribe(d => {
        if (d) {
          this.bindEntity([d]);
          this.onSelectionChange(d);
        }
        this.loading = false;
      }
      );
  }

  private bindEntity(entity: IEntity[]) {
    this.entities = entity;
  }

  onSelectionChange(entity: IEntity) {
    this.selectedEntityIndex = (entity != null) ? entity.index : null;
    this.selectedEntity.emit(entity);
    this.onChanged(this.selectedEntityIndex);
  }

  writeValue(value: number): void {
    this.selectedEntityIndex = value;
    if (value > 0) {
      this.getEntityByIndex(this.selectedEntityIndex);
    }
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public handleFilterChange(searchTerm: string): void {
    if (searchTerm.length >= 3) {
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
      this.loading = true;
      this.filterSubscription = this.entityService.searchEntities(this.entityTypeCode, searchTerm, this.entityTake, this.getMaterialStandardProperties)
        .subscribe(d => {
          this.entities = d;
          this.loading = false;
        }
        );
    }
  }
}
