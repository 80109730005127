<h1>{{ 'EntityTranslation.Entities' | translate }} - {{ group?.code }}</h1>
<div class="k-i-loading" *ngIf="loading" style="font-size: 64px"></div>
<div class="app-button-row">
  <app-select-entity-group
    [(ngModel)]="entitygroup"
    (entityGroup)="loadSelectedGroupEntity($event)"
    [entityTypeCode]="entityTypeCode"
  ></app-select-entity-group>

  <div style="flex-grow: 1"></div>
  <button mat-raised-button color="primary" [disabled]="!isCollapsed" (click)="onNewEntity()">
    {{ 'App.New' | translate }}
  </button>

  <div class="btnexport" *ngIf="showExportToExcel">
    <button kendoButton themeColor="primary" (click)="downloadCSV(entityTypeCode)">
      {{ 'OrderViewTranslation.ExportToExcel' | translate }}
    </button>
  </div>
</div>

<kendo-grid
  [data]="gridData | async"
  *ngIf="group"
  class="crud-grid"
  [selectable]="true"
  [kendoGridSelectBy]="'BeamOrderRowID'"
  [reorderable]="true"
  [columnMenu]="true"
  [filterable]="true"
  [filter]="state.filter"
  [skip]="state.skip"
  [sortable]="true"
  [sort]="state.sort"
  [pageSize]="state.take"
  [pageable]="true"
  (edit)="onEditEntity($event)"
  [reorderable]="true"
  (columnResize)="columnResize($event)"
  (columnReorder)="columnReorder($event)"
  [resizable]="true"
  (dataStateChange)="dataStateChange($event)"
  [loading]="entityService.loading"
  [size]="'small'"
>
  <kendo-grid-column field="index" title="{{ 'EntityTranslation.Index' | translate }}" [width]="25">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="code" title="{{ 'EntityTranslation.Code' | translate }}" [width]="25">
    <ng-template
    kendoGridFilterCellTemplate
    let-filter
    let-column="column">
    <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter">
    </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="description" title="{{ 'EntityTranslation.Description' | translate }}" [width]="25">
    <ng-template
    kendoGridFilterCellTemplate
    let-filter
    let-column="column">
    <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter">
    </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
    *ngFor="let p of group.entityGroupProperties; let i = index"
    title="{{ p.propertyCode | translate }}"
    field="{{ p.propertyCode }}"
    [sortable]="false"
    [width]="25"
  >
       <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{ getPropertyValue(dataItem, p.propertyCode) | ReplaceDotToCommaPipe}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-command-column title="{{ 'App.Action' | translate }}" [width]="20">
    <ng-template kendoGridCellTemplate let-dataItem>
      <button kendoGridEditCommand [primary]="true" (click)="onEditEntity(dataItem)">
        {{ 'App.Edit' | translate }}
      </button>
      <button *ngIf="isCuttingPattern(dataItem)" kendoButton themeColor="primary" (click)="viewPattern(dataItem)">
        {{ 'App.View' | translate }}
      </button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>

<kendo-dialog
  title="View Pattern"
  *ngIf="opened"
  (close)="closePatternDialog()"
  [minWidth]="350">
  <div [innerHTML]="svgPattern"></div>
  <kendo-dialog-actions>
    <button kendoButton (click)="closePatternDialog()" themeColor="primary">
      {{'App.Ok' | translate}}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>