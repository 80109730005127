import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { MaterialImportModule } from '@app/core/material-import.module';
import { MbscModule } from '@mobiscroll/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { QRCodeModule } from 'angularx-qrcode';
import { InstructionsComponent } from './Instructions/instructions.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DateRangeFilterCellComponent } from './filters/date-range-cell-filter-component/date-range-cell-filter.component';
import { DropDownListFilterComponent } from './filters/dropdownlist-filter-component/dropdownlist-filter.component';
import { JsonFormatterDirectiveComponent } from './json-formatter-directive/json-formatter-directive.component';
import { MachineGroupSelectorMultiComponent } from './machine-group-selector-multi/machine-group-selector-multi.component';
import { MachineSelectorMultiComponent } from './machine-selector-multi/machine-selector-multi.component';
import { MachineSelectorComponent } from './machine-selector/machine-selector.component';
import { MbscCalendarSlotSelectionComponent } from './mbsc-calendar-slot-selection/mbsc-calendar-slot-selection.component';
import { PeriodSelectorComponent } from './period-selector/period-selector.component';
import { QrCodeGeneratorComponent } from './qr-code-generator/qr-code-generator.component';
import { SelectDemandComponent } from './select-demand/select-demand.component';
import { SelectEntityGroupComponent } from './select-entity-group/select-entity-group.component';
import { SelectEntityRelationDirectionComponent } from './select-entity-relation-direction/select-entity-relation-direction.component';
import { SelectEntityStatusComponent } from './select-entity-status/select-entity-status.component';
import { SelectEntityComponent } from './select-entity/select-entity.component';
import { SelectPackageComponent } from './select-handeling-unit/select-package.component';
import { SelectPropertyComponent } from './select-property/select-property.component';
import { SelectTenantComponent } from './select-tenant/select-tenant.component';
import { SelectUnitComponent } from './select-unit/select-unit.component';
import { SelectValidValueComponent } from './select-valid-value/select-valid-value.component';
import { SiteSelectorComponent } from './site-selector/site-selector.component';
import { StockLocationSelectorComponent } from './stock-location-selector/stock-location-selector.component';
import { TransactionTypeMultiSelectComponent } from './transaction-type-multi-select/transaction-type-multi-select.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { SiteSelectorV2Component } from './site-selector-v2/site-selector-v2.component';
import { SiteMultiSelectComponent } from '@app/modules/app-controls/site-multi-select/site-multi-select.component';

@NgModule({
  declarations: [
    SelectEntityGroupComponent,
    PeriodSelectorComponent,
    MachineSelectorComponent,
    MachineSelectorMultiComponent,
    MachineGroupSelectorMultiComponent,
    SelectEntityComponent,
    SelectUnitComponent,
    SelectEntityRelationDirectionComponent,
    StockLocationSelectorComponent,
    SelectValidValueComponent,
    SelectTenantComponent,
    JsonFormatterDirectiveComponent,
    QrCodeGeneratorComponent,
    DropDownListFilterComponent,
    DateRangeFilterCellComponent,
    SelectPackageComponent,
    SelectDemandComponent,
    MachineGroupSelectorMultiComponent,
    TransactionTypeMultiSelectComponent,
    MbscCalendarSlotSelectionComponent,
    FileUploadComponent,
    InstructionsComponent,
    SelectPropertyComponent,
    SelectEntityStatusComponent,
    SiteSelectorComponent,
    SiteSelectorV2Component,
    SiteMultiSelectComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    MaterialImportModule,
    ReactiveFormsModule,
    KendoImportModule,
    QRCodeModule,
    MbscModule,
    NgxJsonViewerModule
  ],
  exports: [
    SelectEntityGroupComponent,
    PeriodSelectorComponent,
    MachineSelectorComponent,
    MachineSelectorMultiComponent,
    MachineGroupSelectorMultiComponent,
    StockLocationSelectorComponent,
    SelectEntityComponent,
    SelectUnitComponent,
    SelectEntityRelationDirectionComponent,
    SelectValidValueComponent,
    SelectTenantComponent,
    JsonFormatterDirectiveComponent,
    DropDownListFilterComponent,
    DateRangeFilterCellComponent,
    SelectPackageComponent,
    SelectDemandComponent,
    TransactionTypeMultiSelectComponent,
    MbscCalendarSlotSelectionComponent,
    FileUploadComponent,
    InstructionsComponent,
    SelectPropertyComponent,
    SelectEntityStatusComponent,
    SiteSelectorComponent,
    SiteSelectorV2Component,
    SiteMultiSelectComponent
  ]
})
export class AppControlsModule { }
