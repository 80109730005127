import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { BeamMaterialGlueSet } from '@app/core/models/beam-material-glue-set.model';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IGluingPlanValidationDTO } from '@app/core/models/gluing-plan-validation-model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { Injectable } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('GlueSet.WARNING_GLUEPLAN_LENGTH_EXCEEDED_MAX_PRESSBED_LENGTH_API');
marker('GlueSet.WARNING_GLUEPLAN_LENGTH_EXCEEDED_MAX_PRESSBED_LENGTH');
marker('GlueSet.WARNING_INVALID_EDGE_OFFSET');
marker('GlueSet.CAN_NOT_ADD_EMPTY_GLUE_SET_LAYERS');

@Injectable()
export class GluelamStaticalMediatorService {
  private glueplanOffsetChangedSubject$ = new BehaviorSubject<GluingPlan>(null);
  public glueplanOffsetChanged:Observable<GluingPlan>;
  public notifyOnGluePlanOffsetChanged(gluePlan: GluingPlan): void {
    this.glueplanOffsetChangedSubject$.next(gluePlan);
  }

  private isGlueplanSaveAllChangesValidSubject$ = new BehaviorSubject<boolean>(false);
  public isGlueplanSaveAllChangesValid:Observable<boolean>;
  public notifyOnIsGlueplanSaveAllChangesValidChanged(isValid: boolean): void {
    this.isGlueplanSaveAllChangesValidSubject$.next(isValid);
  }

  public saveGlueplanwithGlueSetSubject$ = new Subject();
  public notifyOnGluePlanSave(): void {
    this.saveGlueplanwithGlueSetSubject$.next(true);
  }

  private glueSetUpdateSubject$ = new BehaviorSubject<GluingPlan>(null);
  public glueSetUpdate:Observable<GluingPlan>;
  public notifyOnGlueSetUpdate(gluePlan: GluingPlan): void {
    this.glueSetUpdateSubject$.next(gluePlan);
  }

  private glueplanRamConfigurationSubject$ = new BehaviorSubject<IPressbedRamConfigurationDTO[]>(null);
  public glueplanRamConfigurationChange: Observable<IPressbedRamConfigurationDTO[]>;
  public notifyGlueplanRamConfigurationChange(ramConfiguration: IPressbedRamConfigurationDTO[]): void {
    this.glueplanRamConfigurationSubject$.next(ramConfiguration);
  }

  public validateLenghtOffset(lenghtOffset : number, length : number, machine:IMachineDTO): IGluingPlanValidationDTO[] {
    const ret: IGluingPlanValidationDTO[] = [];
    if (machine !== null && Number(lenghtOffset + (length ?? 0) - machine.defaultCutOffLength) > machine.maxPressLength) {
      ret.push({
        text: 'GlueSet.WARNING_GLUEPLAN_LENGTH_EXCEEDED_MAX_PRESSBED_LENGTH',
        values: { length, maxLength: machine?.maxPressLength }
      });
    }
    return ret;
  }

  public gluesets: BeamMaterialGlueSet[] = [];

  constructor() {
    this.glueplanOffsetChanged = this.glueplanOffsetChangedSubject$.asObservable();
    this.glueSetUpdate = this.glueSetUpdateSubject$.asObservable();
    this.isGlueplanSaveAllChangesValid = this.isGlueplanSaveAllChangesValidSubject$.asObservable();
    this.glueplanRamConfigurationChange = this.glueplanRamConfigurationSubject$.asObservable();
  }
}
