import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { UserSiteDTO } from '@app/core/services/http-services/common/user.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.Site');

@Component({
  selector: 'app-site-selector-v2',
  template: `
  <kendo-formfield *ngIf="showLable">
  <kendo-label translate>{{label}}</kendo-label>
  <kendo-dropdownlist
    [data]="sites"
    textField="siteName"
    valueField="siteId"
    [loading]="loading"
    [valuePrimitive]="true"
    [(value)]="selectedSiteId"
    [defaultItem]="defaultItem"
    (selectionChange)="onChangedVal($event)"
    class="width"
  >
  </kendo-dropdownlist>
</kendo-formfield>

<kendo-dropdownlist *ngIf="!showLable"
  [data]="sites"
  textField="siteName"
  valueField="siteId"
  [loading]="loading"
  [valuePrimitive]="true"
  [(value)]="selectedSiteId"
  (selectionChange)="onChangedVal($event)"
  class="width"
>
</kendo-dropdownlist>`,
  styleUrls: ['./site-selector-v2.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SiteSelectorV2Component,
      multi: true
    }
  ]
})
export class SiteSelectorV2Component implements OnInit, ControlValueAccessor {
  @Input() label: string = 'App.Site';
  @Input() showLable: boolean = false;
  @Input() allowEmply: boolean = false;
  @Output() onSiteSelected = new EventEmitter<UserSiteDTO>();
  private onChanged!: Function;
  private onTouched!: Function;

  selectedSiteId: string;
  sites: UserSiteDTO[] = [];
  loading: boolean = true;

  defaultItem: UserSiteDTO = <UserSiteDTO>{ siteId: null, siteName: 'App.Empty' };

  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit(): void {
    this.setSites();
  }

  writeValue(selectedSiteId: string): void {
    this.selectedSiteId = selectedSiteId;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  private setSites() {
    this.sites = this.getSites();
    this.loading = false;
  }

  private getSites(): UserSiteDTO[] {
    return JSON.parse(this.localStorageService.getItem(LocalStorageService.userSites)) as UserSiteDTO[];
  }

  onChangedVal(selectedSite: UserSiteDTO) {
    this.setSelectedSite(selectedSite.siteId);
    this.onSiteSelected.emit(selectedSite);
  }

  private setSelectedSite(siteId: string) {
    this.selectedSiteId = siteId;
    this.onTouched(siteId);
    this.onChanged(siteId);
  }
}
