import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from '@app/core/services/auth-service/auth.service';
import { OptinexUserDTO, UserService } from '@app/core/services/http-services/common/user.service';
@Component({
  selector: 'user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  public user: OptinexUserDTO;

  public constructor(public titleService: Title, public adalSvc: AuthService, public config: UserService) { }

  public setTitle() {
  }

  ngOnInit(): void {
    this.config.getUser().then(u => {
      this.user = u;
    });
  }
}
