import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InstructionService } from '@app/core/services/http-services/operative/instruction.service';
import { IInstruction } from '@app/core/models/instruction.model';

@Component({
  selector: 'app-intsructions',
  templateUrl: './instructions.component.html',
  styleUrls: ['./instructions.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InstructionsComponent
    }
  ]
})
export class InstructionsComponent implements OnInit, ControlValueAccessor, OnDestroy {
  instructions: IInstruction[] = [];
  selectedInstructionID:number = null;
  @Input() showLabel: boolean = true;
  @Input() initiatePageLoadEvent: boolean = true;
  @Output() selectedInstruction : EventEmitter<IInstruction> = new EventEmitter<IInstruction>();

  private onTouched!: Function;
  private onChanged!: Function;
  constructor(private instructionService: InstructionService) { }

  ngOnInit(): void {
    if (this.initiatePageLoadEvent) {
      this.loadInstruction();
    }
  }

  ngOnDestroy() {
  }

  private loadInstruction() {
    this.instructionService.query().subscribe(d => {
      this.instructions = d;
    });
  }

  onChangedVal(instruction:IInstruction) {
    this.onTouched(this.selectedInstructionID);
    this.selectedInstructionID = instruction?.instructionID;
    this.selectedInstruction.emit(instruction);
    this.onChanged(this.selectedInstructionID);
  }

  writeValue(instructionId: number): void {
    this.selectedInstructionID = instructionId;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
}
