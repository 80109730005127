<h1 class="header">
  {{ 'Optimization.Sequencer' | translate }}
</h1>
<section>
  <form class="form" [formGroup]="sequencerForm" (ngSubmit)="optimize()" class="col">
    <div class="row">
      <div class="col">
        <kendo-formfield class="w-100" appearance="fill">
          <kendo-label translate> OptimizationOrderTranslation.OptimizationSequencer_Model </kendo-label>
          <kendo-dropdownlist
            style="flex-grow: 1"
            [data]="watsonDeployedModels"
            formControlName="deployedModelId"
            [valuePrimitive]="true"
            [textField]="'Name'"
            [valueField]="'Id'"
          >
          </kendo-dropdownlist>
        </kendo-formfield>
      </div>
      <div class="col">
        <kendo-formfield class="w-100">
          <kendo-label translate>Optimization.OriginDate</kendo-label>
          <kendo-datepicker
            formControlName="timeOrigin"
            [value]="sequencerForm.get('timeOrigin').value | siteDateFormat"
          ></kendo-datepicker>
        </kendo-formfield>
      </div>
      <div>
        <div class="col">
          <kendo-formfield class="w-100">
            <kendo-label translate>Optimization.TimeZoneOffset</kendo-label>
            <kendo-textbox formControlName="timeZoneOffset"></kendo-textbox>
          </kendo-formfield>
        </div>
      </div>
      <div class="col p-10">
        <div>
          <kendo-formfield class="w-100">
            <kendo-label translate>Optimization.WeightFactorLPD</kendo-label>
            <kendo-slider
              [min]="min"
              [max]="max"
              [largeStep]="step"
              [title]="title"
              formControlName="wf_lpd"
            ></kendo-slider>
          </kendo-formfield>
        </div>
        <div class="pt-20">
          <kendo-formhint>
            <mat-icon
              class="icon"
              matTooltip="{{ 'Optimization.WeightFactorLPDHint' | translate }}"
              matTooltipPosition="right"
            >
              info
            </mat-icon>
          </kendo-formhint>
        </div>
      </div>
      <div class="col p-10">
        <div>
          <kendo-formfield class="w-100">
            <kendo-label translate>Optimization.WeightFactorStartEnd</kendo-label>
            <kendo-slider
              [min]="min"
              [max]="max"
              [largeStep]="step"
              [title]="title"
              formControlName="wf_avg_start_end"
            ></kendo-slider>
          </kendo-formfield>
        </div>
        <div class="pt-20">
          <kendo-formhint>
            <mat-icon
              class="icon"
              matTooltip="{{ 'Optimization.WeightFactorStartEndHint' | translate }}"
              matTooltipPosition="right"
            >
              info
            </mat-icon>
          </kendo-formhint>
        </div>
      </div>
      <div class="col p-10">
        <div>
          <kendo-formfield class="w-100">
            <kendo-label translate>Optimization.WeightFactorBatch</kendo-label>
            <kendo-slider
              [min]="min"
              [max]="max"
              [largeStep]="step"
              [title]="title"
              formControlName="wf_batch"
            ></kendo-slider>
          </kendo-formfield>
        </div>
        <div class="pt-20">
          <kendo-formhint>
            <mat-icon
              class="icon"
              matTooltip="{{ 'Optimization.WeightFactorBatchHint' | translate }}"
              matTooltipPosition="right"
            >
              info
            </mat-icon>
          </kendo-formhint>
        </div>
      </div>
      <div class="col p-10">
        <button mat-raised-button color="primary" type="submit" [disabled]="!sequencerForm.valid || isDisabled">
          {{ 'Optimization.Optimize' | translate }}
        </button>
      </div>
      <div class="col">
        <label>{{ message | translate }}</label>
      </div>
    </div>
  </form>
</section>
