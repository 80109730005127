<h1 mat-dialog-title>{{ 'Operative.Demand' | translate }}</h1>
<form
    [formGroup]="demandForm"
    class="flex-display flex-direction">
    <mat-dialog-content class="col">
        <div
            class="row flex-grow-1">
            <app-site-selector-v2
                [showLable]="true"
                formControlName="siteId">
            </app-site-selector-v2>
            <app-select-entity
                formControlName="materialIndex"
                [entityTypeCode]="'RESOURCE'"
                [showLable]="true"
                [getMaterialStandardProperties]="true"
                [label]="'Operative.SelectMaterial'"
                (selectedEntity)="setSelectedMaterial($event)">
            </app-select-entity>
        </div>
        <div
            class="row flex-grow-1">
            <kendo-formfield class="flex-grow-4"
                appearance="standard">
                <kendo-label translate>Operative.NewQty</kendo-label>
                <kendo-numerictextbox
                    formControlName="qty"></kendo-numerictextbox>
            </kendo-formfield>
            <app-select-unit
                formControlName="qtyUnit"
                [showLable]="true"
                [label]="'Operative.UnitCode'"
                class="flex-grow-1">
            </app-select-unit>
        </div>
        <kendo-formfield>
            <kendo-label>{{ 'CLT.LPD' | translate }}</kendo-label>
            <kendo-datepicker
                formControlName="latestProductionDate"
                name="latestProductionDate"
                required></kendo-datepicker>
        </kendo-formfield>
        <div class="row flex-grow-1">
          <kendo-formfield
          appearance="standard"
          showErrors="initial"
          class="flex-grow-1">
          <kendo-label translate>OrderViewTranslation.Length</kendo-label>
          <kendo-textbox formControlName="length"></kendo-textbox>
      </kendo-formfield>
      <kendo-formfield appearance="standard" class="flex-grow-1">
          <kendo-label translate>OrderViewTranslation.Height</kendo-label>
          <kendo-textbox formControlName="hight"></kendo-textbox>
      </kendo-formfield>
      <kendo-formfield appearance="standard" class="flex-grow-1">
          <kendo-label translate>OrderViewTranslation.Width</kendo-label>
          <kendo-textbox formControlName="width"></kendo-textbox>
      </kendo-formfield>
      </div>

        <kendo-formfield appearance="standard">
            <kendo-label translate>OrderViewTranslation.Species</kendo-label>
            <kendo-textbox formControlName="species"></kendo-textbox>
        </kendo-formfield>
        <div class="row flex-grow-1">
          <kendo-formfield appearance="standard" class="flex-grow-1">
            <kendo-label translate>OrderViewTranslation.IsSpecialFormBeam</kendo-label>
            <kendo-switch formControlName="isSpecialFormBeam" color="primary" class="margin-top"></kendo-switch>
          </kendo-formfield>
          <kendo-formfield appearance="standard" class="flex-grow-2">
            <kendo-label translate>OrderViewTranslation.SpecialFormName</kendo-label>
            <kendo-textbox formControlName="specialFormName"></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield appearance="standard" class="flex-grow-2">
          <kendo-label translate>OrderViewTranslation.Radius</kendo-label>
          <kendo-textbox formControlName="radius"></kendo-textbox>
      </kendo-formfield>
        </div>
        <div class="row">
          <kendo-formfield appearance="standard" class="flex-grow-3">
            <kendo-label translate>GluePlanPrint.OrderId</kendo-label>
            <kendo-textbox formControlName="orderID"></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield appearance="standard" class="flex-grow-3">
            <kendo-label translate>OrderViewTranslation.OrderRow</kendo-label>
            <kendo-textbox formControlName="orderRowID"></kendo-textbox>
        </kendo-formfield>
        </div>
        <div class="row flex-grow-1 gap">
          <kendo-formfield class="flex-grow-4">
            <kendo-label text="{{ 'OrderViewTranslation.DemandType' | translate }}"></kendo-label>
            <kendo-dropdownlist [data]="demandTypes" valueField="value" textField="text"
              formControlName="demandType" [valuePrimitive]="true">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                {{ dataItem.text | translate}}
              </ng-template>
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                {{ dataItem.text | translate}}
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
          <kendo-formfield appearance="standard" class="flex-grow-1">
            <kendo-label translate>Stock.IsActive</kendo-label>
            <kendo-switch formControlName="isActive" color="primary" class="margin-top"></kendo-switch>
          </kendo-formfield>
        </div>
        <div class="row">
          <kendo-formfield class="flex-grow-5">
            <kendo-label text="{{ 'OrderViewTranslation.PlanningType' | translate }}"></kendo-label>
            <kendo-dropdownlist [data]="planningTypes" valueField="value" textField="text"
              formControlName="planningType" [valuePrimitive]="true">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                {{ dataItem.text | translate}}
              </ng-template>
              <ng-template kendoDropDownListItemTemplate let-dataItem>
                {{ dataItem.text | translate}}
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
        </div>

        <div>
            <b translate>Operative.ProdOrder</b>
        </div>

        <kendo-formfield>
            <kendo-label translate>Operative.Instruction        <mat-icon
              class="icon"
              matTooltip="{{'Operative.InstructionInfoTooltip' | translate}}"
              matTooltipPosition="right">
              info
          </mat-icon></kendo-label>
            <kendo-textarea
                formControlName="instruction"
                [rows]="3"></kendo-textarea>
        </kendo-formfield>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
            mat-raised-button
            mat-dialog-close
            (click)="onCancel()"
            color="warn">
            {{ 'EntityTranslation.Cancle' | translate }}
        </button>
        <div class="flex-grow-1"></div>
        <button
            mat-raised-button
            (click)="onDemandPOSequence()"
            [disabled]="(!demandForm.valid || disableSubmit)"
            color="primary">
            {{ 'Operative.AddDemandGeneratePO' | translate }}
        </button>
        <button
            mat-raised-button
            (click)="onSubmit()"
            [disabled]="(!demandForm.valid || disableSubmit)"
            color="primary">
            {{ 'App.Add' | translate }}
        </button>
    </mat-dialog-actions>
</form>
