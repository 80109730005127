import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from '../../../../../environments/environment';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { State } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { StateCategory } from '@app/core/models/state-category-enum';

export abstract class BeamMaterialLinesBaseService extends BehaviorSubject<GridDataResult> {
  public loading: boolean;
  constructor(
    conf: ConfigurationService,
    public http: HttpClient,
    notificationService: AppNotificationService
  ) {
    super(null);
  }

  query(state: State): void {
    this.loading = true;

    let p = new HttpParams();

    const beam: any = state.filter.filters.find(x => x['field'] === 'beamMaterialLineID');
    const glueSet: any = state.filter.filters.find(x => x['field'] === 'glueSetId');
    const plan: any = state.filter.filters.find(x => x['field'] === 'gluePlanId');
    const order: any = state.filter.filters.find(x => x['field'] === 'orderNo');
    const orderRow: any = state.filter.filters.find(x => x['field'] === 'orderRowNo');
    const transportPackageId: any = state.filter.filters.find(x => x['field'] === 'transportPackageId');
    const customerDesc: any = state.filter.filters.find(x => x['field'] === 'customerDesc');
    const mainProduct: any = state.filter.filters.find(x => x['field'] === 'mainProduct');
    const quality: any = state.filter.filters.find(x => x['field'] === 'quality');
    const constructionCertification: any = state.filter.filters.find(x => x['field'] === 'constructionCertification');
    const width: any = state.filter.filters.find(x => x['field'] === 'width');
    const height: any = state.filter.filters.find(x => x['field'] === 'height');
    const length: any = state.filter.filters.find(x => x['field'] === 'length');
    const stateCode: any = state.filter.filters.find(x => x['field'] === 'stateId');
    // beamId = beam?.value;
    if (beam) {
      p = p.set('beamId', beam.value);
    }
    if (glueSet) {
      p = p.set('glueSetId', glueSet.value);
    }
    if (plan) {
      p = p.set('planId', plan.value);
    }
    if (order) {
      p = p.set('orderNoStr', order.value);
    }
    if (orderRow) {
      p = p.set('orderRowNoStr', orderRow.value);
    }
    if (transportPackageId) {
      p = p.set('transportPackageId', transportPackageId.value);
    }
    if (customerDesc) {
      p = p.set('customerDesc', customerDesc.value);
    }
    if (mainProduct) {
      p = p.set('mainProduct', mainProduct.value);
    }
    if (quality) {
      p = p.set('quality', quality.value);
    }
    if (constructionCertification) {
      p = p.set('constructionCertification', constructionCertification.value);
    }
    if (width) {
      p = p.set('width', width.value);
    }
    if (height) {
      p = p.set('height', height.value);
    }
    if (length) {
      p = p.set('length', length.value);
    }
    if (stateCode) {
      p = p.set('stateId', stateCode.value);
    }

    p = p.set('skip', state.skip.toString()).set('take', state.take.toString());

    this.http.get<GridDataResult>(`${environment.apiUrl}Beam`, { params: p }).pipe(map(response => (<GridDataResult>{
      data: response.data,
      total: response.total
    }))).subscribe(result => {
      super.next(result);
    });
  }

  getStates():Observable<IBeamMaterialLineState[]> {
    return this.http.get<IBeamMaterialLineState[]>(`${environment.apiUrl}Beam/states`);
  }

  reject(beamState: IBeamStateReport, state) {
    this.http.post<any>(`${environment.apiUrl}Beam/productionReport`, beamState, {}).subscribe(result => {
      this.query(state);
    });
  }
}

@Injectable({
  providedIn: 'root'
})
export class BeamMaterialLinesService extends BeamMaterialLinesBaseService {
  constructor(conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService) {
    super(conf, http, notificationService);
  }
}

export interface IBeamMaterialLineInfo {
  beamMaterialID: number | null;
  beamMaterialLineID: number | null;
  beamOrderRowID: number;
  cut: number;
  indexInCut: number;
  numberOfBeams: number;
  transportPackageId: number | null;
  productionStateName: string;
  gluePlanId: number;
  glueSetId: number;
  productionStateCode: string;
  orderNo: string;
  stateID:number;
  orderRowNo: string;
  customerDesc: string;
  mainProduct: string;
  quality: string;
  constructionCertification: string;
  width: number | null;
  height: number | null;
  length: number | null;
}

export interface IGridDataResult<T> {
  data: T[];
  total: number;
}

export interface IBeamStateReport {
  beamMaterialLineId: number;
  code: string;
  force: boolean;
}

export interface IBeamMaterialLineState {
  beamMaterialLineStateID: number;
  code: string;
  name: string;
  state: StateCategory;
}
