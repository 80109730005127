import { Component, Input, OnInit } from '@angular/core';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { State } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScheduleService } from '@app/core/services/http-services/operative/schedule.service';
import { IScheduleLineDTO } from '@app/core/models/scheduleLineDTO.model';
import { ICalendarLinesCriteriaInputDTO } from '@app/core/models/CalendarLinesCriteriaInputDTO';
import { IScheduleDTO } from '@app/core/models/scheduleDTO.model';

marker('Operative.CanNotDeleteScheduleShiftIsBindToSchedule');
marker('Operative.ScheduleDeleted');

@Component({
  selector: 'app-schedule-grid',
  templateUrl: './schedule-grid.component.html',
  styleUrls: ['./schedule-grid.component.css']
})
export class ScheduleGridComponent implements OnInit {
  @Input() set refreshScheduleGrid(isRefresh: boolean) {
    if (isRefresh) {
      this.loadScheduleData();
    }
  }

  state: State = {
    skip: 0,
    take: 30,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  public scheduleData: IScheduleDTO[] = [];
  scheduleDataLoading: boolean = false;
  itemToRemoveId: number = undefined;

  constructor(private scheduleService: ScheduleService
    , private spinner: NgxSpinnerService
    , private appNotificationService: AppNotificationService) {
  }

  ngOnInit(): void {
    this.loadScheduleData();
  }

  private loadScheduleData() {
    this.scheduleService.query()
      .subscribe(d => {
        this.scheduleData = d.data;
      });
  }

  editSchedule(scheduleId: number) {
    this.scheduleService.openDialog(scheduleId)
      .afterClosed()
      .subscribe((d) => {
        if (d) {
          this.loadScheduleData();
        }
      });
  }

  removeHandler({ dataItem }): void {
    this.itemToRemoveId = dataItem.scheduleId;
  }

  confirmRemove(isRemove:boolean) {
    if (isRemove) {
      this.spinner.show('scheduleGridLoader');
      this.scheduleService.removeSchedule(this.itemToRemoveId)
        .subscribe(
          (res) => {
            this.removeSCheduleResponse(res);
          }
          , () => {
            this.spinner.hide('scheduleGridLoader');
          }
        );
    }
    this.itemToRemoveId = undefined;
  }

  private removeSCheduleResponse(response: boolean) {
    this.spinner.hide('scheduleGridLoader');
    if (response) {
      this.appNotificationService.notifySucsessAppChanel('Operative.ScheduleDeleted');
      this.loadScheduleData();
    }
  }

  openCalender(scheduleLines: IScheduleLineDTO[]) {
    const criteria = <ICalendarLinesCriteriaInputDTO> {
      scheduleLines,
      shiftIds: []
    };
    this.scheduleService.showCalender(criteria, 'App.ScheduleCalendar');
  };
}
