import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DeviceService } from '@app/core/services/http-services/operative/device.service';
import { KendoGridSetUp } from '@app/core/services/custom-services/kendo-grid-setup';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { QRCodeService } from '@app/core/services/custom-services/qr-code.service';
import { IDeviceDTO, IDeviceSearchCriteriaDTO, IDeviceQRCode } from '@app/core/models/deviceDTO';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DataStateChangeEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';

marker('Operative.DevieNameAlreadyExists');
marker('Operative.DeviceSaveSuccess');
marker('Operative.DeviceUpdateSuccess');
marker('Operative.DeviceDeleteMsg');
marker('Operative.DeviceMachineAlreadyAssignedErrMsg');
marker('Operative.QRCode');
marker('Operative.QRCodeIsAlreadyActivated');
marker('Operative.InvalidDevice');
marker('Operative.DeavtivateDeviceTitle');
marker('Operative.DeavtivateDeviceMsg');
marker('Operative.DeviceUnmappedSuccessfully');

const createDeviceForm = (d: IDeviceDTO) => new UntypedFormGroup({
  deviceId: new UntypedFormControl(d.deviceId),
  deviceName: new UntypedFormControl(d.deviceName, [Validators.required]),
  machineId: new UntypedFormControl(d.machineId, [Validators.required]),
  machineName: new UntypedFormControl(d.machine?.name),
  isActivated: new UntypedFormControl(d.isActivated)
});

@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css']
})
export class DeviceComponent implements OnInit {
  state: State = {
    skip: 0,
    take: KendoGridSetUp.pageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  deviceSearchCriteriaDTO: IDeviceSearchCriteriaDTO = { machines: [] };
  gridDeviceData : GridDataResult;
  formGroup: UntypedFormGroup;
  deviceToRemove:number = null;
  isEdit:boolean = false;
  selectedDeviceId: number = null;
  msgTitle: string = '';
  msg: string = '';

  @ViewChild('deviceEditorGrid')
  private deviceEditorGrid: GridComponent;

  private editedRowIndex: number;
  private isShowQRCode: boolean = false;

  constructor(private deviceService: DeviceService
    , private notificationService: AppNotificationService
    , private qrCodeService: QRCodeService) { }

  ngOnInit(): void { }

  get() {
    this.deviceService.get(this.state, this.deviceSearchCriteriaDTO)
      .subscribe(d => {
        this.gridDeviceData = d;
      });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.get();
  }

  addDeviceRow() {
    this.isEdit = false;
    this.closeEditor(this.deviceEditorGrid);
    this.formGroup = createDeviceForm(<IDeviceDTO> { isActivated: false });
    this.deviceEditorGrid.addRow(this.formGroup);
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.isEdit = true;
    this.closeEditor(this.deviceEditorGrid);
    this.formGroup = createDeviceForm(dataItem);
    sender.editRow(rowIndex, this.formGroup);
  }

  cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }): void {
    if (isNew) {
      const newDevice: IDeviceDTO = <IDeviceDTO> {
        deviceId: 0,
        deviceName: formGroup.get('deviceName').value,
        machineId: formGroup.get('machineId').value,
        isActivated: formGroup.get('isActivated').value
      };

      this.deviceService.insert(newDevice).subscribe(d => {
        this.get();
        sender.closeRow(rowIndex);
        this.notificationService.notifySucsessAppChanel('Operative.DeviceSaveSuccess');
      });
    } else {
      const updatedDevice: IDeviceDTO = <IDeviceDTO> {
        deviceId: formGroup.get('deviceId').value,
        deviceName: formGroup.get('deviceName').value
      };

      this.deviceService.update(updatedDevice).subscribe(d => {
        dataItem.deviceName = d.deviceName;
        sender.closeRow(rowIndex);
        this.notificationService.notifySucsessAppChanel('Operative.DeviceUpdateSuccess');
      });
    }
  }

  removeHandler({ dataItem, isNew, rowIndex }) {
    this.deviceToRemove = dataItem.deviceId;
  }

  confirmRemove(isRemove:boolean) {
    if (isRemove) {
      this.deviceService.delete(this.deviceToRemove).subscribe(() => {
        this.get();
        this.notificationService.notifyInfoAppChanel('Operative.DeviceDeleteMsg');
      });
    }
    this.deviceToRemove = null;
  }

  showQRCode(d:IDeviceDTO) {
    if (d.isActivated) {
      this.msgTitle = 'Operative.QRCode';
      this.msg = 'Operative.QRCodeIsAlreadyActivated';
      this.isShowQRCode = true;
      this.selectedDeviceId = d.deviceId;
    } else {
      const qrCodeInput: IDeviceQRCode = <IDeviceQRCode> {
        machineId: d.machineId,
        deviceName: d.deviceName,
        deviceGUID: d.deviceGUID,
        isActivated: d.isActivated,
        tenantGroup: d.tenantGroup
      };
      this.qrCodeService.openDialog(JSON.stringify(qrCodeInput))
        .afterClosed()
        .subscribe(d => {
          this.get(); // calling get() here to refresh the grid to show latest value in Activated column
        });
    }
  }

  confirmGenerateNewAuthKey(result: boolean) {
    if (result) {
      this.deviceService.generateNewAuthKey(this.selectedDeviceId)
        .subscribe(d => {
          if (d != null) {
            this.notificationService.notifySucsessAppChanel('Operative.DeviceUnmappedSuccessfully');
            this.isShowQRCode ? this.showQRCode(d) : this.get();
          } else {
            this.notificationService.notifyErrorAppChanel('Operative.InvalidDevice');
          }
          this.clear();
        });
    } else {
      this.clear();
    }
  }

  onSelectMachine(machines: IMachineDTO[]) {
    this.deviceSearchCriteriaDTO.machines = machines;
    this.get();
  }

  deactivate(d:IDeviceDTO) {
    this.msgTitle = 'Operative.DeavtivateDeviceTitle';
    this.msg = 'Operative.DeavtivateDeviceMsg';
    this.isShowQRCode = false;
    this.selectedDeviceId = d.deviceId;
  }

  private clear() {
    this.isShowQRCode = false;
    this.selectedDeviceId = null;
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
}
