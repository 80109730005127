import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { GlueHeatMapData, GlueLamSchedulerData, GlueLamSchedulerResultDTO } from '@app/core/models/glue-lam-scheduler-result-dto';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { GlueLamSchedulerService } from '@app/core/services/http-services/gluelam/glue-lam-scheduler.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { ProductionFileService } from '@app/core/services/http-services/gluelam/production-file.service';
import { IMachineGroupDTO, MachineGroupService } from '@app/core/services/http-services/operative/machine-group.service';
import { DownloadFileHelper } from '@app/shared/helpers/download-file-helper';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { SeriesClickEvent, SeriesPoint, LegendItemClickEvent } from '@progress/kendo-angular-charts';
import { ContextMenuComponent } from '@progress/kendo-angular-menu';
import { NgxSpinnerService } from 'ngx-spinner';
import { GluingPlanModalNgComponent, IGluPlanModalInput } from '../controls/gluing-plan-modal-ng/gluing-plan-modal-ng.component';
import { GluingPlan } from '@app/core/models/gluing-plans.model';

marker('Scheduler.StartDate');
marker('Scheduler.EndDate');
marker('Scheduler.TimeResolution');
marker('Scheduler.LengthResolution');

@Component({
  selector: 'app-statical-scheduler',
  templateUrl: './statical-scheduler.component.html',
  styleUrls: ['./statical-scheduler.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host:{
    class :"d-flex-col-component"
  }
})
export class StaticalSchedulerComponent implements OnInit {
  gluelamSchedulerForm: UntypedFormGroup;
  machineGroup: IMachineGroupDTO;
  timeSlots: Array<{ text: string; value: number }> = [
    { text: '1 Hour', value: 60 },
    { text: '2 Hour', value: 120 },
    { text: '3 Hour', value: 180 }
  ];

  lengthSlots: Array<{ text: string; value: number }> = [
    { text: '200', value: 200 },
    { text: '300', value: 300 },
    { text: '400', value: 400 }
  ];

  yAxisCategories: string[] = [];
  xAxisCategories: number[] = [];

  data: GlueHeatMapData[] = null;
  glueData: GlueLamSchedulerData[] = null;
  selectedGluePlan: GluingPlan = null;
  maxDate:Date;

  @ViewChild('chartmenu') chartContextMenu: ContextMenuComponent;

  constructor(private fb: UntypedFormBuilder, public machineGroupService: MachineGroupService, public glueLamSchedulerService: GlueLamSchedulerService,
    public productionFileService: ProductionFileService,
    public gluingPlanService: GluePlanService,
    public datePipe: DatePipe,
    public notificationService: AppNotificationService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    const startDate = new Date(new Date().setHours(0, 0, 0, 0));
    const endDate = this.getDate(startDate, 4);
    this.maxDate = this.getDate(startDate, 30);

    this.gluelamSchedulerForm = this.fb.group({
      machineId: [null, Validators.required],
      startDate: [startDate, Validators.required],
      endDate: [endDate, Validators.required],
      timeResolution: [60, Validators.required],
      lengthResolution: [200, Validators.required]
    });

    this.gluelamSchedulerForm.controls['startDate'].valueChanges.subscribe((date: Date) => {
      const end = this.getDate(date, 4);
      this.maxDate = this.getDate(date, 30);
      this.gluelamSchedulerForm.controls['endDate'].setValue(end);
    });

    this.machineGroupService.query(null, true, false).subscribe((machineGroup) => {
      this.machineGroup = machineGroup.data.length > 0 ? machineGroup.data[0] : null;
    });
  }

  prev() {
    const startDate = this.gluelamSchedulerForm.controls['startDate'].value;
    const endDate = this.gluelamSchedulerForm.controls['endDate'].value;
    endDate.setDate(startDate.getDate() - 1);
    startDate.setDate(startDate.getDate() - 5);
    this.gluelamSchedulerForm.controls['endDate'].setValue(endDate);
    this.gluelamSchedulerForm.controls['startDate'].setValue(startDate);
  }

  next() {
    const startDate = this.gluelamSchedulerForm.controls['startDate'].value;
    const endDate = this.gluelamSchedulerForm.controls['endDate'].value;
    startDate.setDate(endDate.getDate() + 1);
    endDate.setDate(endDate.getDate() + 5);
    this.gluelamSchedulerForm.controls['endDate'].setValue(endDate);
    this.gluelamSchedulerForm.controls['startDate'].setValue(startDate);
  }

  getDate(date:Date, noOfDays: number) : Date {
    const endDate = new Date(date);
    endDate.setDate(endDate.getDate() + noOfDays);
    return endDate;
  }

  onSubmit() {
    this.spinnerService.show('glueLamSchedulerLoader');
    this.glueLamSchedulerService.getHeatMapConfiguration(this.gluelamSchedulerForm.value)
      .subscribe((result: GlueLamSchedulerResultDTO) => {
        this.xAxisCategories = result.xAxisCategories;
        this.yAxisCategories = result.yAxisCategories;

        this.glueData = result.glueData.map(dataItem => {
          dataItem.plannedExecutionEndDate = new Date(dataItem.plannedExecutionEndDate);
          dataItem.plannedExecutionStartDate = new Date(dataItem.plannedExecutionStartDate);
          return dataItem;
        });

        this.data = result.data;

        this.spinnerService.hide('glueLamSchedulerLoader');
      });
  }

  onSeriesClick(e: SeriesClickEvent): void {
    const originalEvent = e.originalEvent;

    // originalEvent.button = 2 indicates that user has right clicked on the kendo-chart-series-item
    // originalEvent.button = 0 indicates, user has left clicked on the kendo-chart-series-item
    // here we are only allowing users to right click
    if (e.dataItem?.ids.length > 0 && originalEvent.button === 2) {
      this.gluingPlanService.getGluingPlan(e.dataItem.ids[0]).subscribe((plan) => {
        this.selectedGluePlan = plan;
        this.selectedGluePlan.plannedExecutionStartDate = new Date(plan.plannedExecutionStartDate);
        this.selectedGluePlan.plannedExecutionEndDate = new Date(plan.plannedExecutionEndDate);

        this.chartContextMenu.show({
          left: originalEvent.pageX,
          top: originalEvent.pageY
        });
      });
    }
  }

  onGluePlanEdit() {
    this.chartContextMenu.hide();
    const isNew = false;
    const ref = this.dialog.open(GluingPlanModalNgComponent, {
      width: '600px',
      data: <IGluPlanModalInput>{
        isNew,
        gluingPlan: { ...this.selectedGluePlan },
        isStaticalPressGroup: true,
        isContinuousPressGroup: false,
        isStaticalScheduler: true
      }
    });

    ref.afterClosed().subscribe((result) => {
      if (result) {
        this.notificationService.notifySucsessAppChanel('GluePlan.GluePlanUpdated');
        this.onSubmit();
      }
    });
  }

  getGluesetStateColor(stateId: Number) {
    switch (stateId) {
      case GlueSetState.RELEASED:
        return '#FE7021';
      case GlueSetState.SENT_BY_PLANNER:
      case GlueSetState.RECIVED_BY_PROD_SYSTEM:
        return '#00ffff';
      case GlueSetState.COMPLETED:
        return '#03a103';
      case GlueSetState.FAIL_TO_RECEIVE_BY_PROD_SYSTEM:
      case GlueSetState.CANCELED:
        return '#f50707';
      default:
        return '#FE7021';
    }
  }

  color = (e: SeriesPoint): string => {
    switch (e.dataItem.value) {
      case 100:
        return this.getGluesetStateColor(this.getData(e.dataItem).stateId);
      case 200:
        return '#FF0000';
      default:
        return '#ffd7b5';
    }
  };

  onLegendItemClick(e: LegendItemClickEvent): void {
    e.preventDefault();
  }

  download() {
    this.spinnerService.show('glueLamSchedulerLoader');
    this.productionFileService.productionFileForGlueplan(this.selectedGluePlan.gluingPlanID).then(b => {
      DownloadFileHelper.downloadFile(b, `GluePlan-${this.selectedGluePlan.gluingPlanID}-${new Date(Date.now()).toISOString().split('T')[0]}`);
      this.spinnerService.hide('glueLamSchedulerLoader');
    });
  }

  public dateLabelContent = (e: { value: number }): string => {
    if (e.value % 4 === 0 || e.value === 0 || e.value === this.yAxisCategories.length - 1) {
      const date = this.yAxisCategories[e.value];
      return this.datePipe.transform(new Date(date), 'dd.MM.YYYY - HH:MM').toString();
    }

    return '';
  };

  public lenghtLabelContent = (e: { value: number }): string => {
    if (this.xAxisCategories[e.value] % 2000 === 0) {
      return this.xAxisCategories[e.value].toString();
    }

    return '';
  };

  public getData(cellValue:GlueHeatMapData): GlueLamSchedulerData {
    if (cellValue?.ids?.length < 1) {
      return;
    }

    const id = cellValue.ids[0];

    return this.glueData.filter(gd => gd.id === id)[0];
  }

  public get chartHeight() {
    return Math.max(...this.data.map(o => o.y)) * 10;
  }
}
