import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GluingPlan } from '../../../../core/models/gluing-plans.model';
import { BeamLamminaDimension } from '../../../../core/models/beam-lammina-dimension.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { GlueSetState, GlueSetStateService, IBeamMaterialGlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';

marker('App.Cancel');
marker('GluePlan.COMPLETED');
marker('GluePlan.GENERATED');
marker('GluePlan.PLANNED');
marker('GluePlan.STARTED');
marker('GluePlan.SENT_BY_PLANNER');
marker('GluePlan.RECIVED_BY_PROD_SYSTEM');
marker('GluePlan.FAIL_TO_RECEIVE_BY_PROD_SYSTEM');
marker('GluePlan.PAUSED');
marker('GluePlan.CANCELED');
marker('GluePlan.RELEASED');
marker('App.ErrorEndDateLessThenStartDate');

@Component({
  selector: 'app-gluing-plan-modal-ng',
  templateUrl: './gluing-plan-modal-ng.component.html',
  styleUrls: ['./gluing-plan-modal-ng.component.css']
})
export class GluingPlanModalNgComponent implements OnInit {
  isNew: boolean = false;
  dimensions:BeamLamminaDimension[] = [];
  states:IBeamMaterialGlueSetState[] = [];
  gluingPlanForm:UntypedFormGroup = new UntypedFormGroup({});
  ramConfiguration : IPressbedRamConfigurationDTO[] = [];
  selectedMachine : IMachineDTO = null;
  defaultDimension: { width: string; beamLamminaDimensionID: number } = {
    width: 'Select All',
    beamLamminaDimensionID: null
  };

  private noOfHoursToAdd = 4;
  constructor(
    public ref: MatDialogRef<GluingPlanModalNgComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data:IGluPlanModalInput,
    public dimensionService: DimensionService,
    public glueSetStateService: GlueSetStateService,
    private gluelamStaticalMediatorService: GluelamStaticalMediatorService,
    public notificationService: AppNotificationService,
    public gluePlanService :GluePlanService) {
  }

  ngOnInit(): void {
    forkJoin({
      dimentions: this.dimensionService.getBeamLamminaDimentions(),
      gluesetStates: this.glueSetStateService.getGlusetStatesForPlan(this.data.gluingPlan.gluingPlanID)
    }).subscribe((result) => {
      this.dimensions = result.dimentions;
      this.states = result.gluesetStates.filter(s => s.isValidForGluePlan && s.canChangeToState);
    });

    this.isNew = this.data.isNew;

    this.gluingPlanForm = new UntypedFormGroup({
      gluingPlanID: new UntypedFormControl(this.data.gluingPlan.gluingPlanID),
      name: new UntypedFormControl(this.data.gluingPlan.name, [Validators.required, Validators.minLength(3)]),
      instruction: new UntypedFormControl(this.data.gluingPlan.instruction),
      plannedExecutionStartDate: new UntypedFormControl(this.data.gluingPlan?.plannedExecutionStartDate ? new Date(this.data.gluingPlan?.plannedExecutionStartDate) : new Date(), [Validators.required]),
      plannedExecutionEndDate: new UntypedFormControl(this.data.gluingPlan?.plannedExecutionEndDate ? new Date(this.data.gluingPlan?.plannedExecutionEndDate) : new Date(new Date().setHours(new Date().getHours() + this.noOfHoursToAdd)), [Validators.required]),
      glueSetStateId: new UntypedFormControl(this.data.gluingPlan.glueSetStateId, [Validators.required]),
      machineId: new UntypedFormControl(this.data.gluingPlan.machineId, [Validators.required]),
      lenghtOffset: new UntypedFormControl(this.data.gluingPlan.lenghtOffset),
      length: new UntypedFormControl(this.data.gluingPlan.length),
      beamLaminaDimentionId: new UntypedFormControl(this.data.gluingPlan.beamLaminaDimentionId)
    });

    if (this.data.isStaticalPressGroup) {
      this.gluingPlanForm.get('lenghtOffset').setValidators([Validators.required, this.lenghtOffsetValidator({ ...this.data.gluingPlan })]);
      this.gluingPlanForm.get('beamLaminaDimentionId').setValue(null);
      this.gluingPlanForm.get('length').disable();
      if (this.data.isStaticalScheduler && this.data.gluingPlan.glueSetStateId !== GlueSetState.RELEASED) {
        this.gluingPlanForm.disable();
        this.gluingPlanForm.get('glueSetStateId').enable();
      }
    } else {
      this.gluingPlanForm.get('beamLaminaDimentionId').setValidators([Validators.required]);
    }

    if (this.gluePlanService.isDisabledEndDate(this.data.gluingPlan)) {
      this.gluingPlanForm.get('plannedExecutionEndDate').disable();
    }

    // this.setSelectedMachine(this.data?.machineGroup?.machines.find(x => x.machineId === this.data?.gluingPlan.machineId));
    this.gluingPlanForm.get('plannedExecutionStartDate').valueChanges.subscribe(value => {
      if (value) {
        // add 4 hours to start date time
        // calculates new end date time
        const calculatedEndDate = new Date(new Date(value).setHours(new Date(value).getHours() + this.noOfHoursToAdd));
        this.gluingPlanForm.get('plannedExecutionEndDate').setValue(calculatedEndDate);
      }
    });
  }

  closeDialog() {
    this.ref.close(false);
  }

  async addGluingPlan() {
    if (!this.gluingPlanForm.valid) {
      return;
    }

    if (this.isNew) {
      this.gluePlanService.addGluingPlan(this.gluingPlanForm.getRawValue()).subscribe((gp: GluingPlan) => {
        gp.plannedExecutionStartDate = new Date(gp?.plannedExecutionStartDate);
        gp.plannedExecutionEndDate = new Date(gp?.plannedExecutionEndDate);

        this.ref.close(gp);
      });
    } else {
      this.gluePlanService.updateGluingPlan(this.gluingPlanForm.getRawValue()).subscribe((gp: GluingPlan) => {
        gp.plannedExecutionStartDate = new Date(gp?.plannedExecutionStartDate);
        gp.plannedExecutionEndDate = new Date(gp?.plannedExecutionEndDate);
        this.ref.close(gp);
      });
    }
  }

  lenghtOffsetValidator(gluePlan: GluingPlan): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      gluePlan.lenghtOffset = control.value;
      const result = this.gluelamStaticalMediatorService.validateLenghtOffset(gluePlan.lenghtOffset, gluePlan.length, this.selectedMachine);
      return result.length > 0 ? { exeedLenght: true } : null;
    };
  }

  isRAMConfigurationAbsent() {
    return !this.selectedMachine || !this.selectedMachine.machineId || !this.selectedMachine?.pressbedRamConfigurations || this.selectedMachine?.pressbedRamConfigurations.length === 0;
  }

  public onEndDateTimeChange() {
    let startDateTime = this.gluingPlanForm.get('plannedExecutionStartDate').value;
    let endDateTime = this.gluingPlanForm.get('plannedExecutionEndDate').value;

    if (startDateTime && endDateTime) {
      startDateTime = new Date(startDateTime);
      endDateTime = new Date(endDateTime);

      if (endDateTime <= startDateTime) {
        this.notificationService.notifyErrorAppChanel('App.ErrorEndDateLessThenStartDate');
        this.gluingPlanForm.get('plannedExecutionEndDate').patchValue(null);
      }
    }
  }
}

export interface IGluPlanModalInput{
  isNew: boolean;
  gluingPlan: GluingPlan;
  isStaticalPressGroup: boolean | null;
  isContinuousPressGroup: boolean | null;
  isStaticalScheduler: boolean | null;
}
