import { Component, Directive, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { GluingPlan } from '../../../core/models/gluing-plans.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BeamLamminaDimension } from '../../../core/models/beam-lammina-dimension.model';
import { Subscription } from 'rxjs';
import { DownloadFileHelper } from '@app/shared/helpers/download-file-helper';
import { Router } from '@angular/router';
import { GlusetStateHelper } from './gluplan-state-helper';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetStateService, IBeamMaterialGlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { ProductionFileService } from '@app/core/services/http-services/gluelam/production-file.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';

@Component({
  selector: 'app-glue-plans-history',
  templateUrl: './glue-plans-history.component.html',
  styleUrls: ['./glue-plans-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GluePlansHistoryComponent implements OnInit {
  public state: State = {
    skip: 0,
    take: 40,
    // Initial filter descriptor
    filter: {
      logic: 'and',
      filters: [
        { field: 'gluingPlanID', operator: 'contains', value: '' },
        { field: 'name', operator: 'contains', value: '' },
        { field: 'instruction', operator: 'contains', value: '' },
        { field: 'stateID', operator: 'contains', value: '2' },
        { field: 'dimentions', operator: 'contains', value: '' }
      ]
    }
  };

  public states:IBeamMaterialGlueSetState[];
  public beamLamminaDimensions: BeamLamminaDimension[];
  public filterObj: any = {};

  constructor(public dialog: MatDialog, public conf: ConfigurationService, public productionFileService: ProductionFileService, public dimensionService: DimensionService,
    private routher:Router,
    private stateService: GlueSetStateService) { }

  ngOnInit(): void {
    this.dimensionService.getBeamLamminaDimentions().subscribe(blds => {
      this.beamLamminaDimensions = blds;
    });

    this.stateService.getGlusetStates().subscribe(s => {
      this.states = s;

      this.states.forEach(x => {
        x.name = x.name.toUpperCase();
      });
    });
  }

  async download(gluingPlan: GluingPlan) {
    const blob = await this.productionFileService.productionFileForGlueplan(gluingPlan.gluingPlanID);
    DownloadFileHelper.downloadFile(blob, `GluePlan-${gluingPlan.gluingPlanID}-${new Date(Date.now()).toISOString().split('T')[0]}.zip`);
  }

  public pageChange(event: PageChangeEvent): void {
    this.state.skip = event.skip;
  }

  public viewGlueplan(item: GluingPlan) {
    /* this.dialog.open(GluesetsViewComponent, {
      height: '100%',
      width: '100vw',
      minHeight: '100%',
      minWidth: '100%',
      direction: 'ltr',
      data: item,
      panelClass: 'bg-color',
      backdropClass: 'background-transperent'
    }); */
    this.routher.navigate(['glulam/gluplam-view', item.gluingPlanID]);
  }

  public getStateColor(code: number) {
    return gluplanColorMap(code);
  }

  public getStateText(code:number):string {
    return GlusetStateHelper.getStateText(code);
  }

  public onStateChange(state: DataStateChangeEvent) {
    this.state = state;
  }
}

export const gluplanColorMap = (code: number) => {
  switch (code) {
    case 1: return '#6c757d';
    case 2: return '#007bff';
    case 5: return '#28a745';
    case 6: return '#dc3545';
    case 7: return '#007bff';
    case 8: return '#007bff';
    case 9: return '#dc3545';
  }
};

@Directive({
  selector: '[gluePlanBinding]'
})

export class GluePlanBindingDirective extends DataBindingDirective implements OnInit, OnDestroy {
  private serviceSubscription: Subscription;

  constructor(private gluePlanService: GluePlanService, grid: GridComponent) {
    super(grid);
  }

  public override ngOnInit(): void {
    this.serviceSubscription = this.gluePlanService.subscribe((result) => {
      this.grid.loading = false;
      this.grid.data = result;
      this.notifyDataChange();
    });

    super.ngOnInit();
    this.rebind();
  }

  public override ngOnDestroy(): void {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  public override rebind(): void {
    this.grid.loading = true;
    this.gluePlanService.query(this.state);
  }

  public override onStateChange(state: DataStateChangeEvent) {
    this.state = state;
    this.rebind();
  }
}
