<div class="main">
  <mat-card>
    <mat-card-header>
      <mat-card-title class="title">Welcome to</mat-card-title>
      <div class="logoImg"></div>      
    </mat-card-header>
    <mat-card-content>
      <div class="sub-title">Sawmill Optimization</div>
      <p class="card-content">A comprehensive planning and calculation solution for sawmills and wood processing industries, Optinex allows you to create a model of your entire organisation – from raw materials to the market. Based on this model, an optimised plan is prepared to maximise the total contribution margin.
      </p>
    </mat-card-content>
    <mat-card-actions>
      <button
        mat-raised-button
        (click)="login()"
        cdkFocusInitial
        color="primary"
        *ngIf="!isUserLoggedIn">
        Login
      </button>
    </mat-card-actions>
  </mat-card>
</div>
