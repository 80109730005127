import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { AppNotificationService } from './core/services/custom-services/notification.service';
import { AuthService } from './core/services/auth-service/auth.service';
import { HttpClient } from '@angular/common/http';
import { MatSidenav } from '@angular/material/sidenav';
import { MsalBroadcastService } from '@azure/msal-angular';
import { Title } from '@angular/platform-browser';
import { UserService } from './core/services/http-services/common/user.service';
import { VmService } from './core/services/http-services/common/vm.service';
import { environment } from './../environments/environment';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('AppComponent.Admin');
marker('AppComponent.Admin_Entity');
marker('AppComponent.Admin_EntityActivity');
marker('AppComponent.Admin_EntityResource');
marker('AppComponent.Admin_EntityGroup');
marker('AppComponent.Admin_EntityProperty');
marker('AppComponent.Admin_Model');
marker('AppComponent.Analytics');
marker('AppComponent.Beams');
marker('AppComponent.General_Dashbord');
marker('AppComponent.Glue_Plans');
marker('AppComponent.Gluing_Plan');
marker('AppComponent.Glulam_Operator');
marker('AppComponent.Glulam_Planning');
marker('AppComponent.Glulam_Planning_Statical');
marker('AppComponent.Glulam_Scheduler_Statical');
marker('AppComponent.Log_Class');
marker('AppComponent.Models');
marker('AppComponent.Operational_Dashbord');
marker('AppComponent.Optimization');
marker('AppComponent.Pattern_Analysis');
marker('AppComponent.Production_Order');
marker('AppComponent.Production_Order_Schedule');
marker('AppComponent.Production_Order_Gantt');
marker('AppComponent.Reports');
marker('AppComponent.Saw_Pattern');
marker('AppComponent.Settings');
marker('AppComponent.Tactical_Dashbord');
marker('AppComponent.Availability');
marker('AppComponent.AoT_Balance');
marker('AppComponent.AoT_Balance_Report');
marker('AppComponent.Glulam');
marker('AppComponent.Glulam');
marker('AppComponent.Machine');
marker('AppComponent.Operative');
marker('AppComponent.Model');
marker('AppComponent.Stock');
marker('AppComponent.Stock_Location');
marker('AppComponent.Stock_Trans_Type');
marker('AppComponent.CLT');
marker('AppComponent.Plan');
marker('AppComponent.MachineGroup');
marker('AppComponent.Handeling_Unit_Report');
marker('AppComponent.Schedule');
marker('AppComponent.Shift');
marker('AppComponent.Deviation');
marker('AppComponent.Demand_Report');
marker('AppComponent.Device');
marker('AppComponent.OptimizeSequencer');
marker('AppComponent.LogForecast');
marker('AppComponent.Production_Order_ScheduleNg');
marker('AppComponent.OptimizeRuns');
marker('AppComponent.Production_Order_Calendar');
marker('AppComponent.Instruction');
marker('AppComponent.InstructionLine');
marker('AppComponent.Parts');
marker('AppComponent.Part');
marker('AppComponent.Profile');
marker('AppComponent.ShapeList');
marker('AppComponent.EditMotherBoard');
marker('AppComponent.EditProfileView');
marker('AppComponent.PlanView');
marker('AppComponent.EditPlan');
marker('AppComponent.Tactical');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isMenuExpanded: boolean = false;
  configuration = environment;
  isIframe:boolean = false;
  loggedIn:boolean = false;
  public vmSate: string = 'Unknown';
  public vmSateColor: string = 'orange';

  public noMargin:boolean = false;

  get logoImg(): string {
    return 'assets/img/fx_logo.png';
  }

  profile;

  @ViewChild('sidenav') public sidenav: MatSidenav;

  loginSuccessSubscription: Subscription;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private router: Router,
    public titleService: Title,
    public vm: VmService,
    public http: HttpClient,
    public not: AppNotificationService,
    public userService: UserService,
    public routhe:ActivatedRoute) {
  }

  ngOnInit() {
    if (environment.environment !== 'prod') {
      let titel = this.titleService.getTitle();

      if (environment.environment != null && environment.environment !== '') {
        titel = titel + ` (${environment.environment.toUpperCase()})`;
      }

      this.titleService.setTitle(titel);
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((e:NavigationEnd) => {
      // Not the best soulution but needed for Demo!
      this.noMargin = (e.url === '/' || e.url === 'page-not-found');
    });

    this.isIframe = window !== window.parent && !window.opener;

    if (this.isIframe) {
      console.warn("I'm in frame Help!!!!");
    }

    this.setLoginState();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginState();
        this.authService.setActiveAccountCurrent();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.setLoginState();
        this.authService.setActiveAccountCurrent();
      });
  }

  toggleSidenav() {
    this.sidenav.toggle();
  }

  onNavigate($e) {
    if (!this.isMenuExpanded) {
      this.sidenav.close();
    }
    this.router.navigateByUrl($e.link);
  }

  setLoginState() {
    this.loggedIn = this.authService.isLoggedIn();
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
