export interface WatsonStatus {
  jobId: string;
  solveStatus: WatsonSolveStatus;
  status: WatsonRunStatus;
  isResultProcessed: Boolean;
}

export enum WatsonRunStatus {
  notFound = 'Not Found',
  queued = 'queued',
  running = 'running',
  completed = 'completed',
  failed = 'failed',

  // Appliction Error
  appError = 'appError',
  canceled = 'canceled'
}

export enum WatsonSolveStatus {
  unknown = 'unknown',
  feasible_solution = 'feasible_solution',
  optimal_solution = 'optimal_solution',
  infeasible_solution = 'infeasible_solution',
  unbounded_solution = 'unbounded_solution',
  infeasible_or_unbounded_soluti = 'infeasible_or_unbounded_solution',
  // Appliction Error
  appError = 'appError'

}
