<ngx-spinner name="menuSpinner" [fullScreen]="false" type="ball-scale-multiple"></ngx-spinner>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="app-menu-tree">
    <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [matTreeNodePaddingIndent]="10">
        <div mat-icon-button (contextmenu)= "openInNewTab(node)" (click)="itemClicked(node)" [ngClass]="{'selected-item' : node.selected , 'pointer' : true, 'node' : true}">
          <mat-icon class="app-menu-icon" color="primary">{{node.icon}}</mat-icon>
            {{node.name | translate}}
        </div>
        <div style="flex-grow:1"></div>
        <mat-icon *ngIf="node.loading">schedule</mat-icon>
      </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding [ngClass]="{'selected-item' : node.selected, 'pointer' : true}">
        <div class="node" matTreeNodeToggle>
        <mat-icon class="app-menu-icon" color="primary">{{node.icon}}</mat-icon>
          {{node.name | translate}}
          <div style="flex-grow:1"></div>
          <a mat-icon-button [attr.aria-label]="'Toggle ' + node.name">
            <mat-icon class="mat-icon-ltr-mirror" color="primary">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </a>
        </div>
      </mat-tree-node>
</mat-tree>