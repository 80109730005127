import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { GlueSetService } from '@app/core/services/http-services/gluelam/glue-set.service';
import { Demandline } from '@app/core/models/demand-line.model';
import { BeamMaterialGlueSet } from '../../../../core/models/beam-material-glue-set.model';
import { BeamLamminaDimension } from '../../../../core/models/beam-lammina-dimension.model';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { forkJoin } from 'rxjs';

export interface DialogData {
  bmgs: BeamMaterialGlueSet;
  lamminaDim: BeamLamminaDimension;
  width: number;
  beamOrderLines: Demandline[];
}

@Component({
  selector: 'app-bmgs-print',
  templateUrl: './bmgs-print.component.html',
  styleUrls: ['./bmgs-print.component.css']
})
export class BMGSprintComponent {
  bmgs: BeamMaterialGlueSet;
  lamminaDim: BeamLamminaDimension;
  plan: GluingPlan;

  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    public ds: DimensionService,
    public gsservice: GlueSetService,
    public glueplanService : GluePlanService
  ) {
    this.route.params.subscribe({
      next: params => {
        console.log(`LOAD: ${params.id}`);

        const gpId = params.id;

        forkJoin({
          gluset: this.gsservice.getGlueSet(gpId),
          glueplan: this.glueplanService.getGluePlansByGluesetId(gpId)
        })
          .subscribe(result => {
            this.bmgs = result.gluset;
            this.plan = result.glueplan;
          });
      }
    });
  }

  print() {
    const domClone = document.getElementById('printThis').cloneNode(true);

    let $printSection = document.getElementById('printSection');

    $printSection = document.createElement('div');
    $printSection.id = 'printSection';
    document.body.appendChild($printSection);

    $printSection.innerHTML = '';
    $printSection.appendChild(domClone);
    window.print();
  }
}
