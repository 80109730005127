import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

import { ButtonThemeColor } from '@progress/kendo-angular-buttons';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { IEntityProperty } from '@app/core/services/http-services/model/entity-property.service';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { environment } from 'src/environments/environment';
import { EntityGroupFormComponent } from '@app/modules/entity-admin/entity-group-form/entity-group-form.component';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class EntityGroupService extends BehaviorSubject<GridDataResult> {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog
  ) {
    super(null);
  }

  private _entityGroups: IEntityGroup[] = null;

  public query(inOperative: boolean = false): Observable<IEntityGroup[]> {
    if (this._entityGroups) {
      return of(this._entityGroups);
    } else {
      let params = new HttpParams();
      params = params.append('inOperative', inOperative);
      return this.http.get<IEntityGroup[]>(`${environment.apiUrl}EntityGroup`, { params }).pipe(
        map((a: IEntityGroup[]) => a.map(e => {
          e.changeDate = new Date(e.changeDate);
          e.themeColor = e.resourceTypeCode === 'MTRL' ? 'primary' : 'secondary';
          return e;
        }))
      ).pipe(tap(d => {
        this._entityGroups = d;
        return d;
      }));
    }
  }

  public insert(eg: IEntityGroup): Observable<any> {
    return this.http.post<IEntityGroup[]>(
      `${environment.apiUrl}EntityGroup`,
      JSON.stringify(eg), {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).pipe(tap(() => {
      this._entityGroups = null;
    }));
  }

  public update(eg: IEntityGroup): Observable<any> {
    return this.http.put<IEntityGroup[]>(`${environment.apiUrl}EntityGroup/${eg.index}`, JSON.stringify(eg), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  openDialog(entityGroup: IEntityGroup, isNew: boolean): any {
    return this.dialog.open(EntityGroupFormComponent, {
      height: '80vh',
      width: '1500px',
      data: { entityGroup, isNew }
    });
  }
}

export interface IEntityGroupProperty {
  index: number;
  entityGroupIndex: number;
  propertyCode: string;
  isMandatory: boolean;
  userCode: string;
  changeDate: string | null;
  defaultValueInt: number | null;
  defaultValueDouble: number | null;
  defaultValueString: string;
  property: IEntityProperty;
  entityGroupCode: string;
  isNew: boolean;
  isChange: boolean;
  isRemoved: boolean;
  isSpecies: boolean;
  isQuality: boolean;
  isLength: boolean;
  isThickness: boolean;
  isWidth: boolean;
}

export interface IEntityGroup {
  index: number;
  code: string;
  description: string;
  entityTypeCode: string;
  resourceTypeCode: string;
  defaultBaseUnitCode: string;
  sortOrderIndex: number | null;
  userCode: string;
  changeDate: Date | null;
  status: number;
  commentId: number | null;
  entityGroupProperties: IEntityGroupProperty[];
  isSelected: boolean | false;
  themeColor: ButtonThemeColor;
  machineGroupId: number;
  machineGroupName: string;
  stockLocationProduceId: number | null;
  stockLocationConsumeId: number | null;
  stockLocationProduceName: string;
  stockLocationConsumeName: string;
  isGlulamGroup: boolean | false;
  inTactical: boolean;
  inOperative: boolean;
  entityGroupSites:IEntityGroupSite[];
  selectedEntityGroupSites:string[];
}

export interface Item {
  text: string;
  value: string;
}

export interface IEntityGroupSite {
  entityGroupIndex: number;
  siteId: Guid;
}
