<h1 mat-dialog-title>
  {{"GluePlan.GlueSet" | translate}} - {{data.bmgs.beamMaterialGlueSetID}}
</h1>
<div class="col statical-plan-view">
  <mat-card class="p-0 glue-plan-mat-card">
    <mat-card-content>
      <app-beam-material-gluset-statical class="glueset"
                                          [plan]="data.plan"
                                          [bmgs]="data.bmgs"
                                          [isOperator]="false"
                                          [isDeleteAllowed]="false"
                                          [machine]="data.machine">
      </app-beam-material-gluset-statical>
    </mat-card-content>
  </mat-card>
</div>
